import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { MenuGroupService } from '../menu-group.service';
import { MenuGroupModel, MenuGroupRequest, MenuModel, RestaurantModel } from '@generativ/wto-api-client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MenuService } from '../../../menu/shared/menu.service';
import { EntryDictionary, FormMenuGroupType, GenericErrorsType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../../shared/contentful.service';
import { GenericErrorService } from '../../../shared/generic-error-service';
import { ParamsService } from '../../../shared/params.service';
import { urlValidator } from '../../../shared/directives/url-validator.directive';
import { ModalService } from '../../../shared/modal/modal.service';
import { ToastService } from '../../../shared/toast/toast.service';
import { ErrorHandlerService } from '../../../shared/error-handler.service';
import { AnimationEventsService } from '../../../shared/events/animation-events.service';
import { FadeInEvent } from '../../../shared/events/fade-in-event';

@Component({
  selector: 'app-menu-group-form',
  templateUrl: './menu-group-form.component.html',
  styleUrls: ['./menu-group-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MenuGroupFormComponent implements OnInit, OnDestroy {
  @Input() submitButtonText: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  menuGroupId: number;
  menuGroupForm: FormGroup;
  ready = false;
  isSubmitting = false;
  menuGroup: MenuGroupModel;
  menu: MenuModel;
  restaurant: RestaurantModel;
  errorMessage: string;
  content: FormMenuGroupType;
  errorContent: GenericErrorsType;
  parentActive = true;
  reset: boolean;
  route: ActivatedRoute;
  doneClicked: boolean;
  isEditMenuGroup: boolean;
  routerSub: Subscription;
  restaurantSub: Subscription;
  menuSub: Subscription;
  menuGroupSub: Subscription;
  formCtrlSub: Subscription;
  resultSub: Subscription;


  constructor(
    private fb: FormBuilder,
    private menuGroupService: MenuGroupService,
    private menuService: MenuService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private contentfulService: ContentfulService,
    private genericErrorService: GenericErrorService,
    private paramsService: ParamsService,
    private modalService: ModalService,
    private toastService: ToastService,
    private animationEventsService: AnimationEventsService
  ) {
    this.route = this.modalService.getActivatedRoute();
    this.createFormGroup();
  }

  ngOnInit() {
    this.genericErrorService.getErrorContent().then(
      (errorContent) => {
        this.errorContent = new GenericErrorsType(errorContent);
      }
    );

    this.routerSub = this.route.params.subscribe((params: Params) => {

      const restaurantId = +this.paramsService.getParamById('restaurantId',
        this.route.snapshot);

      this.restaurantSub = this.menuService.getRestaurant(restaurantId).subscribe(
        restaurantModel => {
          this.restaurant = restaurantModel;

          const menuId = +this.paramsService.getParamById('menuId', this.route.snapshot);

          this.menuSub = this.menuGroupService.getMenu(menuId).subscribe(
            menuModel => {
              this.menu = menuModel;

              if (params.menuGroupId) {
                this.isEditMenuGroup = true;
                this.menuGroupId = +params.menuGroupId;
                this.menuGroupSub = this.menuGroupService.getMenuGroup(this.menuGroupId).subscribe(
                  menuGroupModel => {

                    this.menuGroup = new MenuGroupModel();
                    this.menuGroup.parse(menuGroupModel);
                    this.menuGroupForm.patchValue(this.menuGroup);
                    this.menuGroupForm.controls.urlMonitoringFrequency
                      .setValue(this.restaurant.websiteUrlMonitoringFrequency);
                    this.menuGroupForm.controls.urlMonitoringFrequency.disable();

                    // If hidden content, enable type and url and check hidden content
                    if (this.menuGroup.hiddenContent) {
                      this.menuGroupForm.controls.hiddenContentType.enable();
                      this.menuGroupForm.controls.hiddenContentUrl.enable();
                      this.menuGroupForm.controls.hiddenContent.setValue(true);
                    }

                    this.ready = true;
                  },
                  error => this.errorMessage = <any>error
                );
              } else {
                this.menuGroup = new MenuGroupModel();
                this.menuGroupForm.controls.urlMonitoringFrequency
                  .setValue(this.restaurant.websiteUrlMonitoringFrequency);
                this.menuGroupForm.controls.urlMonitoringFrequency.disable();
                this.menuGroup.menuId = menuId;
                this.ready = true;
              }

              if (menuModel.active === 0) {
                this.menuGroupForm.controls.active.disable();
                this.menuGroupForm.controls.active.setValue(0);
                this.parentActive = false;
              }

            },
            error => this.errorMessage = <any>error
          );
        });
    });

    this.contentfulService.getAdminEntry(EntryDictionary.FormMenuGroup.formMenuGroup).then(
      (entry) => {
        this.content = new FormMenuGroupType(entry);
      }
    );

    this.formCtrlSub = this.menuGroupForm.controls.pageUrl.valueChanges
      .subscribe((value) => {
        if (value && value.length > 0) {
          this.menuGroupForm.controls.pageUrl.setValidators([
            Validators.compose([
              urlValidator()
            ])
          ]);
          this.menuGroupForm.controls.urlMonitoringFrequency.setValidators([Validators.compose([
            Validators.required
          ])]);
          if (!this.menuGroupForm.controls.urlMonitoringFrequency.value) {
            this.menuGroupForm.controls.urlMonitoringFrequency.setValue('7 days');
          }
          this.menuGroupForm.controls.urlMonitoringFrequency.updateValueAndValidity();
          this.reset = false;
        } else if (!value && !this.reset) {
          this.reset = true;
          this.menuGroupForm.controls.pageUrl.clearValidators();
          this.menuGroupForm.controls.pageUrl.updateValueAndValidity();
          this.menuGroupForm.controls.pageUrl.setValue(null);

          this.menuGroupForm.controls.urlMonitoringFrequency.clearValidators();
          this.menuGroupForm.controls.urlMonitoringFrequency.updateValueAndValidity();
        }
      });
  }


  submitForm() {
    if (this.isSubmitting) {
      return;
    }

    if (!this.menuGroupForm.valid) {
      this.errorMessage = 'Errors on form. See fields below.';
      return;
    }

    // If no change has occurred to the form on submit, just navigate back to
    // restaurant manage page instead of updating the menu group.
    if (!this.menuGroupForm.dirty) {
      this.router.navigate(['restaurant', this.menu.restaurantId]);
      return;
    }

    this.isSubmitting = true;

    this.menuGroup.parse(this.menuGroupForm.value);

    this.menuGroup.hiddenContent = this.menuGroupForm.value.hiddenContent;

    // If hidden content add type and url, otherwise set to null
    this.menuGroup.hiddenContentType = this.menuGroupForm.value.hiddenContentType ?
      this.menuGroup.hiddenContentType : null;

    this.menuGroup.hiddenContentUrl = this.menuGroupForm.value.hiddenContentUrl ?
      this.menuGroup.hiddenContentUrl : null;

    // If there is no url, don't save a url monitoring frequency.
    this.menuGroup.urlMonitoringFrequency = this.menuGroup.pageUrl ? this.menuGroup.urlMonitoringFrequency : null;

    if (this.menuGroupId) {
      // Update

      const updateRequest = new MenuGroupRequest.Update();
      updateRequest.menuGroup = this.menuGroup;
      console.log('Update MenuGroup Request:', updateRequest);

      this.resultSub = this.menuGroupService.editMenuGroup(updateRequest)
        .subscribe(
          updateResponse => {
            this.menuGroup = updateResponse.menuGroup;
            this.isSubmitting = false;
            this.toastService.showSuccess(`${ this.menuGroup.name } changed.`);
            this.router.navigate(['restaurant', this.menu.restaurantId]);
          },
          error => {
            this.errorMessage = <any>error;
            this.isSubmitting = false;
          },
        );

    } else {
      // Create
      const createRequest = new MenuGroupRequest.Create().parse(this.menuGroup);
      createRequest.idMenu = this.menuGroup.menuId;
      this.resultSub = this.menuGroupService.createMenuGroup(createRequest)
        .subscribe(
          createResponse => {
            this.menuGroup = createResponse.menuGroup;
            this.toastService.showSuccess(`${ this.menuGroup.name } added to ${ this.menu.name }.`);
            this.animationEventsService.setCreateOrCopyEvent(new FadeInEvent( `menu-group-${createResponse.menuGroup.id}`));
            this.isSubmitting = false;
            if (this.doneClicked) {
              this.router.navigate(['restaurant', this.menu.restaurantId]);
            } else {
              this.router.navigate([`../${this.menuGroup.id}/menu-item/create`], { relativeTo: this.route });
            }
          },
          (error) => {
            this.errorMessage = <any>error;
            this.doneClicked = false;
            this.isSubmitting = false;
          },
            () => console.log('Request Complete')
        );

    }
  }

  hasError(field: string, validator: string) {
    const control = this.menuGroupForm.controls[field];
    return control.hasError(validator)
      && control.touched;
  }

  hasDanger(field: string) {
    const control = this.menuGroupForm.controls[field];
    return !control.disabled && !control.valid && control.touched;
  }

  hasNgError(field: string, validator: string) {
    const control = this.menuGroupForm.controls[field];
    return control.errors
      && control.errors[validator]
      && control.touched;
  }

  toggleHiddenContentOnMenuGroupPage() {
    const hiddenTypeControl = this.menuGroupForm.get('hiddenContentType');
    const hiddenUrlControl = this.menuGroupForm.get('hiddenContentUrl');
    const hasAction = this.menuGroupForm.value.hiddenContent;
    hiddenTypeControl.disabled ? hiddenTypeControl.enable() : hiddenTypeControl.disable();
    hiddenUrlControl.disabled ? hiddenUrlControl.enable() : hiddenUrlControl.disable();
    !hasAction ? this.setHiddenContentOnMenuPageValidators() : this.setNoHiddenContentOnMenuPageValidators();
  }

  openMenuAddItem() {
    this.submitForm();
  }

  onCancel() {
    this.cancel.emit(null);
  }

  private setHiddenContentOnMenuPageValidators() {
    this.menuGroupForm.controls.hiddenContentType.setValidators([
      Validators.compose([
        Validators.required
      ])
    ]);
    this.menuGroupForm.controls.hiddenContentType.setValue(this.menuGroup.hiddenContentType);

    this.menuGroupForm.controls.hiddenContentUrl.setValidators([
      Validators.compose([
        urlValidator()
      ])
    ]);
    this.menuGroupForm.controls.hiddenContentUrl.setValue(this.menuGroup.hiddenContentUrl);
  }

  private setNoHiddenContentOnMenuPageValidators() {
    this.menuGroupForm.controls.hiddenContentType.setValidators([]);
    this.menuGroupForm.controls.hiddenContentType.updateValueAndValidity();
    this.menuGroupForm.controls.hiddenContentType.setValue(null);

    this.menuGroupForm.controls.hiddenContentUrl.setValidators([]);
    this.menuGroupForm.controls.hiddenContentUrl.updateValueAndValidity();
    this.menuGroupForm.controls.hiddenContentUrl.setValue(null);
  }

  private createFormGroup() {
    this.menuGroupForm = this.fb.group({
      name: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(100)
        ])
      ],
      listPageUrl: null,
      websiteUrl: null,
      description: [
        null,
        Validators.compose([
          Validators.maxLength(150)
        ])
      ],
      pageUrl: null,
      urlMonitoringFrequency: '365 days',
      hiddenContent: false,
      hiddenContentType: [
        {
          value: null,
          disabled: true
        },
      ],
      hiddenContentUrl: [
        {
          value: null,
          disabled: true
        }
      ],
      active: [
        1,
        Validators.required
      ]
    });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }
    if (this.menuGroupSub) {
      this.menuGroupSub.unsubscribe();
    }
    if (this.formCtrlSub) {
      this.formCtrlSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
  }

  getValidationErrors(): string[] {
    return this.errorHandlerService.getValidationErrors(this.menuGroupForm);
  }
}
