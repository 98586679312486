import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { homeRoutes } from './home/home.routes';
import { menuGroupRoutes } from './menu-group/menu-group.routes';
import { menuItemRoutes } from './menu-item/menu-item.routes';
import { authRoutes } from './auth/auth.routes';
import { callbackRoutes } from './callback/callback.routes';
import { pageNotFoundRoutes } from './page-not-found/page-not-found.routes';
import { searchRoutes } from './search/search.routes';

const routes: Routes = [
  ...homeRoutes,
  ...menuGroupRoutes,
  ...menuItemRoutes,
  ...searchRoutes,
  ...callbackRoutes,
  ...authRoutes,
  ...pageNotFoundRoutes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AdminRoutingModule { }
