import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MenuGroupCreateComponent } from './menu-group-create/menu-group-create.component';
import { MenuGroupEditComponent } from './menu-group-edit/menu-group-edit.component';
import { MenuGroupComponent } from './menu-group.component';
import { MenuGroupService } from './shared/menu-group.service';
import { MenuGroupFormComponent } from './shared/menu-group-form/menu-group-form.component';
import { ReorderMenuGroupsComponent } from './reorder-menu-groups/reorder-menu-groups.component';
import { MenuGroupRelocateComponent } from './menu-group-relocate/menu-group-relocate.component';
import { MenuGroupCopyComponent } from './menu-group-copy/menu-group-copy.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    MenuGroupComponent,
    MenuGroupCreateComponent,
    MenuGroupEditComponent,
    MenuGroupFormComponent,
    ReorderMenuGroupsComponent,
    MenuGroupRelocateComponent,
    MenuGroupCopyComponent
  ],
  providers: [
    MenuGroupService
  ]
})
export class MenuGroupModule { }
