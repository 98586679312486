import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-modal-content-template',
  template: ''
})
export class ModalContentTemplateComponent implements OnInit {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
  }

}


@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit, OnDestroy, AfterContentInit {

  public component;
  title;
  subheader;

  @ViewChild(ModalContentTemplateComponent, { static: true }) modalDirective: ModalContentTemplateComponent;
  componentRef;

  subHeaderRefSub: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router
  ) {
  }
  ngOnInit() {
  }

  ngAfterContentInit() {
    // Create component in a dynamic way. We are getting the component from the modal wrapper.
    // More info: https://angular.io/guide/dynamic-component-loader
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    const viewContainerRef = this.modalDirective.viewContainerRef;
    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.title = this.componentRef.instance.title;


    // In the modal content in some cases we need one sub-header.
    // E.g the restaurant name when adding one menu.
    // Since it's one name that come from the api it will need to be one observable.
    if (this.componentRef.instance.setSubHeader) {
      this.subHeaderRefSub = this.componentRef.instance.setSubHeader.subscribe(subheader => {
        this.subheader = subheader;
     });
    }
  }

  close() {
    this.activeModal.close();
    this.componentRef.instance.onCloseModal();
  }

  ngOnDestroy() {
    if (this.componentRef.instance.setSubHeader) {
      this.subHeaderRefSub.unsubscribe();
    }
  }
}
