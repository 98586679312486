  <form *ngIf="ready && content; else loader" [formGroup]="menuGroupForm" (ngSubmit)="submitForm()" autocomplete="off"
        (keydown.enter)="$event.preventDefault()">
    <div *ngIf="errorMessage" class="text-danger">
      <i class="fa fa-warning"></i> {{errorMessage}}
    </div>
    <div class="form-group"
         [ngClass]="{'has-danger':hasDanger('name')}">
      <label for="group-name" class="form-control-label">
        <app-tooltip *ngIf="content.inputName && content.inputName.tooltip" [tooltipText]="content.inputName.tooltip">
        </app-tooltip>
        {{ content.inputName?.label }}
      </label>
      <input type="text" id="group-name" class="form-control form-control-lg"
             [placeholder]="content.inputName.placeholder || ''"
             autocomplete="off"
             formControlName="name">
      <div class="form-control-feedback">
        <span *ngIf="hasError('name', 'required')">{{ errorContent.required }}</span>
        <span *ngIf="hasError('name', 'maxlength')">{{ errorContent.maxlength }}</span>
      </div>
    </div>

    <ng-container *ngIf="'' | admin">
      <div class="form-group"
           [ngClass]="{'has-danger':hasDanger('pageUrl')}">
        <label class="form-control-label">
          <app-tooltip *ngIf="content.inputMenuGroupPageUrl && content.inputMenuGroupPageUrl.tooltip"
                       [tooltipText]="content.inputMenuGroupPageUrl.tooltip"></app-tooltip>
          {{ content.inputMenuGroupPageUrl?.label }}
        </label>
        <select formControlName="urlMonitoringFrequency">
          <option value="1 day">1 day</option>
          <option value="3 days">3 days</option>
          <option value="7 days">7 days</option>
          <option value="10 days">10 days</option>
          <option value="30 days">30 days</option>
          <option value="90 days">90 days</option>
          <option value="365 days">365 days</option>
        </select>
        <input type="url" class="form-control form-control-lg"
               [placeholder]="content.inputMenuGroupPageUrl.placeholder || ''"
               autocomplete="off"
               formControlName="pageUrl">
        <div class="form-control-feedback">
          <span *ngIf="hasError('pageUrl', 'invalidUrl')">{{ errorContent.isUrl }}</span>
          <span *ngIf="hasError('pageUrl', 'required')">{{ errorContent.required }}</span>
          <span *ngIf="hasError('urlMonitoringFrequency', 'required')">Must provide monitoring frequency.</span>
        </div>
      </div>
<!--  Removed, but commented in case it ever comes back  -->
<!--      <div class="form-group"-->
<!--           [ngClass]="{'has-danger':hasDanger('hiddenContentType')}">-->
<!--        <label class="form-control-label">-->
<!--          <app-tooltip *ngIf="content.hiddenContentOnMenuGroupPage && content.hiddenContentOnMenuGroupPage.tooltip"-->
<!--                       [tooltipText]="content.hiddenContentOnMenuGroupPage.tooltip"></app-tooltip>-->
<!--          {{ content.hiddenContentOnMenuGroupPage?.label }}-->
<!--        </label>-->
<!--        <div class="form-check d-inline-block">-->
<!--          <input type="checkbox" (click)="toggleHiddenContentOnMenuGroupPage()" class="form-check-input" id="actionsRequired"-->
<!--                 formControlName="hiddenContent">-->
<!--          <label class="form-check-label" for="actionsRequired">-->
<!--            <span>&nbsp;</span>-->
<!--          </label>-->
<!--        </div>-->
<!--        <select formControlName="hiddenContentType">-->
<!--          <option value="image">Image</option>-->
<!--          <option value="frame">Frame</option>-->
<!--          <option value="other">Other</option>-->
<!--        </select>-->
<!--        <div class="form-control-feedback">-->
<!--          <span *ngIf="hasError('hiddenContentType', 'required')">{{ errorContent.required }}</span>-->
<!--        </div>-->
<!--        <input type="url" class="form-control form-control-lg"-->
<!--               [placeholder]="content.hiddenContentUrlOnMenuGroupPage.placeholder || ''"-->
<!--               formControlName="hiddenContentUrl"-->
<!--               autocomplete="off">-->
<!--        <div class="form-control-feedback">-->
<!--          <span *ngIf="hasError('hiddenContentUrl', 'invalidUrl')">{{ errorContent.isUrl }}</span>-->
<!--        </div>-->
<!--      </div>-->
    </ng-container>

    <hr>

    <div class="form-footer">
      <span [ngbTooltip]="tipContent" *ngIf="menuGroupForm.invalid; else nextItemEnabled">
        <button *ngIf="!isEditMenuGroup"
                class="btn btn-outline-primary btn-sm edit-button"
                [disabled]="!menuGroupForm.valid || isSubmitting"
                type="submit">Add Item</button>
      </span>
      <ng-template #nextItemEnabled>
        <span>
          <button *ngIf="!isEditMenuGroup"
                  class="btn btn-outline-primary btn-sm edit-button"
                  [disabled]="!menuGroupForm.valid || isSubmitting"
                  type="submit">Add Item</button>
        </span>
      </ng-template>
      <span class="pull-right">
        <span>
          <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="onCancel()">Cancel</button>
        </span>
        <span [ngbTooltip]="tipContent" *ngIf="menuGroupForm.invalid; else doneEnabled">
          <button class="btn btn-outline-primary btn-sm" type="submit"
                  [disabled]="!menuGroupForm.valid || isSubmitting" (click)="doneClicked = true;">
              Done
          </button>
        </span>
        <ng-template #doneEnabled>
          <span>
            <button class="btn btn-outline-primary btn-sm" type="submit"
                    [disabled]="!menuGroupForm.valid || isSubmitting" (click)="doneClicked = true;">
              <!-- The <span> below will change the button text to a
              3-dot loading animation while the form submits -->
              <span [ngClass]="doneClicked ? 'loader-small span-loader' : ''">
                Done
              </span>
            </button>
          </span>
        </ng-template>
      </span>
    </div>
  </form>

 <ng-template #loader>
   <app-loader></app-loader>
 </ng-template>

 <ng-template #tipContent>The following fields have errors:
   <br>
   <ul>
     <li *ngFor="let field of getValidationErrors()">
       {{ field }}
     </li>
   </ul>
 </ng-template>
