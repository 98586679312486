<ul *ngIf="restaurant; else loader"
    class="list-unstyled list-drag" [(dragulaModel)]='restaurant.menus' [dragula]='"menus-bag"'>
  <ng-container *ngFor="let menu of restaurant.menus">
    <li class="menu-group" *ngIf="!menu.archive">
      <div class="row">
        <div class="col-12">
          <div id="menu-name" class="form-control form-control-lg font-weight-bold">
            {{menu.name}}
          </div>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
<ul *ngIf="restaurant"
    class="list-unstyled list-drag list-archive">
  <ng-container *ngFor="let menu of restaurant.menus">
    <li class="menu-group" *ngIf="menu.archive">
      <div class="row">
        <div class="col-12">
          <div id="archive-menu-name" class="form-control form-control-lg font-weight-bold">
            {{menu.name}}
          </div>
        </div>
      </div>
    </li>
  </ng-container>
</ul>

<div class="done-button">
  <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="onCloseModal()">Done</button>
</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
