import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../shared/modal/modal.service';
import { Router } from '@angular/router';
import { RestaurantService } from '../shared/restaurant.service';
import { RestaurantModel } from '@generativ/wto-api-client';
import FileSaver from 'file-saver';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { DatePipe } from '@angular/common';
import { EntryDictionary, FormExportType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';
import { atLeastOneSelected } from '../restaurant-email/restaurant-email.component';

@Component({
  selector: 'app-restaurant-export',
  templateUrl: './restaurant-export.component.html',
  styleUrls: ['./restaurant-export.component.scss']
})
export class RestaurantExportComponent implements OnInit, OnDestroy {
  content: FormExportType;
  restaurant: RestaurantModel;
  restaurantExportForm: FormGroup;

  routerSub: Subscription;
  restaurantSub: Subscription;
  resultSub: Subscription;

  waiting = false;

  constructor(
    private modalService: ModalService,
    private restaurantService: RestaurantService,
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private contentfulService: ContentfulService
  ) {
    this.createFormGroup();

    this.contentfulService.getAdminEntry(EntryDictionary.FormExport.formExport)
      .then(entry => this.content = new FormExportType(entry));
  }

  ngOnInit() {
    this.routerSub = this.modalService.getActivatedRoute().parent.params.subscribe((params) => {
      if (params.restaurantId) {
        this.restaurantSub = this.restaurantService.getRestaurant(params.restaurantId).subscribe((restaurant) => {
          this.restaurant = restaurant;
          for (const menu of this.restaurant.menus) {
            this.menus.push(this.fb.group({
              id: menu.id,
              selected: false,
              name: menu.name,
              active: menu.active
            }));
          }
        });
      }
    });
  }

  onExport() {
    const menuIds = this.menus.value.filter(menu => menu.selected).map(menu => menu.id).join();
    const unpublishedChecked = this.restaurantExportForm.get('includeUnpublished').value;
    const updatedSinceFormat = this.restaurantExportForm.controls.updatedSince.value;
    let updatedSince;
    if (updatedSinceFormat) {
      // Conform month to [0-11] date indexing
      updatedSince = new Date(updatedSinceFormat.year, --updatedSinceFormat.month, updatedSinceFormat.day);
    }
    this.waiting = true;
    this.resultSub = this.restaurantService.exportRestaurant(this.restaurant.id, menuIds, updatedSince, unpublishedChecked)
    .subscribe(data => {
        const blob = new Blob([data], {type: 'text/utf-8'});
        this.waiting = false;
        FileSaver.saveAs(blob, `${this.restaurant.name} ${ this.datePipe.transform(new Date(), 'y-MM-dd HHmm') }.csv`);
        this.router.navigate(['restaurant', this.restaurant.id]);
        this.modalService.closeCurrentModal();
      },
      error => {
        this.waiting = false;
        console.log(`error exporting menus: `, error);
      }
    );
  }

  get menus(): FormArray {
    return this.restaurantExportForm.get('menus') as FormArray;
  }


  /**
   * Calling closeModal from the component allow us to redirect the user to one page depending on
   * the component that we are.
   */
  onCloseModal() {
    this.router.navigate(['../'], { relativeTo: this.modalService.getActivatedRoute() });
  }

  /**
   * Getting the title from the component, let us getting it from Contentful.
   * @returns {string}
   */
  get title() {
    return 'DOWNLOAD MENU INFORMATION';
  }

  setMenuSelected(id: number): void {
    for (let i = 0; i < this.menus.controls.length; i++) {
      const control = this.menus.controls[i] as FormGroup;
      if (control.controls.id.value === id) {
        control.controls.selected.setValue(!control.controls.selected.value);
        break;
      }
    }
  }

  private createFormGroup() {
    this.restaurantExportForm = this.fb.group({
      menus: this.fb.array([], atLeastOneSelected()),
      includeUnpublished: [false],
      updatedSince: [null],
      active: [false]
    });
  }

  ngOnDestroy() {
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
  }

}
