import { Routes } from '@angular/router';
import { MenuGroupComponent } from './menu-group.component';
import { MenuGroupCreateComponent } from './menu-group-create/menu-group-create.component';
import { MenuGroupEditComponent } from './menu-group-edit/menu-group-edit.component';
import { menuItemRoutes } from '../menu-item/menu-item.routes';
import { MenuItemCreateComponent } from '../menu-item/menu-item-create/menu-item-create.component';
import { ModalWrapperComponent } from '../shared/modal/modal-wrapper/modal-wrapper.component';
import { ReorderMenuGroupsComponent } from './reorder-menu-groups/reorder-menu-groups.component';
import { MenuGroupRelocateComponent } from './menu-group-relocate/menu-group-relocate.component';
import { MenuGroupCopyComponent } from './menu-group-copy/menu-group-copy.component';

export const menuGroupRoutes: Routes = [
  {
    path: 'menu-group',
    component: MenuGroupComponent,
    data: {
      breadcrumb: {}
    },
    children: [
      {
        path: 'create',
        component: ModalWrapperComponent,
        data: {
          component: MenuGroupCreateComponent,
          breadcrumb: {
            bcType: 'Display',
            action: 'Create',
            value: 'Menu Groups',
            type: 'MenuGroup'
          }
        }
      },
      {
        path: 'reorder',
        component: ModalWrapperComponent,
        data: {
          component: ReorderMenuGroupsComponent
        }
      },
      {
        path: ':menuGroupId',
        data: {
          breadcrumb: {
            bcType: 'LookUp',
            value: 'menuGroupId',
            type: 'MenuGroup'
          }
        },
        children: [
          {
            path: 'edit',
            component: ModalWrapperComponent,
            data: {
              component: MenuGroupEditComponent,
              breadcrumb: {
                bcType: 'Display',
                action: 'Edit'
              }
            }
          },
          {
            path: 'move',
            component: ModalWrapperComponent,
            data: {
              component: MenuGroupRelocateComponent
            }
          },
          {
            path: 'copy',
            component: ModalWrapperComponent,
            data: {
              component: MenuGroupCopyComponent
            }
          },
          {
            path: 'manage',
            component: MenuItemCreateComponent,
            data: {
              breadcrumb: {
                bcType: 'Display',
                value: 'Manage',
              }
            }
          },
          ...menuItemRoutes
        ]
      }
    ]
  }
];
