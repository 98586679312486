import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../shared/modal/modal.service';
import { ActivatedRoute } from '@angular/router';
import { ParamsService } from '../../shared/params.service';
import { MenuItemService } from '../menu-item.service';
import { PhotoApprovalModel } from '@generativ/wto-api-client';
import { FilestackService } from '../../shared/filestack.service';
import { Subscription } from 'rxjs/Subscription';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../shared/toast/toast.service';

@Component({
  selector: 'app-menu-item-photo-approval',
  templateUrl: './menu-item-photo-approval.component.html',
  styleUrls: ['./menu-item-photo-approval.component.scss']
})

export class MenuItemPhotoApprovalComponent implements OnInit, OnDestroy {
  ready: boolean;
  photoApproval: PhotoApprovalModel[] = [];
  menuItemId: number;
  date = new Date();
  photoApprovalSub: Subscription;
  currentModal: NgbModalRef;
  deleteReason = -1;
  deleteReasons = ['Photo is inappropriate.', 'Photo features nudity.', 'Photo is not of the menu item.'];

  constructor(
    private modalService: ModalService,
    private paramsService: ParamsService,
    private menuItemService: MenuItemService,
    private route: ActivatedRoute,
    private filestackService: FilestackService,
    private ngbModalService: NgbModal,
    private toastService: ToastService

  ) {
    this.photoApprovalSub = new Subscription();
  }

  ngOnInit() {
    this.getPhotos();
  }

  openModal(content) {
    this.currentModal = this.ngbModalService.open(content);
  }

  /**
   * Attempts to get the next set of photos for approval.
   *
   * @param showToast Show an alert toast message if attempting to get more and none are found
   */
  getPhotos(showToast = false) {
    this.photoApprovalSub.add(this.menuItemService.getPhotosForApproval()
      .subscribe((photos) => {
        console.log(`Retrieved ${photos.length} photos for approval`);
        this.photoApproval = photos;
        this.ready = true;

        // Show a message if no photos found
        if (showToast && !photos.length) {
          this.toastService.showSuccess(`No new photos to show for approval.`);
        }
      }, (error) => {
        console.log(`Error getting photos for approval: `, error);
      }));
  }

  /**
   * Call for button to check for more photos
   */
  showMore() {
    this.getPhotos(true);
  }

  approveAsNew(photo: PhotoApprovalModel) {
    photo.action = 1;
    photo.actionDate = new Date();

    this.filestackService.cropImage(photo.url)
      .subscribe((response) => {
        photo.approvedImageUrl = response;
        photo.menuItem.image = photo.approvedImageUrl;
        this.photoApprovalSub.add(this.menuItemService.addPhotoApprovalAction(photo)
          .subscribe(() => {
            console.log(`Approved as new: `, photo);
            this.photoApproval.splice(this.photoApproval.indexOf(photo), 1);
          }, (error) => {
            console.log(`Error approving photo: `, error);
          }));
      });
  }

  approveAsCompliant(photo: PhotoApprovalModel) {
    photo.action = 2;
    photo.actionDate = new Date();


    this.photoApprovalSub.add(this.menuItemService.addPhotoApprovalAction(photo)
      .subscribe(() => {
        console.log(`Approved as compliant: `, photo);
        this.photoApproval.splice(this.photoApproval.indexOf(photo), 1);
      }, (error) => {
        console.log(`Error marking photo compliant: `, error);
      }));
  }

  deleteFromSystem(photo: PhotoApprovalModel) {
    this.currentModal.close();
    photo.action = 3;
    photo.actionDate = new Date();
    photo.actionReason = this.deleteReason;
    this.filestackService.removePhoto(photo.url)
      .then((response) => {
        this.photoApprovalSub.add(this.menuItemService.addPhotoApprovalAction(photo)
          .subscribe(() => {
            console.log(`Denied and deleted: `, photo);
            this.photoApproval.splice(this.photoApproval.indexOf(photo), 1);
          }, (error) => {
            console.log(`Error deleting photo: `, error);
          }));
      }).catch((error) => {
        console.error(`Filestack delete error: `, error);
    });
  }

  openPopup(src: string) {
    window.open(src, 'popup', 'width=600,height=600');
    return false;
  }

  ngOnDestroy() {
    if (this.photoApprovalSub) {
      this.photoApprovalSub.unsubscribe();
    }
  }
}
