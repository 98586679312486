import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MenuItemComponent } from './menu-item.component';
import { MenuItemCreateComponent } from './menu-item-create/menu-item-create.component';
import { MenuItemEditComponent } from './menu-item-edit/menu-item-edit.component';
import { MenuItemService } from './menu-item.service';
import { MenuItemFormComponent } from './shared/menu-item-form/menu-item-form.component';
import { MenuItemRelocateComponent } from './menu-item-relocate/menu-item-relocate.component';
import { MenuItemCopyComponent } from './menu-item-copy/menu-item-copy.component';
import { MenuItemPhotoApprovalComponent } from './menu-item-photo-approval/menu-item-photo-approval.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

@NgModule({
  imports: [
    SharedModule,
    MultiselectDropdownModule
  ],
  declarations: [
    MenuItemComponent,
    MenuItemCreateComponent,
    MenuItemEditComponent,
    MenuItemFormComponent,
    MenuItemRelocateComponent,
    MenuItemCopyComponent,
    MenuItemPhotoApprovalComponent
  ],
  providers: [
    MenuItemService
  ]
})
export class MenuItemModule { }
