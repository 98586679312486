import { Component, OnInit, Input } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  providers: [NgbPopoverConfig]
})
export class TooltipComponent implements OnInit {
  @Input() tooltipText = 'Tooltip text here.';

  constructor(config: NgbPopoverConfig) {
    config.triggers = 'hover';
    config.placement = 'top';
  }

  ngOnInit() {
  }
}
