import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { MenuGroupModel, MenuItemModel, MenuModel, RestaurantModel } from '@generativ/wto-api-client';

@Injectable()
export class BreadcrumbService {

  constructor() { }

  // Observable  sources
  private restaurantSource = new Subject<RestaurantModel>();
  private menuSource = new Subject<MenuModel>();
  private menuGroupSource = new Subject<MenuGroupModel>();
  private menuItemSource = new Subject<MenuItemModel>();

  // Observable string streams
  restaurantObservable = this.restaurantSource.asObservable();
  menuObservable = this.menuSource.asObservable();
  menuGroupObservable = this.menuGroupSource.asObservable();

  setRestaurant(restaurant: RestaurantModel) {
    this.restaurantSource.next(restaurant);
  }
  setMenu(menu: MenuModel) {
    this.menuSource.next(menu);
  }

  setMenuGroup(menuGroup: MenuGroupModel) {
    this.menuGroupSource.next(menuGroup);
  }

  setMenuItem(menuItem: MenuItemModel) {
    this.menuItemSource.next(menuItem);
  }

}
