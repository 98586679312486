import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';

@Injectable()
export class ErrorHandlerService {

  constructor() { }

  handleError (error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = `${error.status} - ${error.message || ''}`;
    } else {
      errMsg = JSON.stringify(error);
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  getValidationErrors(form: FormGroup): string[] {
    let errors = [];
    Object.keys(form.controls).forEach(key => {
      const formItem = form.get(key);
      if (formItem instanceof FormArray) {
        for (const control of formItem.controls) {
          errors = [...errors, ...this.getValidationErrors(<FormGroup> control) ];
        }

      } else if (formItem instanceof FormGroup) {
        errors = [...errors, ...this.getValidationErrors(formItem) ];
      }
      // Get the error from the controls.
      const controlErrors: ValidationErrors = formItem.errors;
      if (controlErrors) {
        errors.push(key);
      }
    });
    // In case errors are associated with the form.
    const formError: ValidationErrors = form.errors;
    if (formError) {
      Object.keys(formError).forEach(error => {
        errors.push(error);
      });
    }
    return errors;
  }
}
