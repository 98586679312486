import { Routes } from '@angular/router';
import { MenuComponent } from './menu.component';
import { MenuCreateComponent } from './menu-create/menu-create.component';
import { MenuEditComponent } from './menu-edit/menu-edit.component';
import { menuGroupRoutes } from '../menu-group/menu-group.routes';
import { ModalWrapperComponent } from '../shared/modal/modal-wrapper/modal-wrapper.component';
import { ReorderMenusComponent } from './reorder-menus/reorder-menus.component';

export const menuRoutes: Routes = [
  {
    path: 'menu',
    component: MenuComponent,
    data: {
      breadcrumb: {}
    },
    children: [
      {
        path: 'create',
        component: ModalWrapperComponent,
        data: {
          component: MenuCreateComponent,
          breadcrumb: {
            bcType: 'Display',
            action: 'Create',
            value: 'Menus',
            type: 'Menu'
          }
        }
      },
      {
        path: 'reorder',
        component: ModalWrapperComponent,
        data: {
          component: ReorderMenusComponent
        }
      },
      {
        path: ':menuId',
        data: {
          breadcrumb: {
            bcType: 'LookUp',
            value: 'menuId',
            type: 'Menu'
          }
        },
        children: [
          {
            path: 'edit',
            component: ModalWrapperComponent,
            data: {
              component: MenuEditComponent,
              breadcrumb: {
                bcType: 'Display',
                action: 'Edit'
              }
            }
          },
          ...menuGroupRoutes
        ]
      }
    ]
  }
];
