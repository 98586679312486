import { Injectable } from '@angular/core';
import { ContentfulService } from './contentful.service';
import { GenericErrorsType, EntryDictionary } from '@generativ/wto-admin-types';


@Injectable()
export class GenericErrorService {
  private errorsCache: GenericErrorsType;

  constructor(private contentfulService: ContentfulService) {

  }

  public getErrorContent(): Promise<GenericErrorsType> {
    if (!this.errorsCache) {
      return this.contentfulService.getAdminEntry(EntryDictionary.GenericErrors.genericFormErrors).then(
        (entry) => {
          this.errorsCache = new GenericErrorsType(entry);
          return this.errorsCache;
        }
      );
    }
    return Promise.resolve(this.errorsCache);
  }
}
