<ng-container *ngIf="restaurant && content; else loader">
  <form *ngIf="!stepTwo; else step2" [formGroup]="restaurantMenusForm">
    <div class="form-group">
      <app-tooltip [tooltipText]="content.inputUnpublishedCheckbox.tooltip"></app-tooltip>
      &nbsp;
      <div class="form-check d-inline-block">
        <input type="checkbox" id="includeUnpublished" class="form-check-input" formControlName="includeUnpublished"
               name="unpublished">
        <label class="form-check-label" for="includeUnpublished">
          <span class="small">
            {{ content.inputUnpublishedCheckbox.label }}
          </span>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-4 center">
        <label for="reopening-date" class="form-control-label font-weight-bold">
          <app-tooltip [tooltipText]="content.inputSelectMenus.tooltip"></app-tooltip>
          {{ content.inputSelectMenus.label }}
        </label>
      </div>
    </div>
    <div formArrayName="menus">
      <div *ngFor="let menu of restaurantMenusForm.get('menus').controls | activeFilterFormGroup:
       !restaurantMenusForm.get('includeUnpublished').value; let i=index" [formGroupName]="i" class="form-group">
        <div class="offset-1 form-check">
          <input type="checkbox" class="form-check-input" formControlName="selected" id={{i}} name={{i}}>
          <label class="form-check-label" for={{i}}>
            {{menu.value.name}}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4 center">
        <label for="reopening-date" class="form-control-label">
          <app-tooltip [tooltipText]="content.inputUpdatedSince.tooltip"></app-tooltip>
          {{ content.inputUpdatedSince.label }}
        </label>
      </div>
      <div class="col-8 date-picker">
        <input class="form-control form-control-lg" id="reopening-date"
               [placeholder]="content.inputUpdatedSince.placeholder || 'mm-dd-yyyy'" name="dp"
               formControlName="updatedSince" ngbDatepicker
          #d="ngbDatepicker" (click)="d.toggle()">
      </div>
    </div>
    <br>
    <div class="form-group text-center">
      <button type="submit" class="btn btn-outline-primary btn-sm" [disabled]="!restaurantMenusForm.valid" (click)="onNext()">
        <!-- The <span> below will change the button text to a
          3-dot loading animation while the form submits -->
        <span [ngClass]="waiting ? 'loader-small' : ''">
          Next
        </span>
      </button>
    </div>
  </form>
  <ng-template #step2>
    <form [formGroup]="restaurantEmailForm">
      <label for="reopening-date" class="form-control-label font-weight-bold">
        <app-tooltip [tooltipText]="content.inputEmailFileTo.tooltip"></app-tooltip>
        {{ content.inputEmailFileTo.label }}
      </label>
      <div formArrayName="recipients">
        <div *ngFor="let recipient of restaurantEmailForm.get('recipients').controls; let j=index" [formGroupName]="j" class="row">
          <div class="offset-1 form-check">
            <input type="checkbox" class="form-check-input" formControlName="selected" [id]=j [name]=j
              [placeholder]="content.inputEmailFileTo.placeholder">
            <label class="form-check-label" [for]=j>
              {{ recipient.value.company }}
              {{ '(' + recipient.value.email + ')' }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-group hidden-xl-down">

        <label class="form-control-label font-weight-bold pt-3">
          <app-tooltip [tooltipText]="content.inputComposeMessage.tooltip"></app-tooltip>
          {{ content.inputComposeMessage.label }}
        </label>

        <textarea class="form-control form-control-lg" [placeholder]="content.inputComposeMessage.placeholder" rows="6"
        formControlName="content"></textarea>
      </div>
      <div class="text-center form-footer">
        <span>
          <button (click)="onSendEmail()" class="btn btn-outline-primary btn-sm" [disabled]="!restaurantEmailForm.valid" >
            <!-- The <span> below will change the button text to a
            3-dot loading animation while the form submits -->
            <!-- TODO: The loader appearing needs to be tested once email addresses are available to choose -->
            <span [ngClass]="waiting ? 'loader-small span-loader-large' : ''">
              Send
            </span>
          </button>
        </span>
      </div>
    </form>
  </ng-template>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
