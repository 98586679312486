import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  /**
   * Add auth header including
   * @param req
   * @param next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiRegex = new RegExp(environment.wtoApiUrl);

    if (req.url.match(apiRegex)) {
      const authHeader = this.authService.getAuthorizationHeader();
      // Clone the request to add the new header.
      const authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + authHeader) });
      /// / Pass on the cloned request instead of the original request.
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}
