import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { EntryDictionary, PageCreateType } from '@generativ/wto-admin-types';
import { Router } from '@angular/router';
import { RestaurantService } from '../shared/restaurant.service';
import { RestaurantModel } from '@generativ/wto-api-client';
import { ContentfulService } from '../../shared/contentful.service';
import { ModalService } from '../../shared/modal/modal.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-restaurant-edit',
  templateUrl: './restaurant-edit.component.html',
  styleUrls: ['./restaurant-edit.component.scss']
})
export class RestaurantEditComponent implements OnInit, OnChanges, OnDestroy {
  content: PageCreateType;
  restaurant: RestaurantModel;
  routerSub: Subscription;
  restaurantSub: Subscription;
  // RxJS Subscription is an excellent API for managing many unsubscribe calls.
  // See note below about unsubscribing.
  subs = new Subscription();

  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();

  constructor(
    private restaurantService: RestaurantService,
    private contentfulService: ContentfulService,
    private modalService: ModalService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.subs.add(this.modalService.getActivatedRoute().parent.params.subscribe((params) => {
      if (params['restaurantId']) {
        this.subs.add(this.restaurantService.getRestaurant(params['restaurantId']).subscribe((restaurant) => {
          this.restaurant = restaurant;
        }));
      }
    }));

    this.contentfulService.getAdminEntry(EntryDictionary.PageCreate.pageEditRestaurant).then(
      (entry) => {
        this.content = new PageCreateType(entry);
      }
    );
  }

  ngOnChanges() {

  }

  /**
   * Calling closeModal from the component allow us to redirect the user to one page depending on
   * the component that we are.
   */
  onCloseModal() {
    this.router.navigate(['../'], { relativeTo: this.modalService.getActivatedRoute() });
  }


  /**
   * Getting the title from the component, let us getting it from Contentful.
   * @returns {string}
   */
  get title() {
    return 'EDIT LOCATION DETAILS';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
