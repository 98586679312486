import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { MenuGroupModel, MenuItemModel, MenuModel, RestaurantModel } from '@generativ/wto-api-client';
import { RestaurantService } from '../../../restaurant/shared/restaurant.service';
import { BreadcrumbService } from '../../../shared/breadcrumb.service';
import { filter } from 'rxjs/operators';

class Breadcrumb {
  label: string;
  url: string;

  constructor(label: string, url?: string) {
    this.label = label;
    this.url = url;
  }
}

interface Identifiable {
  id: number;
  name: string;
}

type BreadcrumbAction = 'Create' | 'Edit' | 'Manage' | 'Login';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: Breadcrumb[];
  public action: BreadcrumbAction;


  private restaurant: RestaurantModel = new RestaurantModel();
  private menu: MenuModel;
  private menuGroup: MenuGroupModel;
  private menuItem: MenuItemModel;


  private errorMessage: string;

  ROUTE_DATA_BREADCRUMB = 'breadcrumb';


  constructor(
    private restaurantService: RestaurantService,
    private activatedRoute: ActivatedRoute,
    private breadcrumService: BreadcrumbService,
    private router: Router
  ) {}

  ngOnInit() {

    // Subscribe to the NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      // Set breadcrumbs
      const root = this.activatedRoute.root;
      this.breadcrumbs = [];
      this.action = null;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }


  private getBreadcrumbs(route: ActivatedRoute, url = ''): Breadcrumb[] {

    // Get the child routes
    const children: ActivatedRoute[] = route.children;

    // Return if there are no more children
    if (children.length === 0) {
      return this.breadcrumbs;
    }

    // Iterate over each children
    for (const child of children) {

      // Verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // Verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
        continue;
      }

      // Get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // Append route URL to URL
      url += `/${routeURL}`;

      // Create breadCrumb segment
      const breadcrumbData = child.snapshot.data['breadcrumb'];

      const value = breadcrumbData['value'];
      const bcType = breadcrumbData['bcType'];
      const type = breadcrumbData['type'];
      const action = breadcrumbData['action'];

      this.action = action ? action : this.action;

      // Special case: we need to Look up the restaurant
      // We subscribe to restaurantService.getRestaurant
      // When we get the restaurant, we create the breadcrumb and make the recursive call
      if (type === 'Restaurant' && bcType === 'LookUp') {
        this.restaurantService.getRestaurant(+child.snapshot.params['restaurantId']).subscribe(
          ( data ) => {
            this.breadcrumbs = [];
            this.restaurant.parse(data);
            this.breadcrumService.setRestaurant(this.restaurant);
            this.breadcrumbs.push(new Breadcrumb(this.restaurant.name, url + '/manage'));
            return this.getBreadcrumbs(child, url);
          },
          error => this.errorMessage = <any>error
        );
      } else {

        // In this case, we need to display 'Restaurants', 'Menus'
        if (bcType === 'Display') {
          this.setBreadcrumbDisplay(type);

        } else if (bcType === 'LookUp') {
          this.setBreadcrumbLookUp(type, child, url);
        }

        return this.getBreadcrumbs(child, url);
      }
    }
  }

  private getElementFromCollectionById<T extends Identifiable>(id: number, collection: T[]) {
    return collection.find(x => x.id === id);
  }

  private setBreadcrumbLookUp(type: string, route: ActivatedRoute, url: string) {
    let breadCrumb: Breadcrumb;
    switch (type) {
      case 'Menu': {
        this.menu = this.getElementFromCollectionById(+route.snapshot.params['menuId'], this.restaurant.menus);
        this.breadcrumService.setMenu(this.menu);
        breadCrumb = new Breadcrumb(this.menu.name, url + '/edit');
        break;
      }
      case 'MenuGroup': {
        this.menuGroup = this.getElementFromCollectionById(+route.snapshot.params['menuGroupId'], this.menu.groups);
        breadCrumb = new Breadcrumb(this.menuGroup.name, url + '/manage');
        this.breadcrumService.setMenuGroup(this.menuGroup);
        break;
      }
      case 'MenuItem': {
        this.menuItem = this.getElementFromCollectionById(+route.snapshot.params['menuItemId'], this.menuGroup.items);
        breadCrumb = new Breadcrumb(this.menuItem.name, url + '/edit');
        this.breadcrumService.setMenuItem(this.menuItem);
        break;
      }
    }
    if (breadCrumb && breadCrumb.label) {
      this.breadcrumbs.push(breadCrumb);
    }
  }

  private setBreadcrumbDisplay(type: string) {
    let breadcrumb: Breadcrumb;
    switch (type) {
      case 'Restaurant': {
        breadcrumb = new Breadcrumb('Restaurants', `/restaurant/create`);
        break;
      }
      case 'Menu': {
        breadcrumb = new Breadcrumb('Menus', `/restaurant/${this.restaurant.id}/manage`);
        break;
      }
      case 'MenuGroup': {
        breadcrumb = new Breadcrumb('Menu Groups', `/restaurant/${this.restaurant.id}/manage`);
        break;
      }
      case 'MenuItem': {
        breadcrumb = new Breadcrumb(
          'Menu Items',
          `/restaurant/${this.restaurant.id}/menu/${this.menu.id}/menu-group/${this.menuGroup.id}/manage`
        );
        break;
      }
    }
    if (breadcrumb && breadcrumb.label) {
      this.breadcrumbs.push(breadcrumb);
    }
  }
}
