import { Injectable } from '@angular/core';
import { TagModel, TagRequest } from '@generativ/wto-api-client';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class TagService {
  private tagsCache: TagModel[];

  constructor(
    private http: HttpClient,
  ) {

  }

  findByName(name: string, tags: TagModel[]) {
    for (const tag of tags) {
      if (tag.name === name) {
        return tag;
      }
    }
  }

  stringifyTags(tags: TagModel[]): string[] {
    const tagStrArr: string[] = [];
    for (const tag of tags) {
      tagStrArr.push(tag.name);
    }
    return tagStrArr;
  }

  get(type?: string): Observable<TagModel[]> {
    return this.tags.pipe(
      map((tags: TagModel[]) => {
        if (type) {
          return tags.filter((value) => {
            return value.type === type;
          });
        } else {
          return tags;
        }
      }));
  }

  get tags(): Observable<TagModel[]> {
    if (!this.tagsCache) {
      return this.http.get<TagModel[]>(`${environment.wtoApiUrl}/tags`)
        .pipe(
          map((response) => {
            if (Array.isArray(response)) {
              this.tagsCache = [];
              for (const tag of response) {
                this.tagsCache.push(tag);
              }
            }
            return this.tagsCache;
          }
        ));
    } else {
      return new Observable((observer: Observer<TagModel[]>) => {
        observer.next(this.tagsCache);
        observer.complete();
      });
    }
  }

  addTags(tags: TagRequest.CreateBulk): Observable<TagModel[]> {
    return this.http.post<TagModel[]>(`${environment.wtoApiUrl}/tags`, tags)
      .pipe(
        map(addedTags => {
          for (const tag of addedTags) {
            if (this.tagsCache) {
              this.tagsCache.push(new TagModel().parse(tag));
            }
          }
          return addedTags;
        })
      );
  }

  updateTagDisplay(tagId: number, display: number): Observable<TagModel> {
    return this.http.put<TagModel>(`${environment.wtoApiUrl}/tags/${tagId}/display`, display)
      .pipe(
        map(updatedTag => {
          let t = this.tagsCache.find(tag => tag.id === updatedTag.id);
          if (t) {
            t = new TagModel().parse(updatedTag);
          }
          return updatedTag;
        })
      );
  }
}
