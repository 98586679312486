import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class ParamsService {

  constructor() { }

  getParamById(id: string, route: ActivatedRouteSnapshot): string {
    if (route.params[id]) {
      return route.params[id];
    }
    if (route.parent) {
      return this.getParamById(id, route.parent);
    }
    return null;
  }
}
