<ul *ngIf="menu; else loader"
    class="list-unstyled list-drag" [(dragulaModel)]='menu.groups' [dragula]='"groups-bag"'>
  <li class="menu-group" *ngFor="let group of menu.groups">
    <div class="row">
      <div class="col-12">
        <div id="menu-group-name" class="form-control form-control-lg font-weight-bold">
          {{group.name}}
        </div>
      </div>
    </div>
  </li>
</ul>

<div class="done-button ml-auto">
  <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="onCloseModal()">Done</button>
</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
