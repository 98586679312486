import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filestackThumbnail'
})
export class FilestackThumbnailPipe implements PipeTransform {

  /**
   * Transforms an Filestack image URL into a thumbnail URL
   * Uses the Filestack Processing API to do the transformation and corrects EXIF rotation issue
   * Ref: https://github.com/filestack/filestack-js/issues/105
   *
   * @param url Filestack image URL (i.e. https://cdn.filestack.com/<optionalProcessingOptions>/<imageID>)
   * @param width Optional width, default 300
   * @param height Optional height, default 300
   * @return transformed URL, or original URL if cannot be parsed properly
   */
  transform(url: string, width = 300, height = 300): string {
    if (url && url.length) {
      const urlMatches = url.match(/(.*:\/\/.*\/)(\w*)/i);

      if (url.length > 2) {
        return urlMatches[1] + `resize=width:${width},height:${height}/rotate=deg:exif/` + urlMatches[2];
      }
    }
    return url;
  }

}
