<div class="modal-header" id="header-modal">
  <strong class="modal-title">{{title}}</strong>
  <span *ngIf="subheader" class="modal-title modal-sub-header">{{subheader}}</span>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">X</span>
  </button>
</div>
<div class="modal-body">
  <app-modal-content-template></app-modal-content-template>
</div>
