<form #idForm *ngIf="restaurant && content; else loader" [formGroup]="restaurantExportForm" (ngSubmit)="onExport()" autocomplete="off">

  <div class="form-group">
    <app-tooltip [tooltipText]="content.inputUnpublishedCheckbox.tooltip"></app-tooltip>
    &nbsp;
    <div class="form-check d-inline-block">
      <input id="includeUnpublished" type="checkbox" class="form-check-input"
             formControlName="includeUnpublished" name="unpublished">
      <label class="form-check-label" for="includeUnpublished">
        <span class="small">
          {{ content.inputUnpublishedCheckbox.label }}
        </span>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-4 center">
      <label for="reopening-date" class="form-control-label">
        <app-tooltip [tooltipText]="content.inputSelectMenus.tooltip"></app-tooltip> &nbsp;
        <span class="font-weight-bold">{{ content.inputSelectMenus.label }}</span>
      </label>
    </div>
  </div>
  <div formArrayName="menus">
    <div *ngFor="let menu of restaurantExportForm.get('menus').controls | activeFilterFormGroup: !restaurantExportForm.get('includeUnpublished').value; let i=index"  [formGroupName]="i" class="form-group">
      <div class="offset-1 form-check">
        <input type="checkbox" class="form-check-input" (change)="setMenuSelected(menu.controls.id.value)" id={{i}} name={{i}}>
        <label class="form-check-label" for={{i}}>
          {{menu.value.name}}
        </label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-4 center">
      <label for="reopening-date" class="form-control-label">
        <app-tooltip [tooltipText]="content.inputUpdatedSince.tooltip"></app-tooltip> &nbsp;
        {{ content.inputUpdatedSince.label }}
      </label>
    </div>
    <div class="col-8 date-picker">
      <input class="form-control form-control-lg" id="reopening-date"
             [placeholder]="content.inputUpdatedSince.placeholder || 'mm-dd-yyyy'"
             name="dp" formControlName="updatedSince" ngbDatepicker
             #d="ngbDatepicker"
             (click)="d.toggle()">
    </div>
  </div>
  <br>
  <div class="text-center form-footer">
    <span>
      <button type="submit" class="btn btn-outline-primary btn-sm" [disabled]="restaurantExportForm.invalid">
        <!-- The <span> below will change the button text to a
          3-dot loading animation while the form submits -->
        <span [ngClass]="waiting ? 'loader-small span-loader-large' : ''">
          Download
        </span>
      </button>
    </span>
  </div>
</form>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
