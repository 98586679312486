import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'activeFilterFormGroup'
})
export class ActiveFilterFormGroupPipe implements PipeTransform {
  /**
   * Check in one array of items if the control active has one value grater than minimumStatus
   * @param items
   * @param minimumStatus
   */
  transform(items: FormGroup[], minimumStatus: number): any {
    const filterItems = [];
    for (const item of items) {
      if (item && item.get('active').value >= minimumStatus) {
        filterItems.push(item);
      }
    }

    return filterItems;
  }

}
