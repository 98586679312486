<form #idForm [formGroup]="restaurantCloseForm" (ngSubmit)="submitForm()"
      autocomplete="off" *ngIf="ready && content; else loader">
  <div class="form-group">
    <div class="form-check d-inline-block">
      <input id="setOpen" type="radio" value="open" class="form-check-input" formControlName="closedStatus">
      <label class="form-check-label" for="setOpen">
        <span class="small font-weight-bold">{{ content.openLabel }}</span></label>
    </div>
  </div>

  <div class="form-group">
    <div class="form-check d-inline-block">
      <input id="setTakeoutDelivery" type="radio" value="takeoutDelivery" class="form-check-input" formControlName="closedStatus">
      <label class="form-check-label" for="setTakeoutDelivery">
        <span class="small font-weight-bold">{{ content.takeoutOrDeliveryOnlyLabel }}</span></label>
    </div>
  </div>

  <div class="form-group">
    <div class="form-check d-inline-block">
      <input id="setClosed" type="radio" value="tempClosed" class="form-check-input" formControlName="closedStatus">
      <label class="form-check-label" for="setClosed">
        <span class="small font-weight-bold">{{ content.temporarilyClosedLabel }}</span></label>
    </div>
  </div>
  <div class="row">
    <div class="col-4 center">
      <label for="reopening-date" class="form-control-label">
        <app-tooltip *ngIf="content.inputReopeningDate.tooltip" [tooltipText]="content.inputReopeningDate.tooltip"></app-tooltip> &nbsp;
        {{ content.inputReopeningDate.label }}
      </label>
    </div>
    <div class="col-8">
      <input class="form-control form-control-lg" id="reopening-date" placeholder="mm-dd-yyyy"
             name="dp" formControlName="reopenAt" ngbDatepicker #d="ngbDatepicker"
             [disabled]="restaurantCloseForm.controls.closedStatus.value !== 'tempClosed'"
             [minDate]="minDate"
             (click)="d.toggle()" >
      <small class="small-text">{{ content.inputReopeningDate.placeholder }}</small>
    </div>
  </div>
  <br>
  <div class="form-footer text-center">
    <span>
      <button type="submit" [disabled]="waiting"
              class="btn btn-outline-primary btn-sm">
        <!-- The <span> below will change the button text to a
            3-dot loading animation while the form submits -->
        <span [ngClass]="waiting ? 'loader-small span-loader' : ''">
          Submit
        </span>
      </button>
    </span>
  </div>
</form>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
