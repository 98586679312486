<form *ngIf="content && ready; else loader" [formGroup]="menuForm" (ngSubmit)="submitForm()" autocomplete="off"
      (keydown.enter)="$event.preventDefault()">
  <div *ngIf="errorMessage" class="text-danger">
    <i class="fa fa-warning"></i> {{errorMessage}}
  </div>
  <div class="form-group" [ngClass]="{'has-danger':hasDanger('name')}">
    <label for="menu-name" class="form-control-label">
      <app-tooltip *ngIf="content.inputName && content.inputName.tooltip" [tooltipText]="content.inputName.tooltip"></app-tooltip>
      {{ content.inputName?.label }}
    </label>
    <input type="text" id="menu-name" class="form-control form-control-lg"
           [placeholder]="content.inputName.placeholder || ''"
           formControlName="name"
           autocomplete="off">
    <div class="form-control-feedback">
      <span *ngIf="hasError('name', 'required')">{{ errorContent.required }}</span>
      <span *ngIf="hasError('name', 'maxlength')">{{ errorContent.maxlength }}</span>
    </div>
  </div>

  <ng-container *ngIf="'' | admin">
    <div class="form-group" [ngClass]="{'has-danger':hasDanger('url')}">
      <label for="menu-url" class="form-control-label">
        <app-tooltip *ngIf="content.inputMenuUrl && content.inputMenuUrl.tooltip" [tooltipText]="content.inputMenuUrl.tooltip"></app-tooltip>
        {{ content.inputMenuUrl?.label }}
      </label>
      <select formControlName="urlMonitoringFrequency">
        <option value="1 day">1 day</option>
        <option value="3 days">3 days</option>
        <option value="7 days">7 days</option>
        <option value="10 days">10 days</option>
        <option value="30 days">30 days</option>
        <option value="90 days">90 days</option>
        <option value="365 days">365 days</option>
      </select>
      <input type="url" id="menu-url" class="form-control form-control-lg"
             [placeholder]="content.inputMenuUrl.placeholder || ''"
             formControlName="url">
      <div class="form-control-feedback">
        <span *ngIf="hasError('url', 'invalidUrl')">{{ errorContent.isUrl }}</span>
        <span *ngIf="hasError('urlMonitoringFrequency', 'required')">Must provide monitoring frequency.</span>
      </div>
    </div>
<!--  Removed, but commented in case it ever comes back  -->
<!--    <div class="form-group" [ngClass]="{'has-danger':hasDanger('hiddenContentType')}">-->
<!--      <label class="form-control-label">-->
<!--        <app-tooltip *ngIf="content.inputHiddenContentOnMenuPage && content.inputHiddenContentOnMenuPage.tooltip"-->
<!--                     [tooltipText]="content.inputHiddenContentOnMenuPage.tooltip"></app-tooltip>-->
<!--        {{ content.inputHiddenContentOnMenuPage?.label }}-->
<!--      </label>-->
<!--      <div class="form-check d-inline-block">-->
<!--        <input type="checkbox" (click)="toggleHiddenContentOnMenuPage()" class="form-check-input" id="required-actions"-->
<!--               [placeholder]="content.inputHiddenContentOnMenuPage.placeholder || ''"-->
<!--               formControlName="hiddenContent">-->
<!--        <label class="form-check-label" for="required-actions">-->
<!--          <span>&nbsp;</span>-->
<!--        </label>-->
<!--      </div>-->
<!--      <select formControlName="hiddenContentType">-->
<!--        <option value="image">Image</option>-->
<!--        <option value="frame">Frame</option>-->
<!--        <option value="other">Other</option>-->
<!--      </select>-->
<!--      <div class="form-control-feedback">-->
<!--        <span *ngIf="hasError('hiddenContentType', 'required')">{{ errorContent.required }}</span>-->
<!--      </div>-->
<!--      <input type="url" class="form-control form-control-lg"-->
<!--             autocomplete="off"-->
<!--             [placeholder]="content.hiddenContentUrlOnMenuPage.placeholder || ''"-->
<!--             formControlName="hiddenContentUrl">-->
<!--      <div class="form-control-feedback">-->
<!--        <span *ngIf="hasError('hiddenContentUrl', 'invalidUrl')">{{ errorContent.isUrl }}</span>-->
<!--      </div>-->
<!--    </div>-->
  </ng-container>

  <div class="form-group" [ngClass]="{'has-danger':hasDanger('schedules')}">
    <label class="form-control-label">
      <app-tooltip *ngIf="content.inputMenuSchedules && content.inputMenuSchedules.tooltip"
                   [tooltipText]="content.inputMenuSchedules.tooltip"></app-tooltip>
      {{ content.inputMenuSchedules?.label }}
    </label><br>
    <div formArrayName="schedules">
      <div class="form-group" *ngFor="let schedule of menuForm.get('schedules').controls; let i=index"
           [formGroupName]="i">
        <div class="row schedule-selector">
          <div class="center">
            <strong class="numbering">{{i + 1}}</strong>
          </div>
          <div class="col-7">
            <!-- The if statement insxide the <small> element is to keep the header from appearing more than once on the top -->
            <small *ngIf="i===0">{{ content.inputDays?.label }}</small>
            <br>
            <div class="weekdays-selector">

              <input type="checkbox" id="weekday-mon-{{i}}" class="weekday" (change)="changeSelectDayStatus(i, 'mon')"
                     [disabled]="daysSelected[0] !== i && daysSelected[0] !== -1" [formControlName]="'mon'"/>
              <label for="weekday-mon-{{i}}">Mo</label>
              <input type="checkbox" id="weekday-tue-{{i}}" class="weekday" (change)="changeSelectDayStatus(i, 'tue')"
                     [disabled]="daysSelected[1] !== i && daysSelected[1] !== -1" [formControlName]="'tue'"/>
              <label for="weekday-tue-{{i}}">Tu</label>
              <input type="checkbox" id="weekday-wed-{{i}}" class="weekday" (change)="changeSelectDayStatus(i, 'wed')"
                     [disabled]="daysSelected[2] !== i && daysSelected[2] !== -1" [formControlName]="'wed'"/>
              <label for="weekday-wed-{{i}}">We</label>
              <input type="checkbox" id="weekday-thu-{{i}}" class="weekday" (change)="changeSelectDayStatus(i, 'thu')"
                     [disabled]="daysSelected[3] !== i && daysSelected[3] !== -1" [formControlName]="'thu'"/>
              <label for="weekday-thu-{{i}}">Th</label>
              <input type="checkbox" id="weekday-fri-{{i}}" class="weekday" (change)="changeSelectDayStatus(i, 'fri')"
                     [disabled]="daysSelected[4] !== i && daysSelected[4] !== -1" [formControlName]="'fri'"/>
              <label for="weekday-fri-{{i}}">Fr</label>
              <input type="checkbox" id="weekday-sat-{{i}}" class="weekday" (change)="changeSelectDayStatus(i, 'sat')"
                     [disabled]="daysSelected[5] !== i && daysSelected[5] !== -1" [formControlName]="'sat'"/>
              <label for="weekday-sat-{{i}}">Sa</label>
              <input type="checkbox" id="weekday-sun-{{i}}" class="weekday" (change)="changeSelectDayStatus(i, 'sun')"
                     [disabled]="daysSelected[6] !== i && daysSelected[6] !== -1" [formControlName]="'sun'"/>
              <label for="weekday-sun-{{i}}">Su</label>
            </div>
          </div>
          <div class="col-3 time-selector">
            <!-- The if statement inside the <small> elements is to keep the header from appearing more than once on the top -->
            <label class="text-center"><small *ngIf="i===0">{{ content.inputStartTime?.label }}</small><br>
              <input type="time" id="open" class="weekday" [formControlName]="'startTime'"/>
              <br>
              <div class="schedule-hours" [innerHtml]="schedule.controls['startTime'].value | scheduleHours"></div>
            </label>
            <label class="text-center"><small *ngIf="i===0">{{ content.inputEndTime?.label }}</small><br>
              <input type="time" id="close" class="weekday" [formControlName]="'endTime'"/>
              <br>
              <div class="schedule-hours" [innerHtml]="schedule.controls['endTime'].value | scheduleHours"></div>
            </label>
            &nbsp;
            <img class="icon" src="../../../assets/images/icon-add.svg" *ngIf="showAddScheduleButton(i)" (click)="addSchedule()"/>
            &nbsp;
            <!--Only show if unless one schedule is created-->
            <strong *ngIf="i > 0" (click)="removeSchedule(i)" class="cursor-default close-x">x</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="form-footer">
    <span [ngbTooltip]="tipContent" *ngIf="menuForm.invalid; else addGroupEnabled">
      <button *ngIf="!isEditMenu"
              class="btn btn-outline-primary btn-sm edit-button"
              [disabled]="!menuForm.valid || isSubmitting" type="submit">Add Group
      </button>
    </span>
    <ng-template #addGroupEnabled>
      <span>
        <button *ngIf="!isEditMenu"
                class="btn btn-outline-primary btn-sm edit-button"
                [disabled]="!menuForm.valid || isSubmitting" type="submit">Add Group
      </button>
      </span>
    </ng-template>
    <span class="pull-right">
      <span>
        <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="onCancel()">Cancel</button>
      </span>
      <span [ngbTooltip]="tipContent" *ngIf="menuForm.invalid; else doneEnabled">
        <button class="btn btn-outline-primary btn-sm" type="submit"
          [disabled]="!menuForm.valid || isSubmitting" (click)="doneClicked = true;">
            Done
        </button>
      </span>
      <ng-template #doneEnabled>
        <button class="btn btn-outline-primary btn-sm" type="submit"
          [disabled]="!menuForm.valid || isSubmitting" (click)="doneClicked = true;">
          <!-- The <span> below will change the button text to a
          3-dot loading animation while the form submits -->
          <span [ngClass]="doneClicked ? 'loader-small span-loader' : ''">
            Done
          </span>
        </button>
      </ng-template>
    </span>
  </div>
</form>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #tipContent>The following fields have errors:
  <br>
  <ul>
    <li *ngFor="let field of getValidationErrors()">
      {{ field }}
    </li>
  </ul>
</ng-template>
