import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderby'
})
export class OrderByPipe implements PipeTransform {

  constructor() {
  }


  transform(value: any, fieldOrderby: string): any {

    return value.sort((a, b) => {
      if (a[fieldOrderby] < b[fieldOrderby]) { return -1; }
      if (a[fieldOrderby] > b[fieldOrderby]) { return 1; }
      return 0;
    });
  }


}
