<main class="container" *ngIf="content">
  <div class="row form-container">
    <div class="col-8">
      <form class="row" *ngIf="('' | admin); else listOwnedRestaurants">
        <div class="form-group col-10">
          <label class="sr-only" for="restaurant-search">{{ content.searchPlaceholder }}</label>

          <ng-template #rt let-r="result" let-t="term">
            <div class="type-ahead">
              <strong>{{ r.name }}</strong>
              <p class="address">{{ r.address1 }}, {{ r.city}}, {{ r.state }}, {{ r.country }}</p>
            </div>
          </ng-template>

          <div class="form-group w-100" [class.has-danger]="searchFailed">
            <input id="restaurant-search" type="text" class="form-control form-control-lg w-100"
                   [(ngModel)]="restaurant" [ngModelOptions]="{standalone: true}"
                   [ngbTypeahead]="search"
                   [resultTemplate]="rt"
                   [inputFormatter]="formatter"
                   (selectItem)="onSelectItem($event)"
                   placeholder="{{ content.searchPlaceholder}}" />
            <span *ngIf="searching">searching...</span>
            <div class="form-control-feedback" *ngIf="searchFailed">Sorry, no restaurants found.</div>
          </div>
        </div>
        <div class="col-2">
          <button class="btn btn-block btn-clear btn-lg" (click)="manageRestaurant()">{{ content.searchButton }}</button>
        </div>
      </form>

      <ng-template #listOwnedRestaurants>
        <div class="form-group col-12">
          <div class="owned-container">
            <h6>Select a location</h6>
          </div>
          <br>
          <div role="listbox" class="dropdown-menu show">
            <button class="dropdown-item"
                    (mouseover)="applyClass(restaurant.id)"
                    (mouseout)="applyClass(restaurant.id, true)"
                    *ngFor="let restaurant of ownedRestaurants"
                    [id]="'restaurant-' + restaurant.id"
                    (click)="restaurantSelected(restaurant)">
              <div class="type-ahead name">
                <strong>{{ restaurant.name }}</strong>
                <p class="address">{{ restaurant.address1 }}, {{ restaurant.city}}, {{ restaurant.state }}, {{ restaurant.country }}</p>
              </div>
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="row" *ngIf="'' | admin">
    <div class="col-6 offset-3 text-center">
      <a [routerLink]="['/restaurant/create']">{{ content.newButton }}</a>
    </div>
  </div>
  <ng-container *ngIf="'' | superAdmin">
    <br>
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <a [routerLink]="['/menu-item/approval']">Photo Approval</a>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <a [routerLink]="['/search/assign']">Assign Restaurant</a>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <a [routerLink]="['/search']">Re-Index</a>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <a [routerLink]="['/search/tags']">Add Tags</a>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="'' | admin">
    <br>
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <a href="https://0k5e8vi1lg.execute-api.us-east-1.amazonaws.com/prod/dashboard">Change Detection Page</a>
      </div>
    </div>
  </ng-container>
</main>
