import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { MenuGroupModel, MenuGroupRequest, MenuGroupResponse, MenuModel } from '@generativ/wto-api-client';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MenuGroupService {

  private menuGroupUrl = environment.wtoApiUrl + '/menugroups/';
  private menuUrl = `${environment.wtoApiUrl}/menus/`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  createMenuGroup( menuGroup: MenuGroupRequest.Create ): Observable< MenuGroupResponse.Create > {

    return this.http.post<MenuGroupResponse.Create>(this.menuGroupUrl, menuGroup).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  editMenuGroup( menuGroup: MenuGroupRequest.Update ): Observable< MenuGroupResponse.Update > {

    return this.http.put<MenuGroupResponse.Update>(this.menuGroupUrl, menuGroup).pipe(
      catchError(this.errorHandlerService.handleError)
    );

  }

  copyMenuGroup(menuGroupRequest: MenuGroupRequest.Copy): Observable <MenuGroupResponse.Create> {
    return this.http.post<MenuGroupResponse.Create>(this.menuGroupUrl + 'copy' , menuGroupRequest)
      .pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }


  editMenuGroupOrder( menuGroup: MenuGroupRequest.OrderUpdate ): Observable< MenuGroupResponse.OrderUpdate > {

    return this.http.put<MenuGroupResponse.OrderUpdate>(this.menuGroupUrl + 'order', menuGroup).pipe(
      catchError(this.errorHandlerService.handleError)
    );

  }

  assignMenuItem(id: number, menuItemId: number): Observable <MenuGroupResponse.Update> {

    return this.http.put<MenuGroupResponse.Update>(this.menuGroupUrl + id + '/dishes/' + menuItemId, null)
      .pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }


  moveMenuGroup(id, menuId: number): Observable <MenuGroupModel> {
    return this.http.put<MenuGroupModel>(this.menuGroupUrl + id + '/relocate/' + menuId, null)
      .pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }


  getMenuGroup(id: number): Observable< MenuGroupModel > {
    return this.http
      .get<MenuGroupModel>(this.menuGroupUrl + id + '/force').pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  deleteMenuGroup(id: number): Observable<MenuGroupModel> {
    return this.http
      .delete<MenuGroupModel>(this.menuGroupUrl + id).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  getMenu(id: number): Observable< MenuModel > {

    return this.http
      .get<MenuModel>(this.menuUrl + id + '/force').pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }
}
