import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ContentfulService } from '../../shared/contentful.service';
import { EntryDictionary, PageCreateType } from '@generativ/wto-admin-types';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalService } from '../../shared/modal/modal.service';
import { Subscription } from 'rxjs/Subscription';
import { MenuService } from '../shared/menu.service';
import { ParamsService } from '../../shared/params.service';
import { MenuModel } from '@generativ/wto-api-client';

@Component({
  selector: 'app-menu-edit',
  templateUrl: './menu-edit.component.html',
  styleUrls: ['./menu-edit.component.scss']
})
export class MenuEditComponent implements OnInit, OnDestroy {
  content: PageCreateType;
  route: ActivatedRoute;
  menu: MenuModel;

  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();

  // RxJS Subscription is an excellent API for managing many unsubscribe calls.
  // See note below about unsubscribing.
  subs = new Subscription();

  constructor(
    private contentfulService: ContentfulService,
    private router: Router,
    private modalService: ModalService,
    private menuService: MenuService,
    private paramsService: ParamsService
  ) {
    this.route = this.modalService.getActivatedRoute();
  }

  ngOnInit() {
    this.contentfulService.getAdminEntry(EntryDictionary.PageCreate.pageEditMenu).then(
      (entry) => {
        this.content = new PageCreateType(entry);
      }
    );

    this.subs.add(this.route.params.subscribe((params: Params) => {
      const menuId = +this.paramsService.getParamById('menuId', this.route.snapshot);
      this.subs.add(
        // Todo: we should use one cache. Locally.
        this.menuService.getMenu(menuId).subscribe(menuModel => {
          this.menu = menuModel;
          // Set subHeader as [Food Menu Name]
          this.setSubHeader.emit(`${this.menu.name}`);
        }));
    }));
  }

  get title() {
    return 'Edit';
  }


  onCloseModal() {
    this.router.navigate(['../../../'], { relativeTo: this.route });
  }


  onCancel() {
    this.onCloseModal();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
