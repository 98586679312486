import { Component, OnInit } from '@angular/core';
import { PageCreateType, EntryDictionary } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';
import { ModalService } from '../../shared/modal/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-restaurant-create',
  templateUrl: './restaurant-create.component.html',
  styleUrls: ['./restaurant-create.component.scss']
})
export class RestaurantCreateComponent implements OnInit {
  content: PageCreateType;

  constructor(
    private contentfulService: ContentfulService,
    private modalService: ModalService,
    private router: Router
  ) {
  }


  ngOnInit() {
    this.contentfulService.getAdminEntry(EntryDictionary.PageCreate.pageAddRestaurant).then(
      (entry) => {
        this.content = new PageCreateType(entry);
      }
    );
  }

  get title() {
    return 'Add Location';
  }

  onCloseModal() {
    this.router.navigate(['../../'], { relativeTo: this.modalService.getActivatedRoute() });
  }

}
