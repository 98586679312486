<nav class="navbar navbar-expand-md navbar-light sticky-top">
  <div class="container">
    <div class="flex-container">
      <div class="flex-item flex-start">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a *ngIf="isAuthenticated()" [routerLink]="['/']" class="nav-link">Home</a>
          </li>
          <li class="nav-item">
           <a *ngIf="isAuthenticated()" class="nav-link" [href]="insightMonitorLnk">Insight Monitor</a>
          </li>
        </ul>
      </div>
      <div class="navbar-brand flex-item flex-center">
        <img src="../../../assets/images/logo-green-500.png" alt="WTO logo"/>
      </div>
      <div class="flex-item flex-end">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a *ngIf="isAuthenticated()" class="nav-link" href="https://www.whattoorder.com/downloads" target="_blank">QR Codes</a>
          </li>
          <li class="nav-item">
            <a *ngIf="isAuthenticated()" class="nav-link" (click)="logOut()">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
