import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import 'hammerjs';
import { AdminRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuardService } from './guards/auth-guard.service';
import { RestaurantModule } from './restaurant/restaurant.module';
import { MenuModule } from './menu/menu.module';
import { MenuGroupModule } from './menu-group/menu-group.module';
import { MenuItemModule } from './menu-item/menu-item.module';
import { FeedbackComponent } from './core/feedback/feedback.component';
import { TagService } from './shared/tag.service';
import { CoreModule } from './core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentfulService } from './shared/contentful.service';
import { CallbackComponent } from './callback/callback.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { MomentModule } from 'angular2-moment';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ParamsService } from './shared/params.service';
import { FilestackService } from './shared/filestack.service';
import { SearchComponent } from './search/search.component';
import { SearchService } from './shared/search.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { CurrencyPipe } from '@angular/common';
import { ToastService } from './shared/toast/toast.service';
import { CountryService } from './shared/helpers/country.service';
import { DragulaModule } from 'ng2-dragula';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PartialIndexComponent } from './search/partial-index/partial-index.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { SuperAdminGuard } from './guards/super-admin.guard';
import { RestaurantAssignComponent } from './search/restaurant-assign/restaurant-assign.component';
import { SharedModule } from './shared/shared.module';
import { TagsComponent } from './search/tags/tags.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      localStorage.getItem('token_id');
    },
    whitelistedDomains: ['localhost:7777',
      'api-dev-dot-whattoorder-165014.appspot.com',
      'api-dev-tracking-dot-whattoorder-165014.appspot.com',
      'api-staging-dot-whattoorder-165014.appspot.com',
      'api-staging-tracking-dot-whattoorder-165014.appspot.com',
      'api-prod-dot-whattoorder-165014.appspot.com',
      'api-prod-tracking-dot-whattoorder-165014.appspot.com',
    ]
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    SearchComponent,
    PartialIndexComponent,
    RestaurantAssignComponent,
    TagsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    AuthModule,
    RestaurantModule,
    MenuModule,
    MenuItemModule,
    MenuGroupModule,
    AdminRoutingModule,
    NgbModule,
    DragulaModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey,
      libraries: ['places']
    }),
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      iconClasses: {success: 'toast-success'}
    }),
    ReactiveFormsModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      }
    }),
    SharedModule,
    MultiselectDropdownModule
  ],
  entryComponents: [
    // TODO: Does the feedback component need to be an entry component?
    FeedbackComponent
  ],
  providers: [
    AuthGuardService,
    SuperAdminGuard,
    TagService,
    ParamsService,
    ContentfulService,
    FilestackService,
    SearchService,
    CurrencyPipe,
    ToastService,
    CountryService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
