import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../shared/modal/modal.service';
import { ParamsService } from '../../shared/params.service';
import { MenuGroupService } from '../shared/menu-group.service';
import { RestaurantService } from '../../restaurant/shared/restaurant.service';
import { MenuGroupModel, MenuModel, RestaurantModel } from '@generativ/wto-api-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { ToastService } from '../../shared/toast/toast.service';
import { AnimationEventsService } from '../../shared/events/animation-events.service';
import { FadeInEvent } from '../../shared/events/fade-in-event';

@Component({
  selector: 'app-menu-group-relocate',
  templateUrl: './menu-group-relocate.component.html',
  styleUrls: ['./menu-group-relocate.component.scss']
})
export class MenuGroupRelocateComponent implements OnInit, OnDestroy {

  route: ActivatedRoute;
  menuId: number;
  menu: MenuModel;
  menuGroupId: number;
  menuGroup: MenuGroupModel;
  restaurantId: number;
  restaurant: RestaurantModel;
  menus: MenuModel[];
  menuGroupMoveForm: FormGroup;
  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();
  restaurantSub: Subscription;
  resultSub: Subscription;
  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: ModalService,
    private menuGroupService: MenuGroupService,
    private restaurantService: RestaurantService,
    private paramsService: ParamsService,
    private toastService: ToastService,
    private animationEventsService: AnimationEventsService
  ) {
    this.route = this.modalService.getActivatedRoute();
    this.createFormGroup();
  }

  ngOnInit() {
    this.menuGroupId = +this.paramsService.getParamById('menuGroupId', this.route.snapshot);
    this.menuId = +this.paramsService.getParamById('menuId', this.route.snapshot);
    this.restaurantId = +this.paramsService.getParamById('restaurantId', this.route.snapshot);
    this.restaurantSub = this.restaurantService.getRestaurantCache(this.restaurantId).subscribe((restaurant) => {
      this.restaurant = restaurant;
      this.menu = this.restaurant.menus.find((menu) => {
        return menu.id === this.menuId;
      });
      this.menuGroup = this.menu.groups.find((menuGroup) => {
        return menuGroup.id === this.menuGroupId;
      });
      console.log('menu group ', this.menuGroup);
      this.menus = this.restaurant.menus.filter(item => item.type === this.menu.type && item.id !== this.menu.id);
      this.setSubHeader.emit(this.menuGroup.name);
    });
  }

  get title() {
    return 'MOVE GROUP';
  }


  onCloseModal() {
    this.router.navigate([`/restaurant/${this.restaurantId}`], { relativeTo: this.route });
  }


  onCancel() {
    this.onCloseModal();
  }

  submitForm() {
    this.isSubmitting = true;
    const newMenuId = this.menuGroupMoveForm.get('menuId').value;
    this.resultSub = this.menuGroupService.moveMenuGroup(this.menuGroupId, newMenuId).subscribe(
      (data) => {
        this.isSubmitting =  false;
        this.menuGroup = data;
        const alertMsg = `${ this.menuGroup.name } moved to ${ this.menus.find((m) => {
          return m.id === newMenuId; }).name }.`;
        this.toastService.showSuccess(alertMsg);
        this.animationEventsService.setMoveEvent(new FadeInEvent( `menu-group-${this.menuGroup.id}`));
        this.router.navigate([`/restaurant/${this.restaurantId}`], { relativeTo: this.route });
      },
      (err) => {
        // Todo: notify error.
        this.isSubmitting = false;
      }
    );
  }

  private createFormGroup() {
    this.menuGroupMoveForm = this.fb.group({
      menuId: [
        null,
        Validators.required
      ]
    });
  }

  ngOnDestroy() {
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
  }
}
