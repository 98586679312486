import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { MenuItemModel, RestaurantModel } from '@generativ/wto-api-client';

@Pipe({
  name: 'priceType'
})
export class PriceTypePipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe
  ) {
  }


  transform(menuItem: MenuItemModel, restaurant: RestaurantModel): string {
    if (menuItem.priceType === 'normalPrice') {
      return this.currencyPipe.transform(
        menuItem.price, restaurant.currencyCode, 'symbol', '1.2-2', navigator.language
      );
    } else if (menuItem.priceType === 'marketPrice') {
      return 'MP';
    } else if (menuItem.priceType === 'fixedPrice') {
      return 'FP';
    } else {
      return 'NP';
    }
  }


}
