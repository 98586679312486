import { Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { AuthGuardService } from '../guards/auth-guard.service';

export const homeRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  }
];
