import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { ErrorHandlerService } from '../shared/error-handler.service';
import {
  MenuGroupModel,
  MenuItemModel,
  MenuItemRequest,
  MenuItemResponse,
  PhotoApprovalModel
} from '@generativ/wto-api-client';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MenuItemService {

  private menuItemUrl = `${environment.wtoApiUrl}/dishes/`;
  private menuGroupUrl = environment.wtoApiUrl + '/menugroups/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  createMenuItem(menuItem: MenuItemRequest.Create): Observable<MenuItemResponse.Create> {
    return this.http
      .post<MenuItemResponse.Create>(this.menuItemUrl, menuItem).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  editMenuItem(request: MenuItemRequest.Update): Observable<MenuItemResponse.Update> {
    return this.http
      .put<MenuItemResponse.Update>(this.menuItemUrl, request).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  /**
   * We are retrieving the menu item model, in the api.
   * @param id
   */
  deleteMenuItem(id: number): Observable<MenuItemModel> {
    return this.http
      .delete<MenuItemModel>(this.menuItemUrl + id).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  getMenuItem(id: number): Observable<MenuItemModel> {
    return this.http
      .get<MenuItemModel>(this.menuItemUrl + id + '/force').pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  searchRestaurant(restaurantId: number, query: string): Observable<MenuItemModel[]> {

    return this.http
      .get<MenuItemModel[]>(`${environment.wtoApiUrl}/admin-search/restaurant-items/${restaurantId}/${query}/0/10`).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  changeMenuItemStatus(active: number, menuItemId: number): Observable<MenuItemModel> {
    return this.http
      .put<MenuItemModel>(this.menuItemUrl + `${menuItemId}/status/${active}`, { }).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  getMenuGroup(id: number): Observable< MenuGroupModel > {

    return this.http
      .get<MenuGroupModel>(this.menuGroupUrl + id + '/force').pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  moveMenuItem(id, menuGroupId: number): Observable <MenuItemModel> {
    return this.http.put<MenuItemModel>(this.menuItemUrl + id + '/relocate/' + menuGroupId, null)
      .pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  copyMenuItem(menuItemRequest: MenuItemRequest.Copy): Observable <MenuItemResponse.Create> {
    return this.http.post<MenuItemResponse.Create>(this.menuItemUrl + 'copy' , menuItemRequest)
      .pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  getPhotosForApproval(): Observable<PhotoApprovalModel[]> {
    return this.http
      .get<PhotoApprovalModel[]>(this.menuItemUrl + 'photos/list').pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  addPhotoApprovalAction(photoApproval: PhotoApprovalModel) {
    return this.http
      .put<PhotoApprovalModel[]>(this.menuItemUrl + 'photos/action', photoApproval).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  indexMenuItemsSince(daysSinceLastRun: number): Observable<{objectIDs: string[], taskID: number }> {
    return this.http
      .get<{objectIDs: string[], taskID: number }>(this.menuItemUrl + 'updateAlgoliaItems/' + daysSinceLastRun).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  indexInactiveMenuItems(): Observable<number> {
    return this.http.get<number>(this.menuItemUrl + 'index/updateAlgoliaInactiveItems')
      .pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }
}
