import { Injectable } from '@angular/core';
// import { AuthService } from './moltin/services/auth.service';
import {
  CanActivate,
  Router,
  ActivatedRoute,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  CanActivateChild
} from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../shared/user.service';
import { AdminPipe } from '../shared/pipes/admin.pipe';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private adminPipe: AdminPipe
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // If user is not logged in, redirect to login
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['auth', 'login']);
      localStorage.setItem('redirectUrl', state.url);
      return false;
    }

    if (!this.adminPipe.transform() && route.params.restaurantId) {
      const owner = this.userService.currentUser.restaurants
        .find(r => r.id.toString(10) === route.params.restaurantId);
      if (!owner) {
        confirm('You are not authorized to view this page');
        this.router.navigate(['']);
        return false;
      }
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // If user is not logged in, redirect to login
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['auth', 'login']);
      localStorage.setItem('redirectUrl', state.url);
      return false;
    }

    if (!this.adminPipe.transform() && route.params.restaurantId) {
      const owner = this.userService.currentUser.restaurants
        .find(r => r.id.toString(10) === route.params.restaurantId);
      if (!owner) {
        confirm('You are not authorized to view this page');
        this.router.navigate(['']);
        return false;
      }
    }

    return true;
  }
}
