<main class="container" *ngIf="content">
  <div class="headline-container">
    <h2>Assign Restaurant Ownership</h2>
  </div>

  <div class="row">
    <div class="col-12">
      <form class="row">
        <div class="form-group col-6">
          <div class="form-group w-100" [class.has-danger]="searchFailed">
            <ss-multiselect-dropdown class="form-control form-control-lg"
                                     [(ngModel)]="owner" [ngModelOptions]="{standalone: true}"
                                     [settings]="userSelectSettings"
                                     [options]="selectOwners">
            </ss-multiselect-dropdown>
          </div>
        </div>
        <div class="form-group col-6">
          <label class="sr-only" for="restaurant-search">{{ content.searchPlaceholder }}</label>

          <ng-template #rt let-r="result" let-t="term">
            <div class="type-ahead">
              <strong>{{ r.name }}</strong>
              <p class="address">{{ r.address1 }}, {{ r.city}}, {{ r.state }}, {{ r.country }}</p>
            </div>
          </ng-template>

          <div class="form-group w-100" [class.has-danger]="searchFailed">
            <input id="restaurant-search" type="text" class="form-control form-control-lg w-100"
                   [(ngModel)]="restaurant" [ngModelOptions]="{standalone: true}"
                   [ngbTypeahead]="search"
                   [resultTemplate]="rt"
                   [inputFormatter]="formatter"
                   (selectItem)="onSelectItem($event)"
                   placeholder="{{ content.searchPlaceholder}}" />
            <span *ngIf="searching">searching...</span>
            <div class="form-control-feedback" *ngIf="searchFailed">Sorry, no restaurants found.</div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <button class="btn btn-block btn-primary btn-lg"
              [disabled]="!restaurant || !owner || !owner.length"
              (click)="assignOwner()">Assign Restaurant Owner</button>
    </div>
  </div>
  <hr>
  <br>
  <div class="headline-container">
    <h2>Remove Restaurant Ownership</h2>
  </div>

  <div class="row">
    <div class="col-12">
      <form class="row">
        <div class="form-group col-6">
          <label class="sr-only" for="restaurant-owner-search">{{ content.searchPlaceholder }}</label>

          <ng-template #rt let-r="result" let-t="term">
            <div class="type-ahead">
              <strong>{{ r.name }}</strong>
              <p class="address">{{ r.address1 }}, {{ r.city}}, {{ r.state }}, {{ r.country }}</p>
            </div>
          </ng-template>

          <div class="form-group w-100" [class.has-danger]="searchFailed">
            <input id="restaurant-owner-search" type="text" class="form-control form-control-lg w-100"
                   [(ngModel)]="ownedRestaurant" [ngModelOptions]="{standalone: true}"
                   [ngbTypeahead]="ownedSearch"
                   [resultTemplate]="rt"
                   [inputFormatter]="formatter"
                   (selectItem)="onSelectItem($event)"
                   placeholder="{{ content.searchPlaceholder}}" />
            <span *ngIf="searching">searching...</span>
            <div class="form-control-feedback" *ngIf="searchFailed">Sorry, no restaurants found.</div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <button class="btn btn-block btn-primary btn-lg"
              [disabled]="!ownedRestaurant"
              (click)="unAssignOwner()">Remove Ownership</button>
    </div>
  </div>
</main>
