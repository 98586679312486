import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinAttribute'
})
export class JoinAttributePipe implements PipeTransform {

  transform(items: any[], attribute: string): string {
    return items.map(item => item[attribute]).join(', ');
  }
}
