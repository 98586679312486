import { Pipe, PipeTransform } from '@angular/core';
import { ADMIN_ROLES, AuthService } from '../../auth/auth.service';

@Pipe({
  name: 'superAdmin'
})
export class SuperAdminPipe implements PipeTransform {
  constructor(private authService: AuthService) {
  }

  transform(value = null): boolean {
    return this.authService.getHighestRole() === ADMIN_ROLES['super-admin'];
  }

}
