import { Component, OnInit } from '@angular/core';
import { SearchService } from '../shared/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  constructor(
    private searchService: SearchService
  ) { }

  ngOnInit() {
  }

  verifyRestaurantIndex() {
    if (confirm('Are sure you want to completely reset the restaurant index?')) {
      this.searchService.resetRestaurantIndex().subscribe(() => {
        alert('Restaurant index reset.');
      });
    }
  }

  verifyMenuItemIndex() {
    if (confirm('Are sure you want to completely reset the menu item index?')) {
      this.searchService.resetMenuItemIndex().subscribe(() => {
        alert('Menu item index reset.');
      });
    }
  }
}
