import { Pipe, PipeTransform } from '@angular/core';
import { ADMIN_ROLES, AuthService } from '../../auth/auth.service';

@Pipe({
  name: 'admin'
})
export class AdminPipe implements PipeTransform {
  constructor(private authService: AuthService) {
  }

  transform(value = null): boolean {
    return this.authService.getHighestRole() <= ADMIN_ROLES.admin;
  }

}
