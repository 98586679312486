import { Component } from '@angular/core';
import { MenuItemService } from '../../menu-item/menu-item.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-partial',
  templateUrl: 'partial-index.component.html',
  styleUrls: ['partial-index.component.scss']
})
export class PartialIndexComponent {
  daysSince: FormControl = new FormControl(0);

  constructor(
    private menuItemService: MenuItemService
  ) {
  }

  partialIndex() {
    this.menuItemService.indexMenuItemsSince(this.daysSince.value)
      .subscribe((res) => {
        alert(`${res.objectIDs.length} menu items from last ${this.daysSince.value} days indexed`);
      });
  }

  inactiveIndex() {
    this.menuItemService.indexInactiveMenuItems()
      .subscribe((res) => {
        alert(`${res} inactive menu items indexed`);
      });
  }
}
