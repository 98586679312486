import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '@generativ/wto-api-client';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentUser: UserModel;

  constructor(
    private http: HttpClient
  ) { }

  getUserByAuth0Id(auth0Id: string): Observable<UserModel> {
    return this.http.get<UserModel>(`${environment.wtoApiUrl}/users/auth0/${auth0Id}`)
      .pipe(
        map(user => new UserModel().parse(user))
      );
  }

  updateUserRoles(auth0Id: string, roles: string[]): Observable<UserModel> {
    return this.http.put<UserModel>(`${environment.wtoApiUrl}/users/roles/${auth0Id}`, roles)
      .pipe(
        map(user => {
          const u = new UserModel().parse(user);
          this.currentUser = u;
          return u;
        })
      );
  }

  addRestoreId(restoreId: string): Observable<UserModel> {
    this.currentUser.restoreId = restoreId;
    return this.http.put<UserModel>(`${environment.wtoApiUrl}/users/restore`, this.currentUser)
      .pipe(
        map(user => {
          const u = new UserModel().parse(user);
          localStorage.setItem('currentUser', JSON.stringify(u));
          return u;
        })
      );
  }

  getAllOwners(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${environment.wtoApiUrl}/users/list/owners`)
      .pipe(
        map(users => users.map(user => new UserModel().parse(user)))
      );
  }
}
