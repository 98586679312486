import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

declare var google: any;

@Component({
  selector: 'app-place-autocomplete',
  templateUrl: './place-autocomplete.component.html',
  styleUrls: ['./place-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class PlaceAutocompleteComponent implements OnInit, OnDestroy {

  @ViewChild('search')
  public searchElementRef: ElementRef;
  public searchControl: FormControl;

  @Output() fillIn = new EventEmitter<{ place: google.maps.places.PlaceResult, timeZone: string }>();
  @Output() startTyping = new EventEmitter<boolean>();

  private autocomplete: google.maps.places.Autocomplete;
  private autocompleteListener: google.maps.MapsEventListener;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private http: HttpClient
  ) { }

  ngOnInit() {
    // create search FormControl
    this.searchControl = new FormControl();

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {


      // If type property is npt specified, by default all types are returned
      // type =  geocode : return only geocoding results, rather than business results
      // type = address : return only geocoding results with a precise address
      // type = establishment : return only business results
      this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);

      this.autocompleteListener = this.autocomplete.addListener(
        'place_changed', () => this.fillInAddress());
      });

  }

  ngOnDestroy() {
    google.maps.event.removeListener(this.autocompleteListener);
  }

  fillInAddress() {
    const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
    if (place && place.geometry) {

      const targetDate = new Date();
      this.http.get('https://maps.googleapis.com/maps/api/timezone/json?location=' +
        place.geometry.location.lat() + ',' +
        place.geometry.location.lng() +
        '&timestamp=' + targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60 +
        '&key=' + environment.googleMapsKey)
        .subscribe((res: any) => {
          this.fillIn.emit({ place: place, timeZone: res });
        }, (error) => {
          console.error(`Timezone error: `, error);
        });
    }
  }

  // Notify Restaurant Form Component, that I start typing.
  onKey(event: any) {
    this.startTyping.emit();
  }

}
