<main class="container">
  <div class="row">
    <div class="col-12" style="text-align:center">
      <label for="days-input">Days to index</label>
      <input id="days-input" type="number" [formControl]="daysSince">
    </div>
  </div>
  <div class="row">
    <div class="col-12" style="text-align:center; padding-top: 20px;">
      <button (click)="partialIndex()"
              class="btn btn-primary btn-lg">Index Menu Items
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12" style="text-align:center; padding-top: 20px;">
      <button (click)="inactiveIndex()"
              class="btn btn-primary btn-lg">Index Inactive Menu Items
      </button>
    </div>
  </div>
</main>
