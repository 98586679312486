import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuItemService } from '../../menu-item.service';
import {
  ChoiceModel,
  MenuGroupModel,
  MenuItemModel,
  MenuItemRequest,
  MenuItemResponse,
  MenuModel,
  TagModel
} from '@generativ/wto-api-client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { TagService } from '../../../shared/tag.service';
import { EntryDictionary, FormMenuItemType, GenericErrorsType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../../shared/contentful.service';
import { GenericErrorService } from '../../../shared/generic-error-service';
import { ParamsService } from '../../../shared/params.service';
import { ModalService } from '../../../shared/modal/modal.service';
import { FilestackService } from '../../../shared/filestack.service';
import { RestaurantService } from '../../../restaurant/shared/restaurant.service';
import { ToastService } from '../../../shared/toast/toast.service';
import { ErrorHandlerService } from '../../../shared/error-handler.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AnimationEventsService } from '../../../shared/events/animation-events.service';
import { FadeInEvent } from '../../../shared/events/fade-in-event';
import { IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { urlValidator } from '../../../shared/directives/url-validator.directive';

@Component({
  selector: 'app-menu-item-form',
  templateUrl: './menu-item-form.component.html',
  styleUrls: ['./menu-item-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MenuItemFormComponent implements OnInit, OnDestroy {
  @Input() submitButtonText: string;

  menuItemId: number;
  currentMenuGroupId: number;
  menuItemForm: FormGroup;
  descriptionControl: AbstractControl;
  previousDescription = '';
  previousAltName = '';

  image: string;

  environmentalAllTags: TagModel[] = [];
  otherAllTags: TagModel[] = [];
  dietaryAllTags: TagModel[] = [];
  tagsLoaded = false;


  ready = false;
  content: FormMenuItemType;
  errorContent: GenericErrorsType;
  parentActive = true;
  isSubmitting = false;
  restaurantId;
  menuId;
  menu: MenuModel;
  menuGroup: MenuGroupModel;
  doneClicked: boolean;
  copyAndEditClicked: boolean;
  nextGroupClicked: boolean;
  isEditMenuItem: boolean;
  isFoodMenu: boolean;
  lastEnteredPrice: number;

  // Tags Settings
  tagSettings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    selectionLimit: 0,
    closeOnSelect: false,
    showUncheckAll: false,
    dynamicTitleMaxItems: 1,
    maxHeight: '300px'
  };

  routerSub: Subscription;
  restaurantSub: Subscription;
  resultSub: Subscription;
  menuItemSub: Subscription;
  menuGroupSub: Subscription;
  tagSub: Subscription;
  descriptionSub: Subscription;
  altNameSub: Subscription;
  currentModal: NgbModalRef;

  // Regex for prices
  pricePattern = /^(((0?|[1-9]\d{0,5})(\.\d{1,2})?))$/;
  // Regex to remove line endings
  lineEndings = /(\r\n|\n|\r)/gm;

  private menuItem: MenuItemModel;
  errorMessage: string;
  private route: ActivatedRoute;

  constructor(
    private fb: FormBuilder,
    private tagService: TagService,
    private menuItemService: MenuItemService,
    private router: Router,
    private contentfulService: ContentfulService,
    private genericErrorService: GenericErrorService,
    private paramsService: ParamsService,
    private filestackService: FilestackService,
    private modalService: ModalService,
    private restaurantService: RestaurantService,
    private toastService: ToastService,
    private errorHandlerService: ErrorHandlerService,
    private ngbModalService: NgbModal,
    private animationEventsService: AnimationEventsService
  ) {
    this.createFormGroup();
    this.route = this.modalService.getActivatedRoute();
  }

  ngOnInit() {
    this.genericErrorService.getErrorContent().then(
      (errorContent) => {
        this.errorContent = new GenericErrorsType(errorContent);
      }
    );

    this.routerSub = this.route.params.subscribe((params: Params) => {
      this.currentMenuGroupId = +this.route.snapshot.parent.params.menuGroupId;
      this.restaurantId = +this.paramsService.getParamById('restaurantId', this.route.snapshot);
      this.menuId = +this.paramsService.getParamById('menuId', this.route.snapshot);


      // Initialize the menu item type getting the type of the menu.
      this.restaurantSub = this.restaurantService.getRestaurantCache(this.restaurantId).subscribe((restaurant) => {
        this.menu = restaurant.menus.find((menu) => {
          return this.menuId === menu.id;
        });
        this.isFoodMenu = this.menu.type === 'food';
      });

      if (params.menuItemId) {
        this.isEditMenuItem = true;
        this.menuItemId = params.menuItemId;
        this.menuItemSub = this.menuItemService.getMenuItem(+params.menuItemId).subscribe(
          data => {
            this.menuItem = new MenuItemModel();
            this.menuItem.parse(data);
            // Load choices
            this.setChoices(data.choices);

            // Originally set last entered price to menu item's price.
            // If the price type is not normal, set last entered price to null.
            if (this.menuItem.priceType === 'normalPrice') {
              this.lastEnteredPrice = this.menuItem.price;
            } else {
              this.lastEnteredPrice = null;
              this.menuItem.price = null;
            }

            this.image = this.menuItem.image;
            console.log(`Get Menu Item - Parsed`, this.menuItem);

            this.patchMenuItemForm(this.menuItem);

            const selectedDietaryTags = [];
            const selectedEnvironmentalTags = [];
            const selectedOtherTags = [];
            // Load tags
            for (const tag of this.menuItem.tags) {
              switch (tag.type) {
                case 'menuItem:environmental':
                  selectedEnvironmentalTags.push(tag.id);
                  break;
                case 'menuItem:dietary':
                  selectedDietaryTags.push(tag.id);
                  break;
                case 'menuItem:other':
                  selectedOtherTags.push(tag.id);
                  break;
              }
            }

            this.menuItemForm.controls.environmentalOptionsSelected.setValue(selectedEnvironmentalTags);
            this.menuItemForm.controls.dietaryOptionsSelected.setValue(selectedDietaryTags);
            this.menuItemForm.controls.otherOptionsSelected.setValue(selectedOtherTags);

            this.ready = true;
          },
          error => this.errorMessage = <any>error
        );
      } else {
        this.addCategory();
        const menuGroupId = +this.paramsService.getParamById('menuGroupId', this.route.snapshot);
        this.menuGroupSub = this.menuItemService.getMenuGroup(menuGroupId).subscribe(
          menuGroupModel => {
            this.menuItem = new MenuItemModel();
            this.menuGroup = menuGroupModel;
            this.ready = true;

          },
          error => this.errorMessage = <any>error
        );
      }
    });
    // Get all tags and put them in the correct options
    this.tagSub = this.tagService.tags.subscribe(
      data => {
        const sortCuisine = this.orderByName(data);

        for (const tag of sortCuisine) {
          switch (tag.type) {
            case 'menuItem:environmental':
              this.environmentalAllTags.push(tag);
              break;
            case 'menuItem:dietary':
              this.dietaryAllTags.push(tag);
              break;
            case 'menuItem:other':
              this.otherAllTags.push(tag);
              break;
          }
        }
        this.tagsLoaded = true;

      }
    );

    this.contentfulService.getAdminEntry(EntryDictionary.FormMenuItem.formMenuItem).then(
      (entry) => {
        this.content = new FormMenuItemType(entry);
      }
    );

  }

  private getChoicesRequestModel(): ChoiceModel[] {
    const choices: ChoiceModel[] = [];

    for (const category of this.categories.value) {
      for (const choice of category.choices) {
        if (choice.name && choice.price !== undefined) {
          const choiceModel = new ChoiceModel();
          choiceModel.name = choice.name;
          choiceModel.price = choice.price;
          choiceModel.isRequired = category.isRequired;
          choiceModel.category = category.categoryName;
          choices.push(choiceModel);
        }
      }
    }
    return choices;
  }

  setChoices(choices: ChoiceModel[]) {
    if (choices.length > 0) {
      for (const choice of choices) {
        const categoryIndex = this.getCategoryByName(choice.category);
        if (categoryIndex >= 0) {
          this.addChoice(categoryIndex);
          const choicesArray = this.getChoices(categoryIndex);
          choicesArray.controls[choicesArray.length - 1].setValue({
            name: choice.name,
            price: choice.price
          });
        } else {
          this.addCategory();
          this.categories.controls[this.categories.length - 1].setValue({
            categoryName: choice.category,
            isRequired: choice.isRequired,
            choices: [
              {
                name: choice.name,
                price: choice.price
              }
            ]
          });
        }
      }
    } else {
      this.addCategory();
    }
  }

  /**
   * Check if one control is empty
   * @param control
   */
  private isEmpty(control: AbstractControl): boolean {
    const value = control.value;
    return (value === null || value === undefined || value === '');
  }

  /**
   * Validate if the choice form group is correct.
   * If the choice has one name but no price, it will mark this choice as invalid.
   * @param control
   */
  validateChoicePrice(control: FormControl) {
    if ((!this.isEmpty(control.get('name')) && this.isEmpty(control.get('price')))) {
      return {
        'Choice Price': { value: true, key: 'choice' }
      };
    }
    return null;
  }

  private getCategoryByName(name: string): number {
    return this.categories.value.findIndex(category => category.categoryName === name);
  }

  submitForm() {
    if (this.isSubmitting) {
      return;
    }

    this.errorMessage = null; // Reset the error before submit

    if (!this.menuItemForm.valid) {
      this.errorMessage = 'Errors on form. See fields below.';
      const error = this.menuItemForm.errors;
      return;
    }

    // If no change has occurred to the form on submit, just navigate back to
    // restaurant manage page instead of updating the menu item.
    if (!this.menuItemForm.dirty) {
      this.router.navigate(['restaurant', this.restaurantId]);
      return;
    }

    this.isSubmitting = true;

    this.menuItem.parse(this.menuItemForm.value);
    this.menuItem.image = this.image ? this.image : null;

    // Assign cuisineTypes to item -------------------
    this.menuItem.tags = [];

    for (const t of this.menuItemForm.controls.dietaryOptionsSelected.value) {
      const tag = this.dietaryAllTags.find(elem => elem.id === t);
      this.menuItem.tags.push(tag);
    }

    for (const t of this.menuItemForm.controls.environmentalOptionsSelected.value) {
      const tag = this.environmentalAllTags.find(elem => elem.id === t);
      this.menuItem.tags.push(tag);
    }

    for (const t of this.menuItemForm.controls.otherOptionsSelected.value) {
      const tag = this.otherAllTags.find(elem => elem.id === t);
      this.menuItem.tags.push(tag);
    }

    if (this.menuItemId) {
      // Update
      const updateRequest = new MenuItemRequest.Update();
      updateRequest.menuItem = this.menuItem;
      updateRequest.menuItem.choices = this.getChoicesRequestModel();


      console.log('Update Menu Item Request:', updateRequest);
      console.log(`Update MenuItem - Parsed:`, this.menuItem);
      this.resultSub = this.menuItemService.editMenuItem(updateRequest)
        .subscribe(
          updateResponse => {
            this.menuItem = updateResponse.menuItem;
            this.toastService.showSuccess(`${ this.menuItem.name } changed.`);
            this.router.navigate([`/restaurant/${this.restaurantId}`]);
            this.isSubmitting = false;
          },
          error => {
            this.errorMessage = <any>error;
            this.isSubmitting = false;
          }
        );
    } else {

      // Create

      const createRequest = new MenuItemRequest.Create().parse(this.menuItem);
      createRequest.menuGroupId = this.currentMenuGroupId;
      // Set the active with the current value of menu group active.
      createRequest.active = this.menuGroup.active;
      createRequest.choices = this.getChoicesRequestModel();

      this.resultSub = this.menuItemService.createMenuItem(createRequest)
        .subscribe(
          createResponse => {
            console.log(`Create MenuItem - Parsed:`, createResponse);
            createResponse = new MenuItemResponse.Create().parse(createResponse);

            this.menuItem.id = createResponse.menuItem.id;
            console.log('NEW ID:', this.menuItem.id);

            this.menuItem = createResponse.menuItem;

            this.toastService.showSuccess(`${ this.menuItem.name } added to ${ this.menuItem.menuGroupNames }.`);
            this.animationEventsService.setMoveEvent(new FadeInEvent( `menu-item-${createResponse.menuItem.id}`));

            if (this.doneClicked) {
              this.router.navigate([`/restaurant/${this.restaurantId}`]);
            } else if (this.nextGroupClicked) {
              this.router.navigate(['restaurant', this.restaurantId, 'menu', this.menuId, 'menu-group', 'create']);
            } else if (this.copyAndEditClicked) {
              this.menuItemId = null;
              this.menuItem.id = null;

              this.isSubmitting = false;
              this.copyAndEditClicked = false;

              // Scroll to top. 'header-modal' from modal-content.component.html
              const element = document.getElementById('header-modal');
              element.scrollIntoView();
            } else {
              this.menuItem = new MenuItemModel();
              // Reset form after submit is done and no errors were found.
              this.cleanFormGroup();
              this.isSubmitting = false;

              // Scroll to top. 'header-modal' from modal-content.component.html
              const element = document.getElementById('header-modal');
              element.scrollIntoView();
            }
          },
          error => {
            this.errorMessage = <any>error;
            this.isSubmitting = false;
            this.doneClicked = false;
            this.nextGroupClicked = false;
          },
          () => console.log('Request Complete')
        );
    }
  }

  selectPhoto() {
    this.filestackService.pickPhoto()
      .subscribe((image) => {
        this.image = image;
        console.log(`image Url: `, image);

        // Mark form as dirty so MI update occurs if only image changed.
        this.menuItemForm.markAsDirty();
      }, (error) => {
        console.log(`Error uploading file: `, error.toString());
      });
  }

  openModal(content) {
    this.currentModal = this.ngbModalService.open(content);
  }

  removePhoto() {
    this.image = null;
    this.currentModal.close();

    // Mark form as dirty so MI update occurs if only image changed.
    this.menuItemForm.markAsDirty();
  }

  hasError(field: string, validator: string) {
    const control = this.menuItemForm.controls[field];
    return control.hasError(validator)
      && control.touched;
  }

  hasDanger(field: string) {
    const control = this.menuItemForm.controls[field];
    return !control.disabled && !control.valid && control.touched;
  }

  hasArrayError(categoryIndex: number, choiceIndex: number, field: string, validator: string) {
    const choiceControl = this.getChoices(categoryIndex);
    const control = choiceControl.controls[choiceIndex] as any;
    const c = control.controls[field];

    return c.hasError(validator)
      && c.touched;
  }

  /**
   * Check if the form group associated with one choice has error.
   * @param categoryIndex
   * @param choiceIndex
   */
  hasPriceError(categoryIndex: number, choiceIndex: number) {
    // Get all the choices control for one category
    const choicesControl = this.getChoices(categoryIndex);
    // Get the control for that specific choice
    const choiceControl = choicesControl.controls[choiceIndex] as any;
    // Get the control associated with the price
    const priceControl = choiceControl.controls.price;
    // It will return true only if the choice control has one error,
    // and the price has been touched.

    return choiceControl.hasError('Choice Price')
      && priceControl.touched;
  }

  /**
   * Check if the category information is completed, once
   * the name and the price are filled.
   * @param categoryIndex
   */
  hasCategoryRequiredError(categoryIndex: number) {
    // Get the category control
    const categoryControl = this.categories.controls[categoryIndex];
    return categoryControl.hasError('Category Name')
      && categoryControl.touched;
  }

  selectPriceType(type: string) {
    // If the last type was normal price, record what the user entered.
    if (this.menuItemForm.controls.priceType.value === 'normalPrice') {
      this.lastEnteredPrice = this.menuItemForm.controls.price.value;
    }

    // In case we are undo one selecting one price type
    if (this.menuItemForm.controls.priceType.value === type || type === 'normalPrice') {
      this.selectNormalPrice();
    } else {
      this.menuItemForm.controls.price.setValidators([]);
      this.menuItemForm.controls.price.patchValue(null);
      this.menuItemForm.controls.price.updateValueAndValidity();
      this.menuItemForm.controls.priceType.patchValue(type);
      this.menuItemForm.controls.price.disable();

      // For some reason, changing the value with the radio button was not marking the
      // form as dirty, so we have to do it manually.
      this.menuItemForm.controls.priceType.markAsDirty();
    }
  }

  selectNormalPrice() {
    this.menuItemForm.controls.price.enable();
    this.menuItemForm.controls.price.setValidators([
      Validators.compose([
        Validators.required,
        Validators.pattern(this.pricePattern)
      ])
    ]);
    // If clicking back to normal price, revert back to last entered price.
    this.menuItemForm.controls.price.patchValue(this.lastEnteredPrice);
    this.menuItemForm.controls.priceType.patchValue('normalPrice');
    this.menuItemForm.controls.price.updateValueAndValidity();
  }

  onNextItem() {
    this.submitForm();
  }

  onCancel() {
    this.router.navigate([`/restaurant/${this.restaurantId}/`]);
  }

  get categories(): FormArray {
    return this.menuItemForm.get('categories') as FormArray;
  }


  validateCategory(category: FormGroup) {
    if (category.get('categoryName') && category.get('categoryName').value) {
      // In case category name is filled, no other validation is required.
      return null;
    }
    // In the other case we should check if at least one choice is filled
    if (category.get('choices')) {
      const choices = category.get('choices') as FormArray;
      for (const choice of choices.controls) {
        if (!this.isEmpty(choice.get('name')) && !this.isEmpty(choice.get('price'))) {
          return {
            'Category Name': { value: true}
          };
        }
      }
    }
    return null;
  }

  addCategory() {
    const category = this.fb.group({
      categoryName: [
        null
      ],
      isRequired: [
        true
      ],
      choices: this.fb.array([this.buildChoiceFormGroup()])
    });

    category.setValidators(this.validateCategory.bind(this));
    this.categories.push(category);
  }

  removeCategory(index: number) {
    this.categories.removeAt(index);
    this.menuItemForm.markAsDirty();
  }

  showAddCategoryButton(index: number): boolean {
    return this.categories.length - 1 === index;
  }

  getChoices(categoryIndex: number): FormArray {
    return this.categories.controls[categoryIndex].get('choices') as FormArray;
  }

  addChoice(categoryIndex: number) {
    this.getChoices(categoryIndex).push(this.buildChoiceFormGroup());
  }

  private buildChoiceFormGroup(): FormGroup {
    const fg = this.fb.group({
      name: [
        null
      ],
      price: [
        null, Validators.compose([
          Validators.pattern(this.pricePattern)
        ])
      ]
    });
    // Since we are using one validation that include check for more than one control
    // we set the validation to the form group.
    fg.setValidators(this.validateChoicePrice.bind(this));
    return fg;
  }

  showAddChoiceButton(categoryIndex: number, choiceIndex: number): boolean {
    return this.getChoices(categoryIndex).length - 1 === choiceIndex;
  }

  removeChoice(categoryIndex, choiceIndex: number) {
    this.getChoices(categoryIndex).removeAt(choiceIndex);
    this.menuItemForm.markAsDirty();
  }

  private patchMenuItemForm(menuItemModel) {
    // NgValidator js only validates string
    const tmp: any = menuItemModel;
    tmp['price'] = menuItemModel.price ? menuItemModel.price.toString() : undefined;

    this.menuItemForm.patchValue(menuItemModel);
    this.setPriceInputState(menuItemModel);
  }

  private setPriceInputState(menuItem: MenuItemModel) {
    if (menuItem.priceType !== 'normalPrice') {
      this.menuItemForm.controls.price.disable();
    }
  }

  private defaultValueForm(): any {
    return {
      name: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(100)
        ])
      ],
      altName: [
        null,
        Validators.compose([
          Validators.maxLength(1000)
        ])
      ],
      description: [
        null,
        Validators.compose([
          Validators.maxLength(1000)
        ])
      ],
      price: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.pricePattern)
        ])
      ],
      orderingUrl: [
        null,
        Validators.compose([
          Validators.maxLength(256),
          urlValidator()
        ])
      ],
      substitutions: [
        null,
        Validators.compose([])
      ],

      allergens: [
        null,
        Validators.compose([])
      ],
      cuisineTypes: [
        null,
        Validators.compose([])
      ],
      noPrice: false,
      priceType: 'normalPrice',
      otherOptionsSelected: [
        [],
        Validators.compose([])
      ],
      environmentalOptionsSelected: [
        [],
        Validators.compose([])
      ],
      dietaryOptionsSelected: [
        [],
        Validators.compose([])
      ],
      optionsCuisineModel: [
        null,
        Validators.compose([])
      ],
      categories: this.fb.array([])
    };
  }

  private cleanFormGroup() {
    this.menuItemForm.reset({
      priceType: 'normalPrice',
      otherOptionsSelected: [],
      environmentalOptionsSelected: [],
      dietaryOptionsSelected: []
    });
    this.menuItemForm.controls.price.enable();
    this.menuItemForm.controls.price.setValidators([
      Validators.compose([
        Validators.required,
        Validators.pattern(this.pricePattern)
      ])
    ]);
    this.menuItemForm.controls.price.updateValueAndValidity();
    while (this.categories.length !== 0) {
      this.categories.removeAt(0);
    }
    this.addCategory();
    this.image = null;
  }

  private createFormGroup() {
    // Remove previous control subscriptions
    if (this.descriptionSub) {
      this.descriptionSub.unsubscribe();
    }
    if (this.altNameSub) {
      this.altNameSub.unsubscribe();
    }

    this.menuItemForm = this.fb.group(this.defaultValueForm());

    // Remove all line endings when value of description changes.
    this.descriptionSub = this.menuItemForm.controls.description.valueChanges
      .subscribe((value: string) => {
        if (value && value.length > 0 && value !== this.previousDescription) {
          const newValue = value.replace(this.lineEndings, ' ');
          this.previousDescription = newValue;
          this.menuItemForm.controls.description.setValue(newValue);
        }
      });

    // Remove all line endings when value of altName changes.
    this.altNameSub = this.menuItemForm.controls.altName.valueChanges
      .subscribe((value: string) => {
        if (value && value.length > 0 && value !== this.previousAltName) {
          const newValue = value.replace(this.lineEndings, ' ');
          this.previousAltName = newValue;
          this.menuItemForm.controls.altName.setValue(newValue);
        }
      });
  }

  private orderByName(tags: TagModel[]): TagModel[] {
    return tags.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.menuItemSub) {
      this.menuItemSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
    if (this.menuGroupSub) {
      this.menuGroupSub.unsubscribe();
    }
    if (this.tagSub) {
      this.tagSub.unsubscribe();
    }
    if (this.descriptionSub) {
      this.descriptionSub.unsubscribe();
    }
    if (this.altNameSub) {
      this.altNameSub.unsubscribe();
    }
  }

  getValidationErrors(): string[] {
    return this.errorHandlerService.getValidationErrors(this.menuItemForm);
  }
}
