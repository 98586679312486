import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToBoolean'
})
export class ConvertToBooleanPipe implements PipeTransform {

  transform(value: number): boolean {
    return value ? true : false;
  }

}
