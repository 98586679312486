import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TooltipComponent } from './tooltip/tooltip.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { DragulaModule } from 'ng2-dragula';
import { ErrorHandlerService } from './error-handler.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { GenericErrorService } from './generic-error-service';
import { OrderByPipe } from './pipes/orderby.pipe';
import { UrlValidatorDirective } from './directives/url-validator.directive';
import { ModalContentComponent } from './modal/modal-content/modal-content.component';
import { ModalModule } from './modal/modal.module';
import { LoaderComponent } from './loader/loader.component';
import { ActiveFilterPipe } from './pipes/active-filter.pipe';
import { ConvertToBooleanPipe } from './pipes/convert-to-boolean.pipe';
import { JoinAttributePipe } from './pipes/join-attribute.pipe';
import { PriceTypePipe } from './pipes/price-type.pipe';
import { ScheduleHoursPipe } from './pipes/schedule-hours.pipe';
import { EventsModule } from './events/events.module';
import { ActiveFilterFormGroupPipe } from './pipes/active-filter-form-group.pipe';
import { PhotoApprovalImageSizingPipe } from './pipes/photo-approval-image-sizing.pipe';
import { FilestackThumbnailPipe } from './pipes/filestack-thumbnail.pipe';
import { MenuSavedRatedPipe } from './pipes/menu-saved-rated.pipe';
import { MenuGroupSavedRatedPipe } from './pipes/menu-group-saved-rated.pipe';
import { MenuItemSavedRatedPipe } from './pipes/menu-item-saved-rated.pipe';
import { SuperAdminPipe } from './pipes/super-admin.pipe';
import { AdminPipe } from './pipes/admin.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragulaModule,
    TextMaskModule,
    NgbModule,
    EventsModule
  ],
  declarations: [
    TooltipComponent,
    OrderByPipe,
    UrlValidatorDirective,
    LoaderComponent,
    ActiveFilterPipe,
    ActiveFilterFormGroupPipe,
    ConvertToBooleanPipe,
    JoinAttributePipe,
    PriceTypePipe,
    ScheduleHoursPipe,
    PhotoApprovalImageSizingPipe,
    FilestackThumbnailPipe,
    MenuSavedRatedPipe,
    MenuGroupSavedRatedPipe,
    MenuItemSavedRatedPipe,
    SuperAdminPipe,
    AdminPipe
  ],
  exports: [
    OrderByPipe,
    JoinAttributePipe,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragulaModule,
    TextMaskModule,
    NgbModule,
    ModalModule,
    TooltipComponent,
    LoaderComponent,
    ActiveFilterPipe,
    ActiveFilterFormGroupPipe,
    ConvertToBooleanPipe,
    PriceTypePipe,
    ScheduleHoursPipe,
    PhotoApprovalImageSizingPipe,
    EventsModule,
    FilestackThumbnailPipe,
    MenuSavedRatedPipe,
    MenuItemSavedRatedPipe,
    MenuGroupSavedRatedPipe,
    AdminPipe,
    SuperAdminPipe
  ],
  entryComponents: [ModalContentComponent],
  providers: [
    ErrorHandlerService,
    GenericErrorService,
    DatePipe,
    AdminPipe,
    SuperAdminPipe
  ]
})
export class SharedModule { }
