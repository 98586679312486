import { Injectable } from '@angular/core';

@Injectable()
export class CountryService {
  countryPrefixMap: Map<string, { countryCode: number, phoneMask: any }>;

  constructor() {
    this.countryPrefixMap = new Map<string, { countryCode: number, phoneMask: any }>();

    this.countryPrefixMap.set('AF', { countryCode:  93, phoneMask: null });
    this.countryPrefixMap.set('AL', { countryCode:  355, phoneMask: null });
    this.countryPrefixMap.set('DZ', { countryCode:  213, phoneMask: null });
    this.countryPrefixMap.set('AS', { countryCode:  1684, phoneMask: null });
    this.countryPrefixMap.set('AD', { countryCode:  376, phoneMask: null });
    this.countryPrefixMap.set('AO', { countryCode:  244, phoneMask: null });
    this.countryPrefixMap.set('AI', { countryCode:  1264, phoneMask: null });
    this.countryPrefixMap.set('AQ', { countryCode:  672, phoneMask: null });
    this.countryPrefixMap.set('AG', { countryCode:  1268, phoneMask: null });
    this.countryPrefixMap.set('AR', { countryCode:  54, phoneMask: null });
    this.countryPrefixMap.set('AM', { countryCode:  374, phoneMask: null });
    this.countryPrefixMap.set('AW', { countryCode:  297, phoneMask: null });
    this.countryPrefixMap.set('AU', { countryCode:  61, phoneMask: null });
    this.countryPrefixMap.set('AT', { countryCode:  43, phoneMask: null });
    this.countryPrefixMap.set('AZ', { countryCode:  994, phoneMask: null });
    this.countryPrefixMap.set('BS', { countryCode:  1242, phoneMask: null });
    this.countryPrefixMap.set('BH', { countryCode:  973, phoneMask: null });
    this.countryPrefixMap.set('BD', { countryCode:  880, phoneMask: null });
    this.countryPrefixMap.set('BB', { countryCode:  1264, phoneMask: null });
    this.countryPrefixMap.set('BY', { countryCode:  375, phoneMask: null });
    this.countryPrefixMap.set('BE', { countryCode:  32, phoneMask: null });
    this.countryPrefixMap.set('BZ', { countryCode:  501, phoneMask: null });
    this.countryPrefixMap.set('BJ', { countryCode:  229, phoneMask: null });
    this.countryPrefixMap.set('BM', { countryCode:  1441, phoneMask: null });
    this.countryPrefixMap.set('BT', { countryCode:  975, phoneMask: null });
    this.countryPrefixMap.set('BO', { countryCode:  591, phoneMask: null });
    this.countryPrefixMap.set('BA', { countryCode:  387, phoneMask: null });
    this.countryPrefixMap.set('BW', { countryCode:  267, phoneMask: null });
    this.countryPrefixMap.set('BR', { countryCode:  55, phoneMask: null });
    this.countryPrefixMap.set('IO', { countryCode:  246, phoneMask: null });
    this.countryPrefixMap.set('VG', { countryCode:  1284, phoneMask: null });
    this.countryPrefixMap.set('BN', { countryCode:  673, phoneMask: null });
    this.countryPrefixMap.set('BG', { countryCode:  359, phoneMask: null });
    this.countryPrefixMap.set('BF', { countryCode:  226, phoneMask: null });
    this.countryPrefixMap.set('BI', { countryCode:  257, phoneMask: null });
    this.countryPrefixMap.set('KH', { countryCode:  855, phoneMask: null });
    this.countryPrefixMap.set('CM', { countryCode:  237, phoneMask: null });
    this.countryPrefixMap.set('CA', { countryCode:  1, phoneMask: null });
    this.countryPrefixMap.set('CV', { countryCode:  238, phoneMask: null });
    this.countryPrefixMap.set('KY', { countryCode:  1345, phoneMask: null });
    this.countryPrefixMap.set('CF', { countryCode:  236, phoneMask: null });
    this.countryPrefixMap.set('TD', { countryCode:  235, phoneMask: null });
    this.countryPrefixMap.set('CL', { countryCode:  56, phoneMask: null });
    this.countryPrefixMap.set('CN', { countryCode:  86, phoneMask: null });
    this.countryPrefixMap.set('CX', { countryCode:  61, phoneMask: null });
    this.countryPrefixMap.set('CC', { countryCode:  61, phoneMask: null });
    this.countryPrefixMap.set('CO', { countryCode:  57, phoneMask: null });
    this.countryPrefixMap.set('KM', { countryCode:  269, phoneMask: null });
    this.countryPrefixMap.set('CK', { countryCode:  682, phoneMask: null });
    this.countryPrefixMap.set('CR', { countryCode:  506, phoneMask: null });
    this.countryPrefixMap.set('HR', { countryCode:  385, phoneMask: null });
    this.countryPrefixMap.set('CU', { countryCode:  53, phoneMask: null });
    this.countryPrefixMap.set('CW', { countryCode:  599, phoneMask: null });
    this.countryPrefixMap.set('CY', { countryCode:  357, phoneMask: null });
    this.countryPrefixMap.set('CZ', { countryCode:  420, phoneMask: null });
    this.countryPrefixMap.set('CD', { countryCode:  243, phoneMask: null });
    this.countryPrefixMap.set('DK', { countryCode:  45, phoneMask: null });
    this.countryPrefixMap.set('DJ', { countryCode:  253, phoneMask: null });
    this.countryPrefixMap.set('DM', { countryCode:  1767, phoneMask: null });
    this.countryPrefixMap.set('DO', { countryCode:  1809, phoneMask: null });
    this.countryPrefixMap.set('TL', { countryCode:  670, phoneMask: null });
    this.countryPrefixMap.set('EC', { countryCode:  593, phoneMask: null });
    this.countryPrefixMap.set('EG', { countryCode:  20, phoneMask: null });
    this.countryPrefixMap.set('SV', { countryCode:  503, phoneMask: null });
    this.countryPrefixMap.set('GQ', { countryCode:  240, phoneMask: null });
    this.countryPrefixMap.set('ER', { countryCode:  291, phoneMask: null });
    this.countryPrefixMap.set('EE', { countryCode:  372, phoneMask: null });
    this.countryPrefixMap.set('ET', { countryCode:  251, phoneMask: null });
    this.countryPrefixMap.set('FK', { countryCode:  500, phoneMask: null });
    this.countryPrefixMap.set('FO', { countryCode:  298, phoneMask: null });
    this.countryPrefixMap.set('FJ', { countryCode:  679, phoneMask: null });
    this.countryPrefixMap.set('FI', { countryCode:  358, phoneMask: null });
    this.countryPrefixMap.set('FR', { countryCode:  33, phoneMask: null });
    this.countryPrefixMap.set('PF', { countryCode:  689, phoneMask: null });
    this.countryPrefixMap.set('GA', { countryCode:  241, phoneMask: null });
    this.countryPrefixMap.set('GM', { countryCode:  241, phoneMask: null });
    this.countryPrefixMap.set('GE', { countryCode:  995, phoneMask: null });
    this.countryPrefixMap.set('DE', { countryCode:  49, phoneMask: null });
    this.countryPrefixMap.set('GH', { countryCode:  233, phoneMask: null });
    this.countryPrefixMap.set('GI', { countryCode:  350, phoneMask: null });
    this.countryPrefixMap.set('GR', { countryCode:  30, phoneMask: null });
    this.countryPrefixMap.set('GL', { countryCode:  299, phoneMask: null });
    this.countryPrefixMap.set('GD', { countryCode:  1473, phoneMask: null });
    this.countryPrefixMap.set('GU', { countryCode:  1671, phoneMask: null });
    this.countryPrefixMap.set('GT', { countryCode:  502, phoneMask: null });
    this.countryPrefixMap.set('GG', { countryCode:  441481, phoneMask: null });
    this.countryPrefixMap.set('GN', { countryCode:  224, phoneMask: null });
    this.countryPrefixMap.set('GW', { countryCode:  245, phoneMask: null });
    this.countryPrefixMap.set('GY', { countryCode:  592, phoneMask: null });
    this.countryPrefixMap.set('HT', { countryCode:  509, phoneMask: null });
    this.countryPrefixMap.set('HN', { countryCode:  504, phoneMask: null });
    this.countryPrefixMap.set('HK', { countryCode:  852, phoneMask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/] });
    this.countryPrefixMap.set('HU', { countryCode:  36, phoneMask: null });
    this.countryPrefixMap.set('IS', { countryCode:  354, phoneMask: null });
    this.countryPrefixMap.set('IN', { countryCode:  91, phoneMask: null });
    this.countryPrefixMap.set('ID', { countryCode:  62, phoneMask: null });
    this.countryPrefixMap.set('IR', { countryCode:  98, phoneMask: null });
    this.countryPrefixMap.set('IQ', { countryCode:  964, phoneMask: null });
    this.countryPrefixMap.set('IE', { countryCode:  353, phoneMask: null });
    this.countryPrefixMap.set('IM', { countryCode:  441624, phoneMask: null });
    this.countryPrefixMap.set('IL', { countryCode:  972, phoneMask: null });
    this.countryPrefixMap.set('IT', { countryCode:  39, phoneMask: null });
    this.countryPrefixMap.set('CI', { countryCode:  225, phoneMask: null });
    this.countryPrefixMap.set('JM', { countryCode:  1876, phoneMask: null });
    this.countryPrefixMap.set('JP', { countryCode:  81, phoneMask: null });
    this.countryPrefixMap.set('JE', { countryCode:  441534, phoneMask: null });
    this.countryPrefixMap.set('JO', { countryCode:  962, phoneMask: null });
    this.countryPrefixMap.set('KZ', { countryCode:  7, phoneMask: null });
    this.countryPrefixMap.set('KE', { countryCode:  254, phoneMask: null });
    this.countryPrefixMap.set('KI', { countryCode:  686, phoneMask: null });
    this.countryPrefixMap.set('XK', { countryCode:  383, phoneMask: null });
    this.countryPrefixMap.set('KW', { countryCode:  965, phoneMask: null });
    this.countryPrefixMap.set('KG', { countryCode:  996, phoneMask: null });
    this.countryPrefixMap.set('LA', { countryCode:  856, phoneMask: null });
    this.countryPrefixMap.set('LV', { countryCode:  371, phoneMask: null });
    this.countryPrefixMap.set('LB', { countryCode:  961, phoneMask: null });
    this.countryPrefixMap.set('LS', { countryCode:  266, phoneMask: null });
    this.countryPrefixMap.set('LR', { countryCode:  231, phoneMask: null });
    this.countryPrefixMap.set('LY', { countryCode:  218, phoneMask: null });
    this.countryPrefixMap.set('LI', { countryCode:  423, phoneMask: null });
    this.countryPrefixMap.set('LT', { countryCode:  370, phoneMask: null });
    this.countryPrefixMap.set('LU', { countryCode:  352, phoneMask: null });
    this.countryPrefixMap.set('MO', { countryCode:  853, phoneMask: null });
    this.countryPrefixMap.set('MK', { countryCode:  389, phoneMask: null });
    this.countryPrefixMap.set('MG', { countryCode:  261, phoneMask: null });
    this.countryPrefixMap.set('MW', { countryCode:  265, phoneMask: null });
    this.countryPrefixMap.set('MY', { countryCode:  60, phoneMask: null });
    this.countryPrefixMap.set('MV', { countryCode:  960, phoneMask: null });
    this.countryPrefixMap.set('ML', { countryCode:  223, phoneMask: null });
    this.countryPrefixMap.set('MT', { countryCode:  356, phoneMask: null });
    this.countryPrefixMap.set('MH', { countryCode:  692, phoneMask: null });
    this.countryPrefixMap.set('MR', { countryCode:  222, phoneMask: null });
    this.countryPrefixMap.set('MU', { countryCode:  230, phoneMask: null });
    this.countryPrefixMap.set('YT', { countryCode:  262, phoneMask: null });
    this.countryPrefixMap.set('MX', { countryCode:  52, phoneMask: null });
    this.countryPrefixMap.set('FM', { countryCode:  691, phoneMask: null });
    this.countryPrefixMap.set('MD', { countryCode:  373, phoneMask: null });
    this.countryPrefixMap.set('MC', { countryCode:  377, phoneMask: null });
    this.countryPrefixMap.set('MN', { countryCode:  976, phoneMask: null });
    this.countryPrefixMap.set('ME', { countryCode:  382, phoneMask: null });
    this.countryPrefixMap.set('MS', { countryCode:  1664, phoneMask: null });
    this.countryPrefixMap.set('MA', { countryCode:  212, phoneMask: null });
    this.countryPrefixMap.set('MZ', { countryCode:  258, phoneMask: null });
    this.countryPrefixMap.set('MM', { countryCode:  95, phoneMask: null });
    this.countryPrefixMap.set('NA', { countryCode:  264, phoneMask: null });
    this.countryPrefixMap.set('NR', { countryCode:  674, phoneMask: null });
    this.countryPrefixMap.set('NP', { countryCode:  977, phoneMask: null });
    this.countryPrefixMap.set('NL', { countryCode:  31, phoneMask: null });
    this.countryPrefixMap.set('AN', { countryCode:  599, phoneMask: null });
    this.countryPrefixMap.set('NC', { countryCode:  687, phoneMask: null });
    this.countryPrefixMap.set('NZ', { countryCode:  64, phoneMask: null });
    this.countryPrefixMap.set('NI', { countryCode:  505, phoneMask: null });
    this.countryPrefixMap.set('NE', { countryCode:  227, phoneMask: null });
    this.countryPrefixMap.set('NG', { countryCode:  234, phoneMask: null });
    this.countryPrefixMap.set('NU', { countryCode:  683, phoneMask: null });
    this.countryPrefixMap.set('KP', { countryCode:  850, phoneMask: null });
    this.countryPrefixMap.set('MP', { countryCode:  1670, phoneMask: null });
    this.countryPrefixMap.set('NO', { countryCode:  47, phoneMask: null });
    this.countryPrefixMap.set('OM', { countryCode:  968, phoneMask: null });
    this.countryPrefixMap.set('PK', { countryCode:  92, phoneMask: null });
    this.countryPrefixMap.set('PW', { countryCode:  680, phoneMask: null });
    this.countryPrefixMap.set('PS', { countryCode:  970, phoneMask: null });
    this.countryPrefixMap.set('PA', { countryCode:  507, phoneMask: null });
    this.countryPrefixMap.set('PG', { countryCode:  675, phoneMask: null });
    this.countryPrefixMap.set('PY', { countryCode:  595, phoneMask: null });
    this.countryPrefixMap.set('PE', { countryCode:  51, phoneMask: null });
    this.countryPrefixMap.set('PH', { countryCode:  63, phoneMask: null });
    this.countryPrefixMap.set('PN', { countryCode:  64, phoneMask: null });
    this.countryPrefixMap.set('PL', { countryCode:  48, phoneMask: null });
    this.countryPrefixMap.set('PT', { countryCode:  351, phoneMask: null });
    this.countryPrefixMap.set('PR', { countryCode:  1787, phoneMask: null });
    this.countryPrefixMap.set('QA', { countryCode:  973, phoneMask: null });
    this.countryPrefixMap.set('CG', { countryCode:  242, phoneMask: null });
    this.countryPrefixMap.set('RE', { countryCode:  262, phoneMask: null });
    this.countryPrefixMap.set('RO', { countryCode:  40, phoneMask: null });
    this.countryPrefixMap.set('RU', { countryCode:  7, phoneMask: null });
    this.countryPrefixMap.set('RW', { countryCode:  250, phoneMask: null });
    this.countryPrefixMap.set('BL', { countryCode:  590, phoneMask: null });
    this.countryPrefixMap.set('SH', { countryCode:  290, phoneMask: null });
    this.countryPrefixMap.set('KN', { countryCode:  1869, phoneMask: null });
    this.countryPrefixMap.set('LC', { countryCode:  1758, phoneMask: null });
    this.countryPrefixMap.set('MF', { countryCode:  590, phoneMask: null });
    this.countryPrefixMap.set('PM', { countryCode:  508, phoneMask: null });
    this.countryPrefixMap.set('VC', { countryCode:  1784, phoneMask: null });
    this.countryPrefixMap.set('WS', { countryCode:  685, phoneMask: null });
    this.countryPrefixMap.set('SM', { countryCode:  378, phoneMask: null });
    this.countryPrefixMap.set('ST', { countryCode:  239, phoneMask: null });
    this.countryPrefixMap.set('SA', { countryCode:  966, phoneMask: null });
    this.countryPrefixMap.set('SN', { countryCode:  221, phoneMask: null });
    this.countryPrefixMap.set('RS', { countryCode:  381, phoneMask: null });
    this.countryPrefixMap.set('SC', { countryCode:  248, phoneMask: null });
    this.countryPrefixMap.set('SL', { countryCode:  232, phoneMask: null });
    this.countryPrefixMap.set('SG', { countryCode:  65, phoneMask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] });
    this.countryPrefixMap.set('SX', { countryCode:  1721, phoneMask: null });
    this.countryPrefixMap.set('SK', { countryCode:  421, phoneMask: null });
    this.countryPrefixMap.set('SI', { countryCode:  386, phoneMask: null });
    this.countryPrefixMap.set('SB', { countryCode:  677, phoneMask: null });
    this.countryPrefixMap.set('SO', { countryCode:  252, phoneMask: null });
    this.countryPrefixMap.set('ZA', { countryCode:  27, phoneMask: null });
    this.countryPrefixMap.set('KR', { countryCode:  82, phoneMask: null });
    this.countryPrefixMap.set('SS', { countryCode:  211, phoneMask: null });
    this.countryPrefixMap.set('ES', { countryCode:  34, phoneMask: null });
    this.countryPrefixMap.set('LK', { countryCode:  94, phoneMask: null });
    this.countryPrefixMap.set('SD', { countryCode:  249, phoneMask: null });
    this.countryPrefixMap.set('SR', { countryCode:  597, phoneMask: null });
    this.countryPrefixMap.set('SJ', { countryCode:  47, phoneMask: null });
    this.countryPrefixMap.set('SZ', { countryCode:  268, phoneMask: null });
    this.countryPrefixMap.set('SE', { countryCode:  46, phoneMask: null });
    this.countryPrefixMap.set('CH', { countryCode:  41, phoneMask: null });
    this.countryPrefixMap.set('SY', { countryCode:  963, phoneMask: null });
    this.countryPrefixMap.set('TW', { countryCode:  886, phoneMask: null });
    this.countryPrefixMap.set('TJ', { countryCode:  992, phoneMask: null });
    this.countryPrefixMap.set('TZ', { countryCode:  255, phoneMask: null });
    this.countryPrefixMap.set('TH', { countryCode:  66, phoneMask: null });
    this.countryPrefixMap.set('TG', { countryCode:  228, phoneMask: null });
    this.countryPrefixMap.set('TK', { countryCode:  690, phoneMask: null });
    this.countryPrefixMap.set('TO', { countryCode:  676, phoneMask: null });
    this.countryPrefixMap.set('TT', { countryCode:  1868, phoneMask: null });
    this.countryPrefixMap.set('TN', { countryCode:  216, phoneMask: null });
    this.countryPrefixMap.set('TR', { countryCode:  90, phoneMask: null });
    this.countryPrefixMap.set('TM', { countryCode:  993, phoneMask: null });
    this.countryPrefixMap.set('TC', { countryCode:  1649, phoneMask: null });
    this.countryPrefixMap.set('TV', { countryCode:  688, phoneMask: null });
    this.countryPrefixMap.set('VI', { countryCode:  1340, phoneMask: null });
    this.countryPrefixMap.set('UG', { countryCode:  256, phoneMask: null });
    this.countryPrefixMap.set('UA', { countryCode:  380, phoneMask: null });
    this.countryPrefixMap.set('AE', { countryCode:  971, phoneMask: null });
    this.countryPrefixMap.set('GB', {
      countryCode:  44,
      phoneMask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    });
    this.countryPrefixMap.set('US', {
      countryCode:  1,
      phoneMask: ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    });
    this.countryPrefixMap.set('UY', { countryCode:  598, phoneMask: null });
    this.countryPrefixMap.set('UZ', { countryCode:  998, phoneMask: null });
    this.countryPrefixMap.set('VU', { countryCode:  678, phoneMask: null });
    this.countryPrefixMap.set('VA', { countryCode:  379, phoneMask: null });
    this.countryPrefixMap.set('VE', { countryCode:  58, phoneMask: null });
    this.countryPrefixMap.set('VN', { countryCode:  84, phoneMask: null });
    this.countryPrefixMap.set('WF', { countryCode:  681, phoneMask: null });
    this.countryPrefixMap.set('EH', { countryCode:  212, phoneMask: null });
    this.countryPrefixMap.set('YE', { countryCode:  987, phoneMask: null });
    this.countryPrefixMap.set('ZM', { countryCode:  260, phoneMask: null });
    this.countryPrefixMap.set('ZW', { countryCode:  263, phoneMask: null });
  }
}

