import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../shared/modal/modal.service';
import { ParamsService } from '../../shared/params.service';
import { RestaurantService } from '../../restaurant/shared/restaurant.service';
import { MenuItemService } from '../menu-item.service';
import { MenuGroupModel, MenuItemModel, MenuModel } from '@generativ/wto-api-client';
import { Subscription } from 'rxjs/Subscription';
import { ToastService } from '../../shared/toast/toast.service';
import { FadeInEvent } from '../../shared/events/fade-in-event';
import { AnimationEventsService } from '../../shared/events/animation-events.service';

@Component({
  selector: 'app-menu-item-relocate',
  templateUrl: './menu-item-relocate.component.html',
  styleUrls: ['./menu-item-relocate.component.scss']
})
export class MenuItemRelocateComponent implements OnInit, OnDestroy {

  route: ActivatedRoute;
  restaurantId: number;
  menuItemId: number;
  menuItem: MenuItemModel;
  menus: MenuModel[];
  menuSelected: MenuModel;
  menuGroups: MenuGroupModel[] = [];
  menuItemMoveForm: FormGroup;
  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();

  restaurantSub: Subscription;
  resultSub: Subscription;
  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: ModalService,
    private menuItemService: MenuItemService,
    private restaurantService: RestaurantService,
    private paramsService: ParamsService,
    private toastService: ToastService,
    private animationEventsService: AnimationEventsService
  ) {
    this.route = this.modalService.getActivatedRoute();
    this.createFormGroup();
  }

  ngOnInit() {
    const menuGroupId = +this.paramsService.getParamById('menuGroupId', this.route.snapshot);
    const menuId = +this.paramsService.getParamById('menuId', this.route.snapshot);
    this.restaurantId = +this.paramsService.getParamById('restaurantId', this.route.snapshot);
    this.menuItemId = +this.paramsService.getParamById('menuItemId', this.route.snapshot);
    this.restaurantSub = this.restaurantService.getRestaurantCache(this.restaurantId).subscribe((rest) => {
      const restaurant = rest;
      const menu = restaurant.menus.find((item) => {
        return item.id === menuId;
      });
      const menuGroup = menu.groups.find((mg) => {
        return mg.id === menuGroupId;
      });
      this.menuItem = menuGroup.items.find((mi) => {
        return mi.id === this.menuItemId;
      });
      this.menus = restaurant.menus.filter(m => m.type === this.menuItem.type &&
        m.groups.length);
      this.setSubHeader.emit(this.menuItem.name);
    });
  }

  get title() {
    return 'MOVE ITEM';
  }

  onChange() {
    this.menuSelected =  this.menuItemMoveForm.get('menu').value;
    this.menuGroups = this.menuSelected.groups;
  }

  onCloseModal() {
    this.router.navigate([`/restaurant/${this.restaurantId}`], { relativeTo: this.route });
  }


  onCancel() {
    this.onCloseModal();
  }

  submitForm() {
    this.isSubmitting = true;
    this.resultSub = this.menuItemService.moveMenuItem(this.menuItemId, this.menuItemMoveForm.get('menuGroupId').value).subscribe(
      (data) => {
        this.isSubmitting = false;
        // Todo: notify success.
        this.menuItem = data;
        this.toastService.showSuccess(`${ this.menuItem.name } moved to ${ this.menuItem.menuGroupNames }`);
        this.animationEventsService.setMoveEvent(new FadeInEvent(`menu-item-${this.menuItemId}`));
        this.router.navigate([`/restaurant/${this.restaurantId}`], { relativeTo: this.route });
      },
      (err) => {
        // Todo: notify error.
        this.isSubmitting = false;
      }
    );
  }

  private createFormGroup() {
    this.menuItemMoveForm = this.fb.group({
      menu: [
        null
      ],
      menuGroupId: [
        null,
        Validators.required
      ]
    });
  }

  ngOnDestroy() {
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
  }
}
