import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { EntryDictionary, PageCreateType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalService } from '../../shared/modal/modal.service';
import { MenuItemModel } from '@generativ/wto-api-client';
import { Subscription } from 'rxjs/Subscription';
import { ParamsService } from '../../shared/params.service';
import { MenuItemService } from '../menu-item.service';

@Component({
  selector: 'app-menu-item-edit',
  templateUrl: './menu-item-edit.component.html',
  styleUrls: ['./menu-item-edit.component.scss']
})
export class MenuItemEditComponent implements OnInit, OnDestroy {
  content: PageCreateType;
  route: ActivatedRoute;
  menuItem: MenuItemModel;

  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();

  // RxJS Subscription is an excellent API for managing many unsubscribe calls.
  // See note below about unsubscribing.
  subs = new Subscription();

  constructor(
    private contentfulService: ContentfulService,
    private modalService: ModalService,
    private router: Router,
    private menuItemService: MenuItemService,
    private paramsService: ParamsService
  ) {
    this.route = this.modalService.getActivatedRoute();
  }

  ngOnInit() {
    this.contentfulService.getAdminEntry(EntryDictionary.PageCreate.pageEditMenuItem).then(
      (entry) => {
        this.content = new PageCreateType(entry);
      }
    );

    this.subs.add(this.route.params.subscribe((params: Params) => {
      const menuItemId = +this.paramsService.getParamById('menuItemId', this.route.snapshot);
      this.subs.add(
        // Todo: we should use one cache. Locally.
        this.menuItemService.getMenuItem(menuItemId).subscribe(menuItemModel => {
          this.menuItem = menuItemModel;
          // Set subHeader as [Food Menu Name]
          this.setSubHeader.emit(`${this.menuItem.name}`);
        }));
    }));
  }

  get title() {
    return 'Edit';
  }

  onCloseModal() {
    this.router.navigate(['../../../../../../../'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
