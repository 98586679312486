<main *ngIf="restaurant && content" class="container">
  <div class="background"></div>
  <div class="sticky">
    <section class="header row">
      <div class="col-5">
        <button (click)="showDetail = !showDetail" class="header-button btn-clear">
          <i class="fa fa-chevron-down"></i>
        </button>
        <span (click)="openRestaurantEdit()" class="text-uppercase link">{{ restaurant.name }}</span>
        <span (click)="openRestaurantEdit()" class="font-weight-normal link">&nbsp;{{ restaurant.address1 }}</span>
      </div>

      <div class="col-3 text-left temporarily-closed">
        <div [ngClass]="(restaurant.closed === 2 || !restaurant.active) ? 'red-dot' :
         restaurant.closed === 1 ? 'yellow-dot' :
         restaurant.takeoutDeliveryOnly ? 'blue-dot' : 'green-dot'"></div>
        <a (click)="openRestaurantClose()" [title]="content.temporarilyClosedTooltip">
          {{ content.temporaryClosureButton }}</a>
        <a [ngbTooltip]="content.temporarilyClosedTooltip">
          <img src="../../../assets/images/icon-info-white.svg" class="icon"></a>
      </div>
      <div class="col-1 icon-container">
        <app-tooltip [tooltipText]="content.downloadAndEmailTooltip"></app-tooltip>
        <ul class="list-unstyled list-inline pull-right">
          <li class="list-inline-item">
            <a title="{{ content.tagTitles[0].titleText }}">
              <img class="icon" src="../../../assets/images/icon-email-gray.svg"
                   (click)="openRestaurantEmail()">
            </a>
          </li>
          <li class="list-inline-item">
            <a title="{{ content.tagTitles[1].titleText }}">
              <img class="icon" src="../../../assets/images/icon-download-gray.svg" (click)="openExportRestaurant()">
            </a>
          </li>
        </ul>
      </div>

      <div class="col-3 text-right">
        <span class="pr-2 font-weight-normal restaurant-publish">{{ content.publishLocationToggleLabel }}</span>
        <ng-container *ngIf="!toggleMap.get('restaurant-' + restaurant.id); else toggleRestaurantSubmittedTemplate">
          <ui-switch [disabled]="!hasMenuWithTimeAvailable() || (!!restaurant.active && !('' | admin))"
                     size="small" [(ngModel)]="!!restaurant.active"
                     (changeEvent)="openToggle('restaurant-' + restaurant.id, confirmChangeStatus)">
          </ui-switch>
        </ng-container>

        <ng-template #toggleRestaurantSubmittedTemplate>
          <span class="loader-small mx-3 mb-1"></span>
        </ng-template>

        <ng-template #confirmChangeStatus>
          <div class="modal-header">
            <strong class="modal-title text-uppercase">Warning</strong>
            <button type="button" class="close" aria-label="Close"
                    (click)="closeToggle(confirmChangeStatus, restaurant, 'restaurant-' + restaurant.id)">
              <span aria-hidden="true">X</span>
            </button>
          </div>
          <div class="modal-body">
            <p *ngIf="restaurant.active">{{ content.publishRestaurantMessage }}</p>
            <p *ngIf="!restaurant.active">{{ content.unpublishRestaurantMessage }}</p>
            <div class="text-center float-buttons">
              <button class="btn btn-outline-primary btn-sm"
                      (click)="closeToggle(confirmChangeStatus, restaurant, 'restaurant-' + restaurant.id)">Cancel
              </button>
              <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="changeRestauarantStatus()">Yes
              </button>
            </div>
          </div>
        </ng-template>
      </div>

      <aside *ngIf="showDetail" class="menu-overlay col-4 restaurant-sidebar">
        <ul class="list-unstyled">
          <li class="text-uppercase">
            <p>
              <button class="menu-overlay-button" (click)="showDetail = !showDetail">
                <i class="fa fa-chevron-up"></i>
              </button>
              <strong>{{ restaurant.name }}</strong>
            </p>
          </li>
          <li class="line-height">
            <button class="btn btn-outline-primary btn-sm" (click)="openRestaurantEdit()">
              {{ content.restaurantEditButton }}</button>
          </li>
          <li class="line-height">
            <img *ngIf="restaurant.image; else placeholder" src="{{ restaurant.image | filestackThumbnail }}"/>
            <ng-template #placeholder>
              <img src="assets/images/placeholders/placeholder-restaurant.jpg" style="height: 300px; width: 300px">
            </ng-template>
          </li>
          <li class="line-height">
            <p><strong>Neighborhood</strong><br>
              {{ restaurant.neighborhood }}</p>
          </li>
          <li class="line-height">
            <p><strong>Address</strong>
              {{ restaurant.address1 }}<br>
              <ng-container *ngIf="restaurant.address2">
                {{ restaurant.address2 }}
                <br>
              </ng-container>
              {{ addressDisplayString }}
            </p>
          </li>
          <li class="line-height">
            <p><strong>Phone</strong><br>
              {{ restaurant.phone | phone: restaurant.country }}</p>
          </li>
          <li class="line-height" *ngIf="'' | admin">
            <p><strong>URL</strong><br>
              {{ restaurant.websiteUrl }}</p>
          </li>
          <li class="line-height">
            <p><strong>Cuisine</strong><br>
              {{ restaurant.primaryCuisine?.name }}</p>
          </li>
        </ul>
      </aside>
    </section>
    <section class="subheader row">
      <div class="col-6 btn-col">
        <button class="btn btn-clear btn-sm first-button" (click)="openAddMenu('food')">{{ content.addMenuButton }}</button>
        <button class="btn btn-clear btn-sm" (click)="openAddMenu('drink')">{{ content.addDrinkMenuButton }}</button>
        <button class="btn btn-clear btn-sm" (click)="expandOrCollapseAll(true)">
          {{ content.expandAllButton }}</button>
        <button class="btn btn-clear btn-sm" (click)="expandOrCollapseAll()">
          {{ content.collapseAllButton }}</button>
      </div>
      <div class="col-6 text-right checkbox-col">
        <label class="form-control-label checkbox-label">{{ content.showUnpublishedLabel }}</label>
        <div class="checkbox-container">
          <input type="checkbox" class="form-check-input" id="showUnpublished"
                 (click)="toggleUnpublishedFilter()" [checked]="!activeFilterValue">
          <label class="checkmark" for="showUnpublished">
            <span>&nbsp;</span>
          </label>
        </div>
      </div>
    </section>
  </div>

  <ng-container *ngIf="menus && menus.length; else newMenu">
    <ul class="menus list-unstyled row" >
      <li class="menu col-12" *ngFor="let menu of menus | activeFilter: activeFilterValue; let i = index;">
        <div class="row menu-details" attr.id="menu-{{menu.id}}">
          <div class="col-6">
            <div *ngIf="!(menu | menuSavedRated); else redX" class="clear-dot"></div>
            <ng-template #redX>
              <img class="icon saved-rated" src="../../../assets/images/icon-deny.svg">
            </ng-template>
            <span class="accordion-toggle">
              <button (click)="expandedMenuMap[menu.id] = !expandedMenuMap[menu.id]"
                      [attr.aria-expanded]="!expandedMenuMap[menu.id]" [attr.aria-controls]="i">
                <i class="fa fa-chevron-down icon-expand"></i>
                <i class="fa fa-chevron-up icon-collapse"></i>
              </button>
              <span class="accordion-name">
                <a [class.link-disabled]="!menu.active"
                   (click)="menu.archive ? null : openMenuEdit(menu.id)" class="pr-1">{{ menu.name }}</a>
                <img *ngIf="menu.type==='drink'" class="icon pr-1" src="../../../assets/images/icon-drink.svg">
                <app-tooltip *ngIf="menu.archive && menu.type==='food'" [tooltipText]="content.foodArchiveTooltip"></app-tooltip>
                <app-tooltip *ngIf="menu.archive && menu.type==='drink'" [tooltipText]="content.drinkArchiveTooltip"></app-tooltip>
              </span>
            </span>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-6 justify-right">
                <!--Addtional <div> with the *ngIf below ensures that the "Add Group" button is aligned corrected
                whether or not the menu is archived-->
                <div *ngIf="!menu.archive">
                  <img *ngIf="!menu.timeUnavailable; else redX" class="icon"
                       src="../../../assets/images/icon-confirm.svg"
                       ngbTooltip="{{ content.tagTitles[12].titleText }}">
                  <ng-template #redX>
                    <img class="icon" src="../../../assets/images/icon-deny.svg"
                         ngbTooltip="{{ content.tagTitles[13].titleText }}">
                  </ng-template>
                </div>
              </div>
              <div class="col-6 btn-col">
                <a (click)="openAddMenuGroup(menu.id)" class="btn btn-clear btn-sm btn-block">
                  {{ content.addGroupButton }}</a>
              </div>
            </div>
          </div>
          <div class="col-2">
            <ul *ngIf="!menu.archive" class="list-unstyled list-inline pull-right hover-icons">
              <li class="list-inline-item">
                <a (click)="openReorderMenus()" title="{{ content.tagTitles[2].titleText }}">
                  <img src="../../../assets/images/icon-reorder.png" class="icon">
                </a>
              </li>
              <li class="list-inline-item">
                <a (click)="openModal(confirmCopyMenu)" title="{{ content.tagTitles[6].titleText }}">
                  <img src="../../../assets/images/icon-copy.svg" class="icon">
                </a>
              </li>
              <ng-template #confirmCopyMenu>
                <div class="modal-header">
                  <strong class="modal-title text-uppercase">Warning</strong>
                  <button type="button" class="close" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p> {{ content.copyMenuMessage }}</p>
                  <div class="text-center float-buttons">
                    <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="close()">Cancel</button>
                    <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="copyMenu(menu)">Yes
                    </button>
                  </div>
                </div>
              </ng-template>
              <li class="list-inline-item">
                <a (click)="openModal(confirmDeleteMenu)" title="{{ content.tagTitles[9].titleText }}">
                  <img src="../../../assets/images/icon-delete.svg" class="icon">
                </a>
              </li>
              <ng-template #confirmDeleteMenu>
                <div class="modal-header">
                  <strong class="modal-title text-uppercase">Warning</strong>
                  <button type="button" class="close" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>{{ content.deleteMenuMessage }}</p>
                  <div class="text-center float-buttons">
                    <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="close()">Cancel</button>
                    <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="deleteMenu(menu)">Yes
                    </button>
                  </div>
                </div>
              </ng-template>
            </ul>
          </div>
          <div class="col-1 text-right">
            <ng-container *ngIf="!toggleMap.get('menu-' + menu.id); else toggleMenuSubmittedTemplate">
              <ui-switch *ngIf="!menu.archive" size="small"
                         [(ngModel)]="!!menu.active"
                         [disabled]="restaurant.active === 0 || menu.timeUnavailable === 1"
                         (change)="openToggle('menu-' + menu.id, confirmChangeMenuStatus)"
              >
              </ui-switch>
            </ng-container>
            <ng-template #toggleMenuSubmittedTemplate>
              <span class="loader-small mr-3 mb-1"></span>
            </ng-template>

            <ng-template #confirmChangeMenuStatus>
              <div class="modal-header">
                <strong class="modal-title text-uppercase">Warning</strong>
                <button type="button" class="close" aria-label="Close"
                        (click)="closeToggle(confirmChangeMenuStatus, menu, 'menu-' + menu.id)">
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div class="modal-body">
                <p *ngIf="menu.active">{{ content.publishMenuMessage }}</p>
                <p *ngIf="!menu.active">{{ content.unpublishMenuMessage }}</p>
                <div class="text-center float-buttons">
                  <button class="btn btn-outline-primary btn-sm" (click)="closeToggle(confirmChangeMenuStatus, menu, 'menu-' + menu.id)">
                    Cancel
                  </button>
                  <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="changeMenuStatus(menu)">
                    Yes
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <ul class="menu-groups list-unstyled row" [id]="i" *ngIf="expandedMenuMap[menu.id]"
            [ngbCollapse]="!expandedMenuMap[menu.id]">
          <li class="menu-group col-12"
              *ngFor="let menuGroup of menu.groups | activeFilter: activeFilterValue; let g = index;"
              attr.id="menu-group-{{menuGroup.id}}">
            <div class="row group-details">
              <div class="col-6">
                <div *ngIf="!(menuGroup | menuGroupSavedRated); else redX" class="clear-dot"></div>
                <ng-template #redX>
                  <img class="icon saved-rated" src="../../../assets/images/icon-deny.svg">
                </ng-template>
                <span class="accordion-toggle">
                  <button (click)="expandedMenuGroupMap[menuGroup.id] = !expandedMenuGroupMap[menuGroup.id]"
                          [attr.aria-expanded]="!expandedMenuGroupMap[menuGroup.id]" [attr.aria-controls]="g">
                    <i class="fa fa-chevron-down icon-expand"></i>
                    <i class="fa fa-chevron-up icon-collapse"></i>
                  </button>
                  <span class="accordion-name">
                    <a [class.link-disabled]="!menu.active || menu.archive"
                       (click)="(menu.archive && menuGroup.name === 'Ungrouped Items') ? null :
                       openGroupEdit(menu.id, menuGroup.id)">{{ menuGroup.name }}</a>
                  </span>
                </span>
              </div>
              <div class="col-3">
                <div class="row">
                  <div class="col-6">
                  </div>
                  <div class="col-6 btn-col">
                    <a (click)="openAddMenuItem(menu.id, menuGroup.id)"
                       class="btn btn-clear btn-sm btn-block">{{ content.addMenuItemButton }}</a>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <ul *ngIf="!(menu.archive && menuGroup.name === 'Ungrouped Items')" class="list-unstyled list-inline pull-right hover-icons">
                  <li class="list-inline-item">
                    <a (click)="openReorderGroups(menu.id)" title="{{ content.tagTitles[3].titleText }}">
                      <img src="../../../assets/images/icon-reorder.png" class="icon">
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a (click)="openMoveMenuGroup(menu.id, menuGroup.id)" title="{{ content.tagTitles[4].titleText }}">
                      <img src="../../../assets/images/icon-move.svg" class="icon">
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a title="{{ content.tagTitles[7].titleText }}" (click)="openCopyMenuGroup(menu.id, menuGroup.id)">
                      <img src="../../../assets/images/icon-copy.svg" class="icon">
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a title="{{ content.tagTitles[10].titleText }}" (click)="openModal(confirmDeleteMenuGroup)">
                      <img src="../../../assets/images/icon-delete.svg" class="icon">
                    </a>
                  </li>
                  <ng-template #confirmDeleteMenuGroup>
                    <div class="modal-header">
                      <strong class="modal-title text-uppercase">Warning</strong>
                      <button type="button" class="close" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">X</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p>{{ content.deleteGroupMessage }}</p>
                      <div class="text-center float-buttons">
                        <button class="btn btn-outline-primary btn-sm" (click)="close()">Cancel</button>
                        <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting"
                                (click)="deleteMenuGroup(menuGroup)">Yes
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ul>
              </div>
              <div class="col-1 text-right">
                <ng-container *ngIf="!toggleMap.get('menuGroup-' + menuGroup.id); else toggleMenuGroupSubmittedTemplate">
                  <ui-switch *ngIf="menuGroup.name.indexOf('Ungrouped') === -1" size="small"
                             [(ngModel)]="!!menuGroup.active"
                             (change)="openToggle('menuGroup-' + menuGroup.id, confirmChangeMenuGroupStatus)"
                             [disabled]="!menu.active">
                  </ui-switch>
                </ng-container>
                <ng-template #toggleMenuGroupSubmittedTemplate>
                  <span class="loader-small mr-3 mb-1"></span>
                </ng-template>
                <ng-template #confirmChangeMenuGroupStatus>
                  <div class="modal-header">
                    <strong class="modal-title text-uppercase">Warning</strong>
                    <button type="button" class="close" aria-label="Close"
                            (click)="closeToggle(confirmChangeMenuGroupStatus, menuGroup, 'menuGroup-' + menuGroup.id)">
                      <span aria-hidden="true">X</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p *ngIf="menuGroup.active">{{ content.publishGroupMessage }}</p>
                    <p *ngIf="!menuGroup.active">{{ content.unpublishGroupMessage }}</p>
                    <div class="text-center float-buttons">
                      <button class="btn btn-outline-primary btn-sm"
                              (click)="closeToggle(confirmChangeMenuGroupStatus, menuGroup, 'menuGroup-' + menuGroup.id)">Cancel
                      </button>
                      <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting"
                              (click)="changeMenuGroupStatus(menuGroup)">Yes</button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <ul class="menu-items list-unstyled row" [(dragulaModel)]='menuGroup.items' [dragula]='"items-bag-" + menuGroup.id'
                [attr.menuId]="menu.id" [id]="g" *ngIf="expandedMenuGroupMap[menuGroup.id]"
                [ngbCollapse]="!expandedMenuGroupMap[menuGroup.id]">
              <li class="menu-item col-12" [attr.menugroupid]="menuGroup.id"   attr.id="menu-item-{{menuItem.id}}"
                  *ngFor="let menuItem of menuGroup.items | activeFilter: activeFilterValue;">
                <div class="row item-details">
                  <div class="col-6">
                    <div *ngIf="!(menuItem | menuItemSavedRated); else redX" class="clear-dot"></div>
                    <ng-template #redX>
                      <img class="icon saved-rated" src="../../../assets/images/icon-deny.svg">
                    </ng-template>
                    <span><a [class.link-disabled]="!menuItem.active"
                             (click)="openItemEdit(menu.id, menuGroup.id, menuItem.id)"> {{ menuItem.name }}</a></span>
                  </div>
                  <div class="col-3">
                    <div class="row">
                      <div class="col-6">
                        <ul class="list-unstyled list-inline pull-right">
                          <li class="list-inline-item" placement="top" ngbTooltip="{{menuItem.description}}"
                              container="body">
                            <img *ngIf="!menuItem.description" src="../../../assets/images/icon-description.svg"
                                 class="icon">
                            <img *ngIf="menuItem.description" src="../../../assets/images/icon-description-active.svg"
                                 class="icon">
                          </li>
                          <li class="list-inline-item" placement="top"
                              ngbTooltip="{{menuItem.tags | joinAttribute: 'name'}}" container="body">
                            <img *ngIf="menuItem.tags.length <= 0" src="../../../assets/images/icon-tag.svg"
                                 class="icon">
                            <img *ngIf="menuItem.tags.length > 0" src="../../../assets/images/icon-tag-active.svg"
                                 class="icon">
                          </li>
                          <li class="list-inline-item" placement="top" [ngbTooltip]="menuItem.image ? itemImage : null" container="body">
                            <img *ngIf="!menuItem.image" src="../../../assets/images/icon-camera.svg" class="icon">
                            <img *ngIf="menuItem.image" src="../../../assets/images/icon-camera-active.svg" class="icon">
                          </li>
                          <ng-template #itemImage><img src="{{ menuItem.image | filestackThumbnail }}"></ng-template>
                        </ul>
                      </div>
                      <!-- Displays menu item price -->
                      <div class="col-6 text-right">
                        {{ menuItem | priceType : restaurant }}
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <ul class="list-unstyled list-inline pull-right hover-icons">
                      <li class="list-inline-item">
                        <a (click)="openMoveMenuItem(menu.id, menuGroup.id, menuItem.id)"
                           title="{{ content.tagTitles[5].titleText }}">
                          <img src="../../../assets/images/icon-move.svg" class="icon">
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a title="{{ content.tagTitles[8].titleText }}"
                           (click)="openCopyMenuItem(menu.id, menuGroup.id, menuItem.id)">
                          <img src="../../../assets/images/icon-copy.svg" class="icon">
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a title="{{ content.tagTitles[11].titleText }}" (click)="openModal(confirmDeleteMenuItem)">
                          <img src="../../../assets/images/icon-delete.svg" class="icon">
                        </a>
                      </li>
                      <ng-template #confirmDeleteMenuItem>
                        <div class="modal-header">
                          <strong class="modal-title text-uppercase">Warning</strong>
                          <button type="button" class="close" aria-label="Close" (click)="close()">
                            <span aria-hidden="true">X</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <p>{{ content.deleteItemMessage }}</p>
                          <div class="text-center float-buttons">
                            <button class="btn btn-outline-primary btn-sm" (click)="close()">Cancel</button>
                            <button class="btn btn-outline-primary btn-sm" (click)="deleteMenuItem(menuItem)">Yes
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </ul>
                  </div>
                  <div class="col-1 text-right">
                    <!-- Removed this toggle from menu items as there is no confirmation screen, keeping them
                    commented out here in case we decide to put the toggle in place again. -->
                    <!--<ng-container *ngIf="!toggleMap.get('menuItem-' + menuItem.id); else toggleMenuItemSubmittedTemplate">-->
                      <ui-switch size="small" [checked]="!!menuItem.active"
                                  [disabled]="!menuGroup.active" (change)="changeMenuItemStatus(menuItem)">
                      </ui-switch>
                    <!--</ng-container>-->
                    <!--<ng-template #toggleMenuItemSubmittedTemplate>-->
                      <!--<span class="loader-small mr-5 mb-1"></span>-->
                    <!--</ng-template>-->
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </li>

    </ul>
  </ng-container>
  <ng-template #newMenu>
    <section class="new-restaurant">
      <div class="row my-3">
        <div class="col-2 offset-1 pl-4">
          <img src="../../../assets/images/cta-arrows.png">
        </div>
      </div>
      <div class="row">
        <div class="col-5 offset-1">
          <strong class="d-block mb-3">{{ content.noMenusHeadline }}</strong>
          <p>{{ content.noMenusMessage }}</p>
        </div>
      </div>
    </section>
  </ng-template>
  <!-- Add small amount of padding to bottom of list -->
  <div class="restaurant-manage-footer"></div>
</main>

<!--Outlet for the modal-->
<router-outlet></router-outlet>

