import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DayTime, MenuModel, MenuRequest, MenuType, RestaurantModel, WeekTime } from '@generativ/wto-api-client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MenuService } from '../menu.service';
import { EntryDictionary, FormMenuType, GenericErrorsType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../../shared/contentful.service';
import { GenericErrorService } from '../../../shared/generic-error-service';
import { ParamsService } from '../../../shared/params.service';
import { urlValidator } from '../../../shared/directives/url-validator.directive';
import { ModalService } from '../../../shared/modal/modal.service';
import { Subscription } from 'rxjs/Subscription';
import { ErrorHandlerService } from '../../../shared/error-handler.service';
import { ToastService } from '../../../shared/toast/toast.service';
import { AnimationEventsService } from '../../../shared/events/animation-events.service';
import { FadeInEvent } from '../../../shared/events/fade-in-event';


@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MenuFormComponent implements OnInit, OnDestroy {
  @Input() submitButtonText: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  content: FormMenuType;
  errorContent: GenericErrorsType;
  parentActive = true;
  menuId: number;
  menuForm: FormGroup;
  ready = false;
  isSubmitting = false;
  errorMessage: string;
  reset: boolean;
  menu: MenuModel;
  restaurant: RestaurantModel;
  route: ActivatedRoute;
  type: MenuType;
  doneClicked: boolean;
  isEditMenu: boolean;
  days: {value: string, display: string}[] = [
    { value: 'mon', display: 'Mo' },
    { value: 'tue', display: 'Tu' },
    { value: 'wed', display: 'We' },
    { value: 'thu', display: 'Th' },
    { value: 'fri', display: 'Fr' },
    { value: 'sat', display: 'Sa' },
    { value: 'sun', display: 'Su' },
  ];

  routerSub: Subscription;
  restaurantSub: Subscription;
  menuSub: Subscription;
  resultSub: Subscription;

  hasOneSchedule = false;

  daysSelected = [-1, -1, -1, -1, -1, -1, -1];

  private weekDays = {
    'sunday': 2,
    'monday': 3,
    'tuesday': 5,
    'wednesday': 7,
    'thursday': 11,
    'friday': 13,
    'saturday': 17
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private menuService: MenuService,
    private contentfulService: ContentfulService,
    private genericErrorService: GenericErrorService,
    private paramsService: ParamsService,
    private modalService: ModalService,
    private toastService: ToastService,
    private errorHandlerService: ErrorHandlerService,
    private animationEventsService: AnimationEventsService
  ) {
    this.createFormGroup();
    this.route = this.modalService.getActivatedRoute();
  }

  ngOnInit() {
    this.genericErrorService.getErrorContent().then(
      (errorContent) => {
        this.errorContent = new GenericErrorsType(errorContent);
      }
    );



    // for(let day of this.days) {
    //   this.selectedDays.set(day.value, 0);
    // }

    this.type = this.route.snapshot.queryParams.type === 'food' ? MenuType.Food : MenuType.Drink;
    this.routerSub = this.route.params.subscribe((params: Params) => {

      const restaurantId = +this.paramsService.getParamById('restaurantId',
        this.route.snapshot);

      this.restaurantSub = this.menuService.getRestaurant(restaurantId).subscribe(
        restaurantModel => {
          this.restaurant = restaurantModel;
          if (params['menuId']) {
            this.isEditMenu = true;
            this.menuId = +params['menuId'];
            this.menuSub = this.menuService.getMenu(this.menuId).subscribe(
              menuModel => {
                this.menu = new MenuModel();
                this.menu.parse(menuModel);
                this.menuForm.patchValue(this.menu);
                this.menuForm.controls['urlMonitoringFrequency']
                  .setValue(this.restaurant.websiteUrlMonitoringFrequency);
                this.menuForm.controls['urlMonitoringFrequency'].disable();

                for (const weekDay in this.menu.weekDaysTime) {
                  if (this.menu.weekDaysTime.hasOwnProperty(weekDay)) {
                    const dayTime: DayTime = this.menu.weekDaysTime[weekDay];
                    const schedules = this.menuForm.controls['schedules'];
                    let foundMatchingTime = false;

                    // for (const [scheduleIndex, schedule] of schedules['controls'].entries()) {
                    schedules['controls'].forEach((schedule, scheduleIndex) => {
                      const controls = schedule['controls'];

                      if (controls['startTime'].value === dayTime.start && controls['endTime'].value === dayTime.end) {
                        foundMatchingTime = true;
                        controls[weekDay].value = true;
                        this.changeSelectDayStatus(scheduleIndex, weekDay);
                      }
                    });

                    if (!foundMatchingTime) {
                      this.hasOneSchedule = true;
                      this.addSchedule();
                      const newIndex = schedules['controls'].length - 1;
                      schedules['controls'][newIndex]['controls'][weekDay].value = true;
                      schedules['controls'][newIndex]['controls']['startTime'].value = dayTime.start;
                      schedules['controls'][newIndex]['controls']['endTime'].value = dayTime.end;
                      this.changeSelectDayStatus(newIndex, weekDay);
                    }
                  }
                }

                // If no menu times are unavailable, add a blank placeholder schedule
                if (this.menu.timeUnavailable) {
                  this.addSchedule();
                }


                // if time is not available for that menu the status should be inactive and we shouldn't allowed the
                // menu to be active
                if (this.menu.timeUnavailable) {
                  this.menuForm.controls['active'].setValue(0);
                  this.menuForm.controls['active'].disable();
                }

                // If hidden content, enable type and url and check hidden content
                if (this.menu.hiddenContent) {
                  this.menuForm.controls['hiddenContentType'].enable();
                  this.menuForm.controls['hiddenContentUrl'].enable();

                  this.menuForm.controls['hiddenContent'].setValue(true);
                }
                this.ready = true;
              },
              error => this.errorMessage = <any>error
            );
          } else {
            this.menu = new MenuModel();
            this.menu.restaurantId = +this.route.parent.parent.snapshot.params['restaurantId'];
            this.addSchedule();
            this.menuForm.controls['urlMonitoringFrequency']
              .setValue(this.restaurant.websiteUrlMonitoringFrequency);
            this.menuForm.controls['urlMonitoringFrequency'].disable();
            this.ready = true;
          }

          if (restaurantModel.active === 0) {
            this.menuForm.controls['active'].disable();
            this.menuForm.controls['active'].setValue(0);
            this.parentActive = false;
          }
        },
        error => this.errorMessage = <any>error
      );
    });

    this.contentfulService.getAdminEntry(EntryDictionary.FormMenu.formMenu).then(
      (entry) => {
        this.content = new FormMenuType(entry);
      }
    );
  }


  submitForm() {
    if (this.isSubmitting) {
      return;
    }

    if (!this.menuForm.valid) {
      this.errorMessage = 'Errors on form. See fields below.';
      console.log(this.errorMessage);
      return;
    }

    // If no change has occurred to the form on submit, just navigate back to
    // restaurant manage page instead of updating the menu.
    if (!this.menuForm.dirty) {
      this.router.navigate(['restaurant/', this.menu.restaurantId]);
      return;
    }

    this.isSubmitting = true;

    const formValues = this.menuForm.value;

    this.menu.parse(formValues);

    const schedules = this.menuForm.controls['schedules'];

    this.menu.weekDaysTime = new WeekTime();

    let hasSchedule: boolean;
    if (schedules) {
      for (const group of schedules['controls']) {
        const controls = group['controls'];
        for (const prop in controls) {
          if (controls.hasOwnProperty(prop) && controls[prop].value === true) {
            hasSchedule = true;
            this.menu.weekDaysTime[prop] = new DayTime();
            this.menu.weekDaysTime[prop].start = controls['startTime'].value;
            this.menu.weekDaysTime[prop].end = controls['endTime'].value;
          }
        }
      }
      this.menu.timeUnavailable = hasSchedule ? 0 : 1;
    }

    this.menu.hiddenContent = this.menuForm.value['hiddenContent'];

    // If hidden content add type and url, otherwise set to null
    this.menu.hiddenContentType = this.menuForm.value['hiddenContentType'] ?
      this.menu.hiddenContentType : null;

    this.menu.hiddenContentUrl = this.menuForm.value['hiddenContentUrl'] ?
      this.menu.hiddenContentUrl : null;

    // If there is no url, don't save a url monitoring frequency.
    this.menu.urlMonitoringFrequency = this.menu.url ? this.menu.urlMonitoringFrequency : null;

    if (this.menuId) {
      // Update
      const updateRequest = new MenuRequest.Update();
      this.menu.durationDays = this.getWeekNumber();
      updateRequest.menu = this.menu;

      this.resultSub = this.menuService.editMenu(updateRequest)
        .subscribe(
          updateResponse => {
            this.menu = updateResponse.menu;
            this.toastService.showSuccess(`${ this.menu.name } changed.`);
            this.router.navigate(['restaurant/', this.menu.restaurantId]);
            console.log(`Update Menu - Parsed:`, updateResponse);
            this.isSubmitting = false;
          },
          error => {
            this.isSubmitting = false;
            this.errorMessage = <any> error;
          }
        );

    } else {
      // Create
      // Todo: Connect days time.
      // this.setDaysTime();
      const createRequest = new MenuRequest.Create().parse(this.menu);
      createRequest.durationDays = this.getWeekNumber();
      createRequest.weekDaysTimes = this.menu.weekDaysTime;
      createRequest.type = this.type;

      console.log('Create Menu Request:', createRequest);

      this.resultSub = this.menuService.createMenu(createRequest)
        .subscribe(
          createResponse => {
            this.menu = createResponse.menu;
            this.toastService.showSuccess(`${ this.menu.name } added.`);
            this.animationEventsService.setCreateMenuEvent(new FadeInEvent( `menu-${createResponse.menu.id}`));
            console.log(`Create Menu - Parsed:`, createResponse);
            this.isSubmitting = false;
            if (this.doneClicked) {
              this.router.navigate(['restaurant/', this.menu.restaurantId]);
            } else {
              this.router.navigate([`../${this.menu.id}/menu-group/create`], { relativeTo: this.route });
            }
          },
          (error) => {
            this.errorMessage = <any>error;
            this.doneClicked = false;
            this.isSubmitting = false;
          },
          () => console.log('Request Complete')
        );

    }
  }

  toggleHiddenContentOnMenuPage() {
    const hiddenTypeControl = this.menuForm.get('hiddenContentType');
    const hiddenUrlControl = this.menuForm.get('hiddenContentUrl');
    const hasAction = this.menuForm.value['hiddenContent'];
    hiddenTypeControl.disabled ? hiddenTypeControl.enable() : hiddenTypeControl.disable();
    hiddenUrlControl.disabled ? hiddenUrlControl.enable() : hiddenUrlControl.disable();
    !hasAction ? this.setHiddenContentOnMenuPageValidators() : this.setNoHiddenContentOnMenuPageValidators();
  }

  hasError(field: string, validator: string) {
    const control = this.menuForm.controls[field];
    return control.hasError(validator)
      && control.touched;
  }

  hasDanger(field: string) {
    const control = this.menuForm.controls[field];
    return !control.disabled && !control.valid && control.touched;
  }

  hasNgError(field: string, validator: string) {
    const control = this.menuForm.controls[field];
    return control.errors
      && control.errors[validator]
      && control.touched;
  }

  handleOnCancel() {
    this.router.navigate(['restaurant/', this.menu.restaurantId]);
  }

  get schedules(): FormArray {
    return this.menuForm.get('schedules') as FormArray;
  }

  onCancel() {
    this.cancel.emit(null);
  }

  openMenuAddGroup() {
    this.submitForm();
  }

  showAddScheduleButton(index: number): boolean {
    return this.schedules.length - 1 === index;
  }


  /**
   * Validate if in case that at least one menu group is set, if one schedule is valid
   * @param control
   */
  validateSchedule(control: FormControl) {
    if (this.hasOneSchedule) {
      for (const group of this.schedules.controls) {
        const controls = group['controls'];
        for (const prop in controls) {
          // In case at least one day has defined startTime and endTime. We shouldn't return any error.
          if (controls.hasOwnProperty(prop) && controls[prop].value === true
              && controls['startTime'].value && controls['endTime'].value) {
            return null;
          }
        }
        return {
          'Schedules': { value: true }
        };
      }
    } else {
      return null;
    }
  }

  addSchedule() {
    this.schedules.push(this.fb.group({
      mon: [
        { value: false, disabled: !this.isDayStateEditable(this.schedules.length, 'mon') }
      ],
      tue: [
        { value: false, disabled: !this.isDayStateEditable(this.schedules.length, 'tue') }
      ],
      wed: [
        { value: false, disabled: !this.isDayStateEditable(this.schedules.length, 'wed') }
      ],
      thu: [
        { value: false, disabled: !this.isDayStateEditable(this.schedules.length, 'thu') }
      ],
      fri: [
        { value: false, disabled: !this.isDayStateEditable(this.schedules.length, 'fri') }
      ],
      sat: [
        { value: false, disabled: !this.isDayStateEditable(this.schedules.length, 'sat') }
      ],
      sun: [
        { value: false, disabled: !this.isDayStateEditable(this.schedules.length, 'sun') }
      ],
      startTime: [
        null,
        Validators.compose([])
      ],
      endTime: [
        null,
        Validators.compose([])
      ],
    }));
  }

  removeSchedule(index: number) {
    for (const day of this.daysSelected) {
      if (day === index) {
        const dayName = this.days[this.daysSelected.indexOf(day)].value;
        this.daysSelected[this.daysSelected.indexOf(day)] = -1;
        const schedules = this.menuForm.controls['schedules'];

        // Loop through controls and enable the day on other controls that was unselected
        schedules['controls'].forEach((schedule, scheduleIndex) => {
          const controls = schedule['controls'];
          for (const row in controls) {
            if (controls.hasOwnProperty(row)) {
              if (dayName === row && index !== scheduleIndex) {
                controls[row].enable();
              }
            }
          }
        });
      }
    }
    this.schedules.removeAt(index);
  }

  changeSelectDayStatus(index: number, day: string) {
    const dayIndex = this.days.findIndex((dayX) => {
      return dayX.value === day;
    });

    // Remove day from schedule
    if (this.daysSelected[dayIndex] === index) {
      this.daysSelected[dayIndex] = -1;

      const schedules = this.menuForm.controls['schedules'];

      // Remove validation when no days left in schedule
      // and schedule is not active.
      // If menu was previously activated, it must have one valid schedule
      const hasDaySelected = this.daysSelected.indexOf(index);

      if (hasDaySelected === -1 && !this.menu.active) {
        schedules['controls'][index]['controls']['endTime'].setValidators(null);
        schedules['controls'][index]['controls']['endTime'].updateValueAndValidity();
        schedules['controls'][index]['controls']['startTime'].setValidators(null);
        schedules['controls'][index]['controls']['startTime'].updateValueAndValidity();
      }

      // Loop through controls and enable the day on other controls that was unselected
      schedules['controls'].forEach((schedule, scheduleIndex) => {
        const controls = schedule['controls'];
        for (const row in controls) {
          if (controls.hasOwnProperty(row)) {
            if (day === row && index !== scheduleIndex) {
              controls[row].enable();
            }
          }
        }
      });
    } else { // Add day to schedule
      this.daysSelected[dayIndex] = index;


      const schedules = this.menuForm.controls['schedules'];

      // Set validator for added day
      schedules['controls'][index]['controls']['endTime'].setValidators(Validators.required);
      schedules['controls'][index]['controls']['endTime'].updateValueAndValidity();
      schedules['controls'][index]['controls']['startTime'].setValidators(Validators.required);
      schedules['controls'][index]['controls']['startTime'].updateValueAndValidity();
      console.log(`Schedules: `, schedules);

      // Loop through controls and disable the day on other controls that was selected
      schedules['controls'].forEach((schedule, scheduleIndex) => {
        const controls = schedule['controls'];
        for (const row in controls) {
          if (controls.hasOwnProperty(row)) {
            if (day === row && index !== scheduleIndex) {
              controls[row].disable();
            }
          }
        }
      });
    }
  }

  /**
   * the status of the day can be changed only if it's selected in this schedule or it hasn't been selected
   * @param {string} day
   * @param {number} index
   * @returns {boolean}
   */
  isDayStateEditable(index: number, day: string): boolean {
    const dayIndex = this.days.findIndex((dayX) => {
      return dayX.value === day;
    });

    return this.daysSelected[dayIndex] === -1 || this.daysSelected[dayIndex] === index;
  }

  private setHiddenContentOnMenuPageValidators() {
    this.menuForm.controls['hiddenContentType'].setValidators([
      Validators.compose([
        Validators.required
      ])
    ]);
    this.menuForm.controls['hiddenContentType'].setValue(this.menu.hiddenContentType);

    this.menuForm.controls['hiddenContentUrl'].setValidators([
      Validators.compose([
        urlValidator()
      ])
    ]);
    this.menuForm.controls['hiddenContentUrl'].setValue(this.menu.hiddenContentUrl);
  }

  private setNoHiddenContentOnMenuPageValidators() {
    this.menuForm.controls['hiddenContentType'].setValidators([]);
    this.menuForm.controls['hiddenContentType'].updateValueAndValidity();
    this.menuForm.controls['hiddenContentType'].setValue(null);

    this.menuForm.controls['hiddenContentUrl'].setValidators([]);
    this.menuForm.controls['hiddenContentUrl'].updateValueAndValidity();
    this.menuForm.controls['hiddenContentUrl'].setValue(null);
  }

  private getWeekNumber(): number {
    let result = 1;

    for (const key in this.weekDays) {
      if (this.weekDays.hasOwnProperty(key)) {
        result = this.menuForm.value[key] ? result * this.weekDays[key] : result;
      }
    }
    return result;
  }

  private fillAvailableDays(menu: MenuModel) {

    for (const day in this.weekDays) {

      if (this.weekDays.hasOwnProperty(day)) {
        const shortDayName = day.substring(0, 3);
        if (this.menu.weekDaysTime[shortDayName]) {
          this.menuForm.controls[`${shortDayName}Start`].setValue(this.menu.weekDaysTime[shortDayName].start);
          this.menuForm.controls[`${shortDayName}End`].setValue(this.menu.weekDaysTime[shortDayName].end);
        } else {
          this.menuForm.controls[`${shortDayName}Start`].disable();
          this.menuForm.controls[`${shortDayName}End`].disable();
          this.menuForm.controls[day].setValue(false);
        }
      }
    }
  }


  createFormGroup() {
    const schedulesArray = this.fb.array([]);
    schedulesArray.setValidators(this.validateSchedule.bind(this));

    this.menuForm = this.fb.group({
      name: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(45)
        ])
      ],
      description: [
        null,
        Validators.compose([
          Validators.maxLength(150)
        ])
      ],
      listPageUrl: [
        null,
        Validators.compose([
          urlValidator()
        ])
      ],
      websiteUrl: [
        null,
        Validators.compose([
          urlValidator()
        ])
      ],
      time: [
        { hour: 13, minute: 30 },
        Validators.compose([
          Validators.maxLength(256),
        ])
      ],
      url: [
        null,
        urlValidator()
      ],
      urlMonitoringFrequency: '365 days',
      hiddenContent: false,
      hiddenContentType: [
        {
          value: null,
          disabled: true
        },
      ],
      hiddenContentUrl: [
        {
          value: null,
          disabled: true
        },
      ],
      active: [
        0,
        Validators.required
      ],
      schedules: schedulesArray
    });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
  }

  getValidationErrors(): string[] {
    return this.errorHandlerService.getValidationErrors(this.menuForm);
  }
}
