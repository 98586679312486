import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { ErrorHandlerService } from '../../shared/error-handler.service';
import {
  RestaurantModel,
  RestaurantRequest,
  RestaurantResponse,
  RestaurantSearchModel, TagModel, UserModel
} from '@generativ/wto-api-client';
import { Observable } from 'rxjs/Observable';
import * as algoliasearch from 'algoliasearch';
import { SearchClient, SearchIndex } from 'algoliasearch';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RestaurantService {

  private restaurantUrl = environment.wtoApiUrl + '/restaurants/';
  private client: SearchClient;
  private restaurantIndex: SearchIndex;
  private restaurant: Observable<RestaurantModel>;
  expandedMenuMap = {};
  expandedMenuGroupMap = {};

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {
    // @ts-ignore
    this.client = algoliasearch(environment.algolia.appId, environment.algolia.searchKey);
    this.restaurantIndex = this.client.initIndex(environment.algolia.restaurantIndex);
  }

  createRestaurant(restaurant: RestaurantRequest.Create): Observable< RestaurantResponse.Create > {

    return this.http.post<RestaurantResponse.Create>(this.restaurantUrl, restaurant).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  editRestaurant(request: RestaurantRequest.Update): Observable< RestaurantResponse.Update> {

    return this.http.put<RestaurantResponse.Update>(this.restaurantUrl + request.restaurant.id, request).pipe(
      catchError(this.errorHandlerService.handleError)
    );

  }

  editRestaurantCloseInformation(request: RestaurantRequest.Update): Observable< RestaurantResponse.Update> {

    return this.http.put<RestaurantResponse.Update>(this.restaurantUrl + request.restaurant.id + '/close', request)
      .pipe(catchError(this.errorHandlerService.handleError));

  }

  editMenuOrder(menuOrder: RestaurantRequest.OrderUpdate): Observable< RestaurantResponse.OrderUpdate > {
    return this.http.put<RestaurantResponse.OrderUpdate>(this.restaurantUrl + menuOrder.id + '/order', menuOrder).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  // Todo: we should improve this. Right now it's not caching.
  getRestaurant(id: number): Observable< RestaurantModel > {
    this.restaurant = this.http
      .get<RestaurantModel>(this.restaurantUrl + id + '/force').pipe(
        map(restaurant => {
          const r = new RestaurantModel().parse(restaurant);
          r.owner = new UserModel().parse(restaurant.owner);
          r.primaryCuisine = new TagModel().parse(restaurant.primaryCuisine);
          return r;
        }),
        catchError(this.errorHandlerService.handleError)
      );
    return this.restaurant;
  }

  getRestaurantCache(id: number): Observable <RestaurantModel> {
    return this.restaurant;
  }

  exportRestaurant(id: number, menuIds: string, updatedSince: Date, unpublishedChecked: boolean): Observable< any > {

    let params = new HttpParams();
    params = params.append('unpublishedChecked', (unpublishedChecked ? 1 : 0).toString());
    // In the case no time is selected we set the epoc time as 0, so it will create one date in 1969.
    params = params.append('updatedSince', updatedSince ? updatedSince.getTime().toString() : '0');
    params = params.append('menuIds', menuIds);

    return this.http
      .get<RestaurantModel>(this.restaurantUrl + id + '/export', { params: params}).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  sendEmail(id: number, sendEmailInfo: RestaurantRequest.SendEmail): Observable< RestaurantResponse.Create > {

    return this.http.post<any>(`${this.restaurantUrl}${id}/sendEmail`, sendEmailInfo).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  async search(query: string, ownerSearch = false): Promise<RestaurantSearchModel[]> {

    const queryParams = {
      hitsPerPage: 100,
      filters: ownerSearch ? 'hasOwner=1' : ''
    };
    return this.restaurantIndex.search(query, queryParams).then((res: any) => {
      return res.hits;
    });
  }

  assignOwnership(restaurantId: number, ownerId: number): Observable<RestaurantModel> {
    return this.http.put<RestaurantModel>(`${this.restaurantUrl}${restaurantId}/assignOwner`, ownerId)
      .pipe(
        map(restaurant => new RestaurantModel().parse(restaurant))
      );
  }

}
