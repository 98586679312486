import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable()
export class SearchService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {

  }


  public resetRestaurantIndex(): Observable<any> {
    return this.http
      .get(`${environment.wtoApiUrl}/admin-search/restaurants/index`).pipe(
        catchError(this.errorHandlerService.handleError)
      );

  }

  public resetMenuItemIndex(): Observable<any> {
    return this.http
      .get(`${environment.wtoApiUrl}/admin-search/menu-items/index`).pipe(
        catchError(this.errorHandlerService.handleError)
      );

  }
}
