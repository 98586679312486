import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RestaurantService } from '../shared/restaurant.service';
import {
  MenuGroupModel,
  MenuGroupRequest,
  MenuItemModel,
  MenuItemRequest,
  MenuModel,
  MenuRequest,
  RestaurantModel,
  RestaurantRequest
} from '@generativ/wto-api-client';
import { DragulaService } from 'ng2-dragula';
import { MenuService } from '../../menu/shared/menu.service';
import { EntryDictionary, PageOverviewRestaurantType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuGroupService } from '../../menu-group/shared/menu-group.service';
import { MenuItemService } from '../../menu-item/menu-item.service';
import { Subscription } from 'rxjs/Subscription';
import { ToastService } from '../../shared/toast/toast.service';
import { AnimationEventsService } from '../../shared/events/animation-events.service';
import { FadeInEvent } from '../../shared/events/fade-in-event';
import { AdminPipe } from '../../shared/pipes/admin.pipe';


@Component({
  selector: 'app-restaurant-manage',
  templateUrl: './restaurant-manage.component.html',
  styleUrls: ['./restaurant-manage.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RestaurantManageComponent implements OnInit, OnDestroy {

  restaurantActive = false;
  restaurant: RestaurantModel;
  content: PageOverviewRestaurantType;
  showDetail = false;
  @Input() menus: MenuModel[] = [];
  archiveMenus: MenuModel[] = [];
  activeFilterValue = 0;
  currentModal;
  menuTime = false;
  menuWithTimeAvailable = false;
  expandedMenuMap = {};
  expandedMenuGroupMap = {};
  isSubmitting = false;
  addressDisplayString: string;
  toggleMap: Map<string, boolean>;
  itemAnimatedId: string;
  menuCreatedId: string;

  // RxJS Subscription is an excellent API for managing many unsubscribe calls.
  // See note below about unsubscribing.
  subs = new Subscription();

  private errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private restaurantService: RestaurantService,
    private menuService: MenuService,
    private menuItemService: MenuItemService,
    private dragulaService: DragulaService,
    private contentfulService: ContentfulService,
    private modalService: NgbModal,
    private menuGroupService: MenuGroupService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private toastService: ToastService,
    private animationEventsService: AnimationEventsService,
    private adminPipe: AdminPipe
  ) {
  }

  ngOnInit() {
    this.toggleMap = new Map<string, boolean>();

    this.expandedMenuMap = this.restaurantService.expandedMenuMap;
    this.expandedMenuGroupMap = this.restaurantService.expandedMenuGroupMap;

    this.subs.add(this.router.events.subscribe((event) => {
      // Load restaurant new info when the user is redirected back to restaurant manage page.
      if (event instanceof NavigationEnd) {
        this.loadRestaurantInfo();
      }
    }));
    this.loadRestaurantInfo();

    this.contentfulService.getAdminEntry(EntryDictionary.PageOverviewRestaurant.pageRestaurantSummary).then(
      (entry) => {
        this.content = new PageOverviewRestaurantType(entry);
      }
    );


    this.subs.add(this.animationEventsService.moveEvent.subscribe(
      (event: FadeInEvent) => {
        // Set the Item Moved id.
        this.itemAnimatedId = event.elementId;
        const element = document.getElementById(this.itemAnimatedId);

        if (element) {
          element.classList.add('move-animation');
        }
      }
    ));

    this.subs.add(this.animationEventsService.createOrCopyEvent.subscribe(
      (event: FadeInEvent) => {
        // Set the Item Moved id.
        this.itemAnimatedId = event.elementId;
      }
    ));
    this.subs.add(this.animationEventsService.createMenuEvent.subscribe((event: FadeInEvent) => {
        this.menuCreatedId = event.elementId;
      }
    ));
  }

  loadRestaurantInfo() {

    this.menuWithTimeAvailable = false;

    this.subs.add(this.route.params.subscribe((params) => {
      if (params['restaurantId']) {
        this.subs.add(this.restaurantService.getRestaurant(params['restaurantId']).subscribe((restaurant) => {
          this.restaurant = restaurant;

          this.addressDisplayString = this.setRestaurantDisplayString();

          for (const menu of this.restaurant.menus) {
            if (!this.expandedMenuMap[menu.id]) {
              this.expandedMenuMap[menu.id] = false;
            }
            for (const group of menu.groups) {
              if (!this.expandedMenuGroupMap[group.id]) {
                this.expandedMenuGroupMap[group.id] = false;
              }
            }
          }

          console.log(`Get Restaurant - Parsed`, restaurant);
          // Order the archive to the last.
          this.menus = [];
          this.archiveMenus = [];
          this.restaurant.menus.forEach(menu => {
            if (menu.archive) {
              this.archiveMenus.push(menu);
            } else {
              this.menus.push(menu);
            }
            if (menu.timeUnavailable === 0) {
              this.menuWithTimeAvailable = true;
            }


          });
          this.menus = this.menus.concat(this.archiveMenus);

          // Only if the reload restaurant info it's caused because one item moved subscribe to html dom events.
          if (this.itemAnimatedId || this.menuCreatedId) {

            const container = document.querySelector('.container');

            // Subscribe to html changes until the new element it's displayed.
            const domObserver = new MutationObserver(() => {
              // Once it's displayed get the element
              const elementId = this.itemAnimatedId ? this.itemAnimatedId : this.menuCreatedId;
              const movedElement = document.getElementById(elementId);
              if (document.contains(movedElement)) {
                // Set the class
                const movedElementId = this.itemAnimatedId ? 'move-animation' : 'new-animation';
                movedElement.classList.add(movedElementId);
                this.itemAnimatedId = null;
                this.menuCreatedId = null;
                // disconnect to the observer.
                domObserver.disconnect();
              }
            });


            domObserver.observe(container, {
              childList: true,
              subtree: true,
            });
          }
          this.initDragula();
        }));
      }
    }));
  }

  openToggle(id: string, modal) {
    this.toggleMap.set(id, true);
    this.currentModal = this.modalService.open(modal, { backdrop: 'static' });
  }

  closeToggle(modal, entity, toggleID: string) {
    // Tweak to change the switch to the correct value.
    entity.active = entity.active ? 0 : 1;
    this.currentModal.close();
    entity.active = entity.active ? 1 : 0;

    this.toggleMap.set(toggleID, false);
  }

  close() {
    this.currentModal.close();
  }

  open(content) {
    this.modalService.open(content, { backdrop: 'static' });
  }

  openModal(modalId: string) {
    this.currentModal = this.modalService.open(modalId, { backdrop: 'static' });
  }

  openRestaurantClose() {
    this.router.navigate(['restaurant', this.restaurant.id, 'close']);
  }

  openAddMenu(type: string) {
    this.router.navigate(['restaurant', this.restaurant.id, 'menu', 'create'], { queryParams: { type: type } });
  }

  openAddMenuGroup(menuId: number) {
    this.router.navigate(['restaurant', this.restaurant.id, 'menu', menuId, 'menu-group', 'create']);
  }

  openAddMenuItem(menuId: number, menuGroupId) {
    this.router.navigate([
      'restaurant', this.restaurant.id, 'menu', menuId, 'menu-group',
      menuGroupId, 'menu-item', 'create'
    ]);
  }

  openRestaurantEdit() {
    this.router.navigate(['restaurant', this.restaurant.id, 'edit']);
  }

  openMenuEdit(menuId) {
    this.router.navigate(['restaurant', this.restaurant.id, 'menu', menuId, 'edit']);
  }

  openExportRestaurant() {
    this.router.navigate(['restaurant', this.restaurant.id, 'export']);
  }

  openRestaurantEmail() {
    this.router.navigate(['restaurant', this.restaurant.id, 'send-email']);
  }

  openReorderMenus() {
    this.router.navigate(['restaurant', this.restaurant.id, 'menu', 'reorder']);
  }

  openReorderGroups(menuId: number) {
    this.router.navigate(['restaurant', this.restaurant.id, 'menu', menuId, 'menu-group', 'reorder']);
  }

  openMoveMenuGroup(menuId, menuGroupId) {
    this.router.navigate(['restaurant', this.restaurant.id, 'menu', menuId, 'menu-group', menuGroupId, 'move']);
  }

  openMoveMenuItem(menuId, menuGroupId, menuItemId) {
    this.router.navigate([
      'restaurant', this.restaurant.id, 'menu', menuId, 'menu-group', menuGroupId,
      'menu-item', menuItemId, 'move'
    ]);
  }

  openCopyMenuItem(menuId, menuGroupId, menuItemId) {
    this.router.navigate([
      'restaurant', this.restaurant.id, 'menu', menuId, 'menu-group', menuGroupId,
      'menu-item', menuItemId, 'copy'
    ]);
  }

  openCopyMenuGroup(menuId, menuGroupId) {
    this.router.navigate(['restaurant', this.restaurant.id, 'menu', menuId, 'menu-group', menuGroupId, 'copy']);
  }

  // Expand or collapse all menus and groups
  expandOrCollapseAll(shouldExpand = false) {
    // Expand or collapse all menus
    Object.keys(this.expandedMenuMap).forEach((id, index) => {
      this.expandedMenuMap[id] = shouldExpand;
    });

    // Expand or collapse all menu groups
    Object.keys(this.expandedMenuGroupMap).forEach((id, index) => {
      this.expandedMenuGroupMap[id] = shouldExpand;
    });

    // When expand-all or collapse-all buttons are clicked, they remain active and focused.
    // This is standard so that you can quickly tab through forms, etc.
    // However, in this case we want to manually remove the focus of these specific buttons.
    // If a modal is opened while these buttons are still active and have focus, when returning,
    // the view will automatically scroll to that element, rather than staying where is was scrolled to.
    (document.activeElement as HTMLElement).blur();
  }

  openGroupEdit(menuId, menuGroupId) {
    this.router.navigate([
      'restaurant', this.restaurant.id, 'menu', menuId, 'menu-group', menuGroupId,
      'edit'
    ]);
  }

  openItemEdit(menuId, menuGroupId, menuItemId) {
    this.router.navigate([
      'restaurant', this.restaurant.id, 'menu', menuId, 'menu-group', menuGroupId,
      'menu-item', menuItemId, 'edit'
    ]);
  }

  copyMenu(menu) {
    this.isSubmitting = true;
    this.subs.add(this.menuService.copyMenu(menu).subscribe(
      copyResponse => {
        console.log(`Menu copy response: `, copyResponse);
        this.isSubmitting = false;
        this.toastService.showSuccess(`${copyResponse.menu.name} copied.`);
        this.loadRestaurantInfo();
        this.currentModal.close();
      },
      error => {
        this.errorMessage = <any>error;
        this.isSubmitting = false;
      }
    ));
  }


  toggleUnpublishedFilter() {
    this.activeFilterValue = this.activeFilterValue ? 0 : 1;
  }

  onDrop(el: Element, source: Element) {

    el.classList.add('move-animation');

    // This is the only way to get the dragged item. https://github.com/valor-software/ng2-dragula/pull/704
    // Will be included in version 2.
    const menuId = +source.getAttribute('menuId');
    const menuGroupId = +source.getElementsByClassName('menu-item col-12')[0].getAttribute('menugroupid');
    const menu = this.restaurant.menus.find((m) => {
      return m.id === menuId;
    });
    const menuGroup = menu.groups.find((mg) => {
      return mg.id === menuGroupId;
    });

    const orderArray = menuGroup.items.map(function (elem) {
      return elem.id;
    });
    const order = orderArray.join();

    const updateRequest = new MenuGroupRequest.OrderUpdate();
    updateRequest.menuItemOrder = order;
    updateRequest.id = menuGroup.id;

    console.log('Update MenuGroup Order Request:', updateRequest);

    this.subs.add(this.menuGroupService.editMenuGroupOrder(updateRequest)
      .subscribe(
        updateResponse => {
          console.log(`Update MenuGroup - Parsed:`, updateResponse);
        },
        error => this.errorMessage = <any>error,
      ));
  }

  hasMenuWithTimeAvailable() {
    for (const menu of this.restaurant.menus) {
      if (menu.timeUnavailable === 0) {
        return true;
      }
    }
    return false;
  }

  changeRestauarantStatus() {
    // Restaurant un-publish should be disabled if the user is a restaurant owner and the restaurant is
    // active, but just in case, this will catch any unpublish attempts.
    if (!this.adminPipe.transform() && !this.restaurant.active) {
      this.restaurant.active = 1;
      this.isSubmitting = false;
      this.currentModal.close();
      alert('Unauthorized action. Please contact WhatToOrder support to un-publish this location');
      return;
    }

    const updateRequest = new RestaurantRequest.Update();
    updateRequest.restaurant = this.restaurant;
    // We are changing the value in the switch, so the value of this.rest.active is desired one.
    updateRequest.restaurant.active = this.restaurant.active ? 1 : 0;
    this.isSubmitting = true;
    this.subs.add(this.restaurantService.editRestaurant(updateRequest)
      .subscribe(
        updateResponse => {
          console.log(`Update Restaurant - Parsed:`, updateResponse);
          this.loadRestaurantInfo();
          this.isSubmitting = false;
          this.currentModal.close();
          this.toggleMap.set(`restaurant-${this.restaurant.id}`, false);
        },
        error => {
          this.errorMessage = <any>error;
          this.isSubmitting = false;
          this.toggleMap.set(`restaurant-${this.restaurant.id}`, false);
        }
      ));
  }

  changeMenuStatus(menu: MenuModel) {
    const updateRequest = new MenuRequest.Update();
    // We are changing the value in the switch, so the value of this.rest.active is desired one.
    menu.active = menu.active ? 1 : 0;
    updateRequest.menu = menu;
    this.isSubmitting = true;
    this.subs.add(this.menuService.editMenu(updateRequest)
      .subscribe(
        updateResponse => {
          this.isSubmitting = false;
          this.loadRestaurantInfo();
          this.currentModal.close();
          this.toggleMap.set(`menu-${menu.id}`, false);
        },
        error => {
          this.errorMessage = <any>error;
          this.isSubmitting = false;
          this.toggleMap.set(`menu-${menu.id}`, false);
        }
      ));
  }

  changeMenuGroupStatus(menuGroup: MenuGroupModel) {
    const updateRequest = new MenuGroupRequest.Update();
    // We are changing the value in the switch, so the value of this.rest.active is desired one.
    menuGroup.active = menuGroup.active ? 1 : 0;
    updateRequest.menuGroup = menuGroup;
    this.isSubmitting = true;

    this.subs.add(this.menuGroupService.editMenuGroup(updateRequest)
      .subscribe(
        updateResponse => {
          this.isSubmitting = false;
          this.loadRestaurantInfo();
          this.currentModal.close();
          this.toggleMap.set(`menuGroup-${menuGroup.id}`, false);
        },
        error => {
          this.errorMessage = <any>error;
          this.isSubmitting = false;
          this.toggleMap.set(`menuGroup-${menuGroup.id}`, false);
        }
      ));
  }

  changeMenuItemStatus(menuItem: MenuItemModel) {
    const updateRequest = new MenuItemRequest.Update();
    // We are changing the value in the switch, so the value of this.rest.active is desired one.
    menuItem.active = menuItem.active ? 0 : 1;
    updateRequest.menuItem = menuItem;
    this.isSubmitting = true;
    /*Removed this toggle from menu items as there is no confirmation screen, keeping them
    commented out here in case we decide to put the toggle in place again. */

    // this.toggleMap.set(`menuItem-${menuItem.id}`, true);

    this.subs.add(this.menuItemService.editMenuItem(updateRequest)
      .subscribe(
        updateResponse => {
          this.isSubmitting = false;
          this.loadRestaurantInfo();
          // this.toggleMap.set(`menuItem-${menuItem.id}`, false);
        },
        error => {
          this.errorMessage = <any>error;
          this.isSubmitting = false;
          // this.toggleMap.set(`menuItem-${menuItem.id}`, false);
        }
      ));
  }

  deleteMenuItem(menuItem: MenuItemModel) {
    this.isSubmitting = true;
    this.menuItemService.deleteMenuItem(menuItem.id).subscribe(
      deleteResponse => {
        this.toastService.showSuccess(`${menuItem.name}  permanently deleted.`);
        this.loadRestaurantInfo();
        this.currentModal.close();
        this.isSubmitting = false;
      },
      error => {
        // Todo: Improve error handling
        this.errorMessage = <any>error;
        this.isSubmitting = false;
      }
    );
  }

  deleteMenuGroup(menuGroup: MenuGroupModel) {
    this.isSubmitting = true;
    this.menuGroupService.deleteMenuGroup(menuGroup.id).subscribe(
      deleteResponse => {
        this.toastService.showSuccess(`${menuGroup.name}  permanently deleted.`);
        this.loadRestaurantInfo();
        this.isSubmitting = false;
        this.currentModal.close();
      },
      error => {
        this.isSubmitting = false;
        // Todo: Improve error handling
        this.errorMessage = <any>error;
      }
    );
  }

  deleteMenu(menu: MenuModel) {
    this.isSubmitting = true;
    this.menuService.deleteMenu(menu.id).subscribe(
      deleteResponse => {
        this.isSubmitting = false;
        this.toastService.showSuccess(`${menu.name}  permanently deleted.`);
        this.loadRestaurantInfo();
        this.currentModal.close();
      },
      error => {
        this.isSubmitting = false;
        // Todo: Improve error handling
        this.errorMessage = <any>error;
      }
    );
  }

  ngOnDestroy() {
    this.dragulaService.destroy('items-bag');

    this.restaurantService.expandedMenuMap = this.expandedMenuMap;
    this.restaurantService.expandedMenuGroupMap = this.expandedMenuGroupMap;

    this.subs.unsubscribe();
  }

  // Combine city, state, and zip into a single string
  // Covers cases where an address might not have one of those elements
  setRestaurantDisplayString(): string {
    let address2String: string;

    if (this.restaurant.city && this.restaurant.city.length > 0) {
      address2String = this.restaurant.city;
    }

    if (this.restaurant.state && this.restaurant.state.length > 0) {
      if (address2String && address2String.length > 0) {
        address2String += ', ' + this.restaurant.state;
      } else {
        address2String = this.restaurant.state;
      }
    }

    if (this.restaurant.zipCode && this.restaurant.zipCode.length > 0) {
      if (address2String && address2String.length > 0) {
        address2String += ', ' + this.restaurant.zipCode;
      } else {
        address2String = this.restaurant.zipCode;
      }
    }

    return address2String;
  }

  // Give all bags a unique id so that they can't be dragged between bags
  initDragula() {
    for (const menu of this.restaurant.menus) {
      for (const group of menu.groups) {
        const bag = this.dragulaService.find(`items-bag-${group.id}`);

        // If the bag already exists, don't create a new one.
        if (!bag) {
          this.dragulaService.createGroup(`items-bag-${group.id}`, {
            removeOnSpill: false
          });

          this.dragulaService.drop(`items-bag-${group.id}`).subscribe(({ el, source }) => {
            this.onDrop(el, source);
          });
        }
      }
    }
  }
}
