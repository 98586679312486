import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericErrorsType } from '@generativ/wto-admin-types';
import { GenericErrorService } from '../../shared/generic-error-service';
import { TagModel, TagRequest } from '@generativ/wto-api-client';
import { TagService } from '../../shared/tag.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  tagForm: FormGroup = this.formBuilder.group({
    name: [
      null,
      Validators.required
    ],
    type: [
      null,
      Validators.required
    ],
    display: [
      null,
      Validators.required
    ]
  });
  editTagForm: FormGroup = this.formBuilder.group({
    tag: [
      null,
      Validators.required
    ],
    display: [
      null,
      Validators.required
    ]
  });
  errorContent: GenericErrorsType;
  allTags: TagModel[] = [];
  restaurantCuisineTags: TagModel[] = [];
  restaurantDeliveryTags: TagModel[] = [];
  restaurantReservationTags: TagModel[] = [];
  restaurantReviewTags: TagModel[] = [];
  itemDietaryTags: TagModel[] = [];
  itemEnvironmentalTags: TagModel[] = [];
  itemOtherTags: TagModel[] = [];
  isSubmitting: boolean;
  isEditing: boolean;

  constructor(
    private tagService: TagService,
    private formBuilder: FormBuilder,
    private genericErrorService: GenericErrorService
  ) {}

  ngOnInit(): void {
    this.genericErrorService.getErrorContent().then(
      (errorContent) => {
        this.errorContent = errorContent;
      }
    );

    this.subscription.add(this.tagService.get()
      .subscribe(tags => {
        this.allTags = tags;
        this.sortTagsByType();
        },
        err => console.log(`error getting tags: `, err)
      ));

    this.subscription.add(this.editTagForm.controls.tag.valueChanges
      .subscribe(val => this.tagChanged(val)));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addTag(): void {
    this.isSubmitting = true;

    const tag = new TagRequest.Create();
    tag.name = this.tagForm.controls.name.value;
    tag.description = this.tagForm.controls.name.value;
    tag.value = this.tagForm.controls.name.value;
    tag.display = this.tagForm.controls.display.value === '1';
    tag.type = this.tagForm.controls.type.value;
    const tagRequest = new TagRequest.CreateBulk();
    tagRequest.tags = [tag];

    this.subscription.add(this.tagService.addTags(tagRequest)
      .subscribe(() => {
        alert(`${tag.name} successfully created`);
        this.isSubmitting = false;
        this.tagForm.reset();
        this.tagForm.updateValueAndValidity();
        }, (err) => {
        alert(`Problem creating tag: ${err}`);
        this.isSubmitting = false;
      }));
  }

  updateTag(): void {
    this.isEditing = true;
    this.subscription.add(this.tagService.updateTagDisplay(+this.editTagForm.controls.tag.value,
      this.editTagForm.controls.display.value)
      .subscribe((tag) => {
        alert(`${tag.name} successfully updated`);
        this.isEditing = false;
        this.tagForm.reset();
        this.tagForm.updateValueAndValidity();
      }, (err) => {
        alert(`Problem updating tag: ${err}`);
        this.isEditing = false;
      }));
  }

  tagChanged(tagId: number): void {
    const tag = this.allTags.find(t => t.id === +tagId);

    if (tag) {
      this.editTagForm.controls.display.setValue(tag.display ? '1' : '0');
      this.editTagForm.controls.display.updateValueAndValidity();
    }
  }

  hasError(field: string, validator: string) {
    const control = this.tagForm.controls[field];
    return control.hasError(validator)
      && control.touched;
  }

  hasDanger(field: string) {
    const control = this.tagForm.controls[field];
    return !control.disabled && !control.valid && control.touched;
  }

  hasErrorEdit(field: string, validator: string) {
    const control = this.editTagForm.controls[field];
    return control.hasError(validator)
      && control.touched;
  }

  hasDangerEdit(field: string) {
    const control = this.editTagForm.controls[field];
    return !control.disabled && !control.valid && control.touched;
  }

  private sortTagsByType(): void {
    this.allTags.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    for (const tag of this.allTags) {
      switch (tag.type) {
        case 'menuItem:dietary': {
          this.itemDietaryTags.push(tag);
          break;
        }
        case 'menuItem:environmental': {
          this.itemEnvironmentalTags.push(tag);
          break;
        }
        case 'menuItem:other': {
          this.itemOtherTags.push(tag);
          break;
        }
        case 'restaurant:cuisine': {
          this.restaurantCuisineTags.push(tag);
          break;
        }
        case 'restaurant:delivery': {
          this.restaurantDeliveryTags.push(tag);
          break;
        }
        case 'restaurant:reservation': {
          this.restaurantReservationTags.push(tag);
          break;
        }
        case 'restaurant:review': {
          this.restaurantReviewTags.push(tag);
          break;
        }
      }
    }
  }
}
