<form [formGroup]="menuGroupCopyForm" autocomplete="off" (ngSubmit)="submitForm()" >
  <div class="form-group relocate" *ngIf="menuGroup">
    <div class="row">
      <div class="col-12">
        <select class="form-control form-control-lg" id="rest-cuisine"
                formControlName="menuId">
          <option [ngValue]="null" disabled selected> Select Menu</option>
          <option *ngFor="let menu of menus | orderby:'name'" [ngValue]="menu.id" label="{{ menu.name }}">{{ menu.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="mb-5 text-center">
    <button type="submit" class="btn btn-outline-primary btn-sm" [disabled]="!menuGroupCopyForm.valid || waiting">Copy Group</button>
  </div>
</form>
