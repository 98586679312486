import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ContentfulService } from '../../shared/contentful.service';
import { EntryDictionary, PageCreateType } from '@generativ/wto-admin-types';
import { BreadcrumbService } from '../../shared/breadcrumb.service';
import { MenuModel } from '@generativ/wto-api-client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalService } from '../../shared/modal/modal.service';
import { Subscription } from 'rxjs/Subscription';
import { ParamsService } from '../../shared/params.service';
import { MenuService } from '../../menu/shared/menu.service';

@Component({
  selector: 'app-menu-group-create',
  templateUrl: './menu-group-create.component.html',
  styleUrls: ['./menu-group-create.component.scss']
})
export class MenuGroupCreateComponent implements OnInit, OnDestroy {
  content: PageCreateType;
  menu: MenuModel;
  route: ActivatedRoute;
  subs = new Subscription();
  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();

  constructor(
    private contentfulService: ContentfulService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private modalService: ModalService,
    private menuService: MenuService,
    private paramsService: ParamsService,
  ) {
    this.route = this.modalService.getActivatedRoute();
  }

  ngOnInit() {
    this.contentfulService.getAdminEntry(EntryDictionary.PageCreate.pageAddMenuGroup).then(
      (entry) => {
        this.content = new PageCreateType(entry);
      }
    );

    this.subs.add(this.route.params.subscribe((params: Params) => {
      const menuId = +this.paramsService.getParamById('menuId', this.route.snapshot);
      this.subs.add(
        // Todo: we should use one cache. Locally.
        this.menuService.getMenu(menuId).subscribe(menuModel => {
          this.setSubHeader.emit(menuModel.name);
        }));
    }));
  }


  /**
   * Calling closeModal from the component allow us to redirect the user to one page depending on
   * the component that we are.
   */
  onCloseModal() {
    this.router.navigate(['../../../../'], { relativeTo: this.modalService.getActivatedRoute() });
  }

  /**
   *Just in case we need to add something more than closing the modal, or we want to something different.
   * If not, we can call the onCloseModal directly.
   */
  onCancel() {
    this.onCloseModal();
  }


  /**
   * Getting the title from the component, let us getting it from Contentful.
   * @returns {string}
   */
  get title() {
    return 'Add Group to';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
