import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { MenuModel, MenuRequest, MenuResponse, RestaurantModel } from '@generativ/wto-api-client';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MenuService {

  private menuUrl = `${environment.wtoApiUrl}/menus/`;
  private restaurantUrl = environment.wtoApiUrl + '/restaurants/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  createMenu(menu: MenuRequest.Create): Observable<MenuResponse.Create> {
    return this.http.post<MenuResponse.Create>(this.menuUrl, menu).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  editMenu(request: MenuRequest.Update): Observable<MenuResponse.Update> {

    return this.http.put<MenuResponse.Update>(this.menuUrl, request).pipe(
      catchError(this.errorHandlerService.handleError)
    );

  }

  getMenu(id: number): Observable<MenuModel> {

    return this.http
      .get<MenuModel>(this.menuUrl + id + '/force').pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  editMenuOrder(menuOrder: MenuRequest.OrderUpdate): Observable<MenuResponse.OrderUpdate> {
    return this.http.put<MenuResponse.OrderUpdate>(this.menuUrl + 'order', menuOrder).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  copyMenu(menu: MenuModel): Observable <MenuResponse.Create> {
    return this.http.post<MenuResponse.Create>(`${this.menuUrl}${menu.id}/copy`, null)
      .pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  deleteMenu(id: number): Observable<MenuModel> {
    return this.http
      .delete<MenuModel>(this.menuUrl + id).pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

  getRestaurant(id: number): Observable<RestaurantModel> {

    return this.http
      .get<RestaurantModel>(this.restaurantUrl + id + '/force').pipe(
        catchError(this.errorHandlerService.handleError)
      );
  }

}
