<main class="container" *ngIf="errorContent">
  <div class="headline-container">
    <h2>Add/Edit Tags</h2>
  </div>

  <form [formGroup]="tagForm" (ngSubmit)="addTag()">
    <div class="row">
      <div class="col-6">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('name')}">
          <label class="form-control-label">Name</label>
          <input class="form-control form-control-lg"
                 placeholder="Tag Name"
                 formControlName="name">
          <div class="form-control-feedback">
            <span *ngIf="hasError('name', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('type')}">
          <label class="form-control-label">Type</label>
          <select class="form-control form-control-lg"
                  formControlName="type">
            <option value="restaurant:cuisine">Cuisine</option>
            <option value="restaurant:delivery">Delivery</option>
            <option value="menuItem:dietary">Dietary</option>
            <option value="menuItem:environmental">Environmental</option>
            <option value="menuItem:other">Other</option>
            <option value="restaurant:reservation">Reservation</option>
            <option value="restaurant:review">Review</option>
          </select>
          <div class="form-control-feedback">
            <span *ngIf="hasError('type', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('display')}">
          <label class="form-control-label">Display in App</label>
          <select class="form-control form-control-lg"
                  formControlName="display">
            <option [value]="0">No</option>
            <option [value]="1">Yes</option>
          </select>
          <div class="form-control-feedback">
            <span *ngIf="hasError('display', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2 offset-5">
        <button class="btn btn-primary btn-lg"
                type="submit" [disabled]="tagForm.invalid || isSubmitting">
          <!-- The <span> below will change the button text to a
          3-dot loading animation while the form submits -->
          <span [ngClass]="isSubmitting ? 'loader-small span-loader' : ''">
              Done
            </span>
        </button>
      </div>
    </div>
  </form>

  <br><hr><br>

  <form [formGroup]="editTagForm" (ngSubmit)="updateTag()">
    <div class="row">
      <div class="col-4 offset-3">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDangerEdit('tag')}">
          <label class="form-control-label">Tag</label>
          <select class="form-control form-control-lg"
                  formControlName="tag">
            <optgroup label="--Cuisine--"></optgroup>
            <option *ngFor="let tag of restaurantCuisineTags" [value]="tag.id">{{ tag.name }}</option>
            <optgroup label="--Delivery--"></optgroup>
            <option *ngFor="let tag of restaurantDeliveryTags" [value]="tag.id">{{ tag.name }}</option>
            <optgroup label="--Dietary--"></optgroup>
            <option *ngFor="let tag of itemDietaryTags" [value]="tag.id">{{ tag.name }}</option>
            <optgroup label="--Environmental--"></optgroup>
            <option *ngFor="let tag of itemEnvironmentalTags" [value]="tag.id">{{ tag.name }}</option>
            <optgroup label="--Other--"></optgroup>
            <option *ngFor="let tag of itemOtherTags" [value]="tag.id">{{ tag.name }}</option>
            <optgroup label="--Reservation--"></optgroup>
            <option *ngFor="let tag of restaurantReservationTags" [value]="tag.id">{{ tag.name }}</option>
            <optgroup label="--Review--"></optgroup>
            <option *ngFor="let tag of restaurantReviewTags" [value]="tag.id">{{ tag.name }}</option>
          </select>
          <div class="form-control-feedback">
            <span *ngIf="hasErrorEdit('tag', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDangerEdit('display')}">
          <label class="form-control-label">Display in App</label>
          <select class="form-control form-control-lg"
                  formControlName="display">
            <option [value]="0">No</option>
            <option [value]="1">Yes</option>
          </select>
          <div class="form-control-feedback">
            <span *ngIf="hasErrorEdit('display', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2 offset-5">
        <button class="btn btn-primary btn-lg"
                type="submit" [disabled]="editTagForm.invalid || isEditing">
          <!-- The <span> below will change the button text to a
          3-dot loading animation while the form submits -->
          <span [ngClass]="isEditing ? 'loader-small span-loader' : ''">
              Update
            </span>
        </button>
      </div>
    </div>
  </form>
</main>
