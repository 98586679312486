<ng-container *ngIf="ready && content && errorContent; else loader">
  <form #idForm [formGroup]="restaurantForm" (ngSubmit)="submitForm()" autocomplete="disabled"
        (keydown.enter)="$event.preventDefault()">
    <div *ngIf="errorMessage" class="text-danger">
      <i class="fa fa-warning"></i> {{errorMessage}}
    </div>

    <ng-container *ngIf="'' | admin">
      <div class="form-group row">
        <div class="col-6">
          <label for="rest-review-code" class="form-control-label">
            <app-tooltip *ngIf="content.inputReviewCode && content.inputReviewCode.tooltip" [tooltipText]="content.inputReviewCode.tooltip"></app-tooltip>
            {{ content.inputReviewCode?.label }}
          </label>
          <input type="text" class="form-control form-control-lg" id="rest-review-code"
                 autocomplete="off"
                 [placeholder]="content.inputReviewCode.placeholder || ''"
                 formControlName="reviewCode">
          <div class="form-control-feedback">
            <span *ngIf="hasError('reviewCode', 'maxlength')">{{ errorContent.maxlength }}</span>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>

    <div class="form-group row image-row"
         [ngClass]="{'has-danger':hasDanger('name')}">
      <!-- Custom Padding on the <div> below to keep the input vertically aligned with the image on the right -->
      <div class="col-9 input-spacing">
        <label for="rest-name" class="form-control-label">
          <app-tooltip *ngIf="content.inputName && content.inputName.tooltip" [tooltipText]="content.inputName.tooltip"></app-tooltip>
          {{ content.inputName?.label }}
        </label>
        <input type="text" class="form-control form-control-lg" id="rest-name"
               autocomplete="off"
               [placeholder]="content.inputName.placeholder || ''"
               formControlName="name">
        <div class="form-control-feedback">
          <span *ngIf="hasError('name', 'required')">{{ errorContent.required }}</span>
          <span *ngIf="hasError('name', 'maxlength')">{{ errorContent.maxlength }}</span>
        </div>
      </div>
      <div class="col-3 delete-container">
        <!--Todo Style Image-->
        <a (click)="selectPhoto()">
          <img *ngIf="image; else addPhoto" src="{{ image | filestackThumbnail }}" class="camera-icon">
          <ng-template #addPhoto>
            <div class="add-image">
              <!-- Custom padding in the <div> below to make the image larger and keep it square -->
              <div class="text-center image-spacing pb-3">
                <img src="../../../../assets/images/icon-camera-active.svg" class="camera-icon">
                <br>
                <p>Add Photo</p>
              </div>
            </div>
          </ng-template>
        </a>
        <div *ngIf="image" class="overlay-image">
          <span class="remove" (click)="openModal(confirmDeletePhoto)">X</span>
        </div>
        <ng-template  #confirmDeletePhoto  let-c="close">
          <div class="modal-header">
            <strong class="modal-title text-uppercase">Warning</strong>
            <button type="button" class="close" aria-label="Close" (click)="c(confirmDeletePhoto)">
              <span aria-hidden="true">X</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-center">This photo is not saved in the Menu Manager and this action cannot be undone.</p>
            <div class="text-center">
              <button class="btn btn-primary btn-sm" (click)="removePhoto()">Delete Photo</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="form-group hidden-xl-down"
         [ngClass]="{'has-danger':hasDanger('briefDescription')}">

      <label for="rest-description" class="form-control-label">
        <app-tooltip *ngIf="content.inputDescription && content.inputDescription.tooltip" [tooltipText]="content.inputDescription.tooltip"></app-tooltip>
        {{ content.inputDescription?.label }}
      </label>

      <textarea class="form-control form-control-lg" id="rest-description" placeholder="{{ content.inputDescription?.placeholder }}" rows="4"
                formControlName="briefDescription"></textarea>
      <div class="form-control-feedback">
        <span *ngIf="hasError('briefDescription', 'maxlength')">{{ errorContent.maxlength }}</span>
      </div>
    </div>

    <div class="form-group" [ngClass]="{'has-danger':hasDanger('primaryCuisine')}">
      <div class="row">
        <div class="col-6">
          <label for="rest-cuisine" class="form-control-label">
            <app-tooltip *ngIf="content.inputCuisine && content.inputCuisine.tooltip" [tooltipText]="content.inputCuisine.tooltip"></app-tooltip>
            {{ content.inputCuisine?.label }}
          </label>
          <select class="form-control form-control-lg" id="rest-cuisine"
                  formControlName="primaryCuisine">
            <option disabled value=""> -- Select an Option --</option>
            <option *ngFor="let tag of cuisineTags | orderby:'name'" [ngValue]="tag" label="{{ tag.name }}">{{ tag.name
              }}
            </option>
          </select>
          <div class="form-control-feedback">
            <span *ngIf="hasError('primaryCuisine', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
        <div class="col-6" [ngClass]="{'has-danger':hasDanger('neighborhood')}">
          <label class="form-control-label">
            <app-tooltip *ngIf="content.inputNeighborhood && content.inputNeighborhood.tooltip" [tooltipText]="content.inputNeighborhood.tooltip"></app-tooltip>
            {{ content.inputNeighborhood?.label }}
          </label>
          <input type="text" class="form-control form-control-lg"
                 [placeholder]="content.inputNeighborhood.placeholder || ''"
                 autocomplete="off"
                 formControlName="neighborhood">
          <div class="form-control-feedback">
            <span *ngIf="hasError('neighborhood', 'required')">{{ errorContent.required }}</span>
            <span *ngIf="hasError('neighborhood', 'maxlength')">{{ errorContent.maxlength }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group"
         [ngClass]="{'has-danger': hasDanger('websiteUrl') || hasDanger('websiteUrlMonitoringFrequency')}">
      <label for="rest-url" class="form-control-label">
        <app-tooltip *ngIf="content.inputUrl && content.inputUrl.tooltip" [tooltipText]="content.inputUrl.tooltip"></app-tooltip>
        {{ content.inputUrl?.label }}
      </label>
      <div class="form-check d-inline-block">
        <input type="checkbox" class="form-check-input" id="siteUnavailable"
               formControlName="websiteUrlUnavailable"
               (click)="toggleHomePageUrl()">
        <label class="form-check-label"
               for="siteUnavailable"><span class="small">{{ content.inputUrlUnavailable?.placeholder }}</span>
        </label>
      </div>

      <ng-container *ngIf="'' | admin">
        <select class="select" formControlName="websiteUrlMonitoringFrequency"
                (change)="updateListPage()">
          <option value="1 day">1 day</option>
          <option value="3 days">3 days</option>
          <option value="7 days">7 days</option>
          <option value="10 days">10 days</option>
          <option value="30 days">30 days</option>
          <option value="90 days">90 days</option>
          <option value="365 days">365 days</option>
        </select>
      </ng-container>

      <input type="url" class="form-control form-control-lg" id="rest-url" placeholder="{{ content.inputUrl?.placeholder }}"
             formControlName="websiteUrl">
      <div class="form-control-feedback">
        <span *ngIf="hasError('websiteUrl', 'required')">{{ errorContent.required }}</span>
        <span *ngIf="hasError('websiteUrl', 'invalidUrl')">{{ errorContent.isUrl }}</span>
        <span *ngIf="hasError('websiteUrlMonitoringFrequency', 'required')">Must provide monitoring frequency.</span>
      </div>
    </div>

    <div class="form-group"
         [ngClass]="{'has-danger': hasDanger('instagramUrl')}">
      <label for="instagram-url" class="form-control-label">
        <app-tooltip *ngIf="content.inputInstagramUrl && content.inputInstagramUrl.tooltip"
                     [tooltipText]="content.inputInstagramUrl.tooltip"></app-tooltip>
        {{ content.inputInstagramUrl.label }}</label>

      <input type="url" class="form-control form-control-lg" id="instagram-url"
             [placeholder]="content.inputInstagramUrl.placeholder || ''"
             formControlName="instagramUrl">
      <div class="form-control-feedback">
        <span *ngIf="hasError('instagramUrl', 'invalidUrl')">{{ errorContent?.isUrl }}</span>
      </div>
    </div>

    <div class="form-group"
         [ngClass]="{'has-danger': hasDanger('feedbackEmail')}">
      <div class="row">
        <div class="col-6">
          <label for="feedback-email" class="form-control-label">
            <app-tooltip *ngIf="content.inputFeedbackEmail && content.inputFeedbackEmail.tooltip"
                         [tooltipText]="content.inputFeedbackEmail.tooltip"></app-tooltip>
            {{ content.inputFeedbackEmail.label }}</label>

          <input type="url" class="form-control form-control-lg" id="feedback-email"
                 [placeholder]="content.inputFeedbackEmail.placeholder || ''"
                 formControlName="feedbackEmail">
          <div class="form-control-feedback">
            <span *ngIf="hasError('feedbackEmail', 'email')">Email must be valid</span>
          </div>
        </div>
        </div>
      </div>

    <hr>

    <div class="row col-12">
      <label class="form-control-label">
        <app-tooltip [tooltipText]="content?.inputComplexTags.groupTooltip"></app-tooltip>
        {{ content?.inputComplexTags.groupLabel }}
      </label>
    </div>
    <div class="row pt-2">
      <div class="col-5">
        <label class="form-control-label">
          {{ content?.inputComplexTags.labelField1 }}
        </label>
      </div>
      <div class="col-5">
        <label class="form-control-label">
          {{ content?.inputComplexTags.labelField2 }}
        </label>
      </div>
      <div class="col-1 center">
      </div>
    </div>
    <div class="row pt-2 complex-tags" formArrayName="complexTags" *ngFor="let item of restaurantForm.get('complexTags').controls; let i = index;">
      <div class="col-5" [formGroupName]="i">
        <ss-multiselect-dropdown class="form-control form-control-lg"
                                 formControlName="tag"
                                 [settings]="tagSettings"
                                 [options]="complexTags">
        </ss-multiselect-dropdown>
        <div class="form-control-feedback">
          <span *ngIf="hasGroupControlError(item, 'tag', 'required')">{{ errorContent?.required }}</span>
        </div>
      </div>
      <div class="col-5" [formGroupName]="i">
        <input type="email" class="form-control form-control-lg" formControlName="url"
               autocomplete="new-password" [placeholder]="content?.inputComplexTags.placeholderField2">
        <div class="form-control-feedback">
          <span *ngIf="hasGroupControlError(item, 'url', 'maxlength')">{{ errorContent?.maxlength }}</span>
          <span *ngIf="hasGroupControlError(item, 'url', 'invalidUrl')">{{ errorContent?.isUrl }}</span>
        </div>
      </div>
      <div class="col-1 center">
        <img class="icon" src="../../../../assets/images/icon-add.svg"
             (click)="addComplex(null, '')" *ngIf="i === restaurantForm.get('complexTags').controls.length - 1">
        <strong (click)="removeComplex(i)"
                class="icon" [ngClass]="i === restaurantForm.get('complexTags').controls.length - 1 ? 'x-with-add-icon'
                 : ' ml-5'">x</strong>
      </div>
    </div>
    <hr>
    <div class="row col-12">
      <label class="form-control-label">
        <app-tooltip *ngIf="content.inputFileRecipients && content.inputFileRecipients.tooltip" [tooltipText]="content.inputFileRecipients.tooltip"></app-tooltip>
        {{ content.inputFileRecipients?.label }}
      </label>
    </div>
    <div class="row pt-2">
      <div class="col-5">
        <label class="form-control-label">
          Company
        </label>
      </div>
      <div class="col-5">
        <label class="form-control-label">
          Contact Email
        </label>
      </div>
      <div class="col-1 center">
      </div>
    </div>
    <div class="row pt-2" formArrayName="menuFileRecipient" *ngFor="let item of restaurantForm.get('menuFileRecipient').controls; let i = index;">
      <div class="col-5" [formGroupName]="i">
        <!-- TODO: Need to add Company to Contenful -->
        <input type="text" class="form-control form-control-lg" formControlName="company"
               [placeholder]="content.inputFileRecipients.placeholder || ''"
               autocomplete="off">
        <div class="form-control-feedback">
          <!-- TODO: Add contentful error field -->
          <span *ngIf="hasGroupControlError(item, 'company', 'minlength')">Name needs at least 2 characters</span>
        </div>
      </div>
      <div class="col-5" [formGroupName]="i">
        <!-- TODO: Need to add contact email placeholder to Contenful, Input - Email File To not working -->
        <input type="email" class="form-control form-control-lg" formControlName="contactEmail"
               autocomplete="new-password">
        <div class="form-control-feedback">
          <!-- TODO: Add contentful error field -->
          <span *ngIf="hasGroupControlError(item, 'contactEmail', 'email')">A valid email is required</span>
        </div>
      </div>
      <div class="col-1 center">
        <img class="icon" src="../../../../assets/images/icon-add.svg"
             (click)="addRecipient('', '')" *ngIf="i === restaurantForm.get('menuFileRecipient').controls.length - 1; else removeRecipientMinus">
        <ng-template #removeRecipientMinus>
          <!--<img class="icon" src="../../../../assets/images/icon-collapse.svg">-->
          <strong (click)="removeRecipient(i)" class="icon ml-5">x</strong>
        </ng-template>
      </div>
    </div>
    <hr>

    <ng-container *ngIf="'' | admin">
      <div class="form-group"
           [ngClass]="{'has-danger':hasDanger('listPageUrl')}">
        <label for="list-url" class="form-control-label">
          <app-tooltip *ngIf="content.listPageUrl && content.listPageUrl.tooltip" [tooltipText]="content.listPageUrl.tooltip"></app-tooltip>
          {{ content.listPageUrl?.label }}
        </label>
        <select formControlName="listPageUrlMonitoringFrequency">
          <option value="1 day">1 day</option>
          <option value="3 days">3 days</option>
          <option value="7 days">7 days</option>
          <option value="10 days">10 days</option>
          <option value="30 days">30 days</option>
          <option value="90 days">90 days</option>
          <option value="365 days">365 days</option>
        </select>
        <input type="url" class="form-control form-control-lg" id="list-url"
               formControlName="listPageUrl"
               [placeholder]="content.listPageUrl.placeholder || ''"
               (keyup)="toggleListPageUrl($event)">
        <div class="form-control-feedback">
          <span *ngIf="hasError('listPageUrl', 'required')">{{ errorContent.required }}</span>
          <span *ngIf="hasError('listPageUrl', 'invalidUrl')">{{ errorContent.isUrl }}</span>
          <span *ngIf="hasError('listPageUrlMonitoringFrequency', 'required')">Must provide monitoring frequency.</span>
        </div>
      </div>

      <div hidden class="form-group"
           [ngClass]="{'has-danger':hasDanger('businessType')}">
        <label for="rest-type" class="form-control-label">
          <app-tooltip *ngIf="content.inputBusinessType && content.inputBusinessType.tooltip" [tooltipText]="content.inputBusinessType.tooltip"></app-tooltip>
          {{ content.inputBusinessType?.label }}
        </label>
        <select class="form-control form-control-lg" id="rest-type"
                formControlName="businessType">
          <option value="independent" label="Independent" selected>Independent</option>
          <option value="franchise" label="Franchise">Franchise</option>
          <option value="corporate" label="Corporate">Corporate</option>
        </select>
        <div class="form-control-feedback">
          <span *ngIf="hasError('businessType', 'required')">{{ errorContent.required }}</span>
        </div>
      </div>
      <hr>
    </ng-container>

    <div class="form-group" [ngClass]="{'has-danger':hasDanger('locatedIn')}">
      <label for="rest-located-in" class="form-control-label">
        <app-tooltip *ngIf="content.inputLocatedIn && content.inputLocatedIn.tooltip" [tooltipText]="content.inputLocatedIn.tooltip"></app-tooltip>
        {{ content.inputLocatedIn?.label }}
      </label>
      <input type="text" class="form-control form-control-lg" id="rest-located-in"
             autocomplete="off"
             [placeholder]="content.inputLocatedIn.placeholder || ''"
             formControlName="locatedIn">
      <div class="form-control-feedback">
        <span *ngIf="hasError('locatedIn', 'maxlength')">{{ errorContent.maxlength }}</span>
      </div>
    </div>
    <div class="form-group">
      <label class="form-control-label">
        <app-tooltip *ngIf="content.inputAddressLookup && content.inputAddressLookup.tooltip" [tooltipText]="content.inputAddressLookup.tooltip"></app-tooltip>
        {{ content.inputAddressLookup?.label }}
      </label>
      <app-place-autocomplete (fillIn)="onFillIn($event)"></app-place-autocomplete>
    </div>
    <div class="form-group"
         [ngClass]="{'has-danger':hasDanger('address1')}">
      <label for="rest-address-1" class="form-control-label">
        <app-tooltip *ngIf="content.inputAddress1 && content.inputAddress1.tooltip" [tooltipText]="content.inputAddress1.tooltip"></app-tooltip>
        {{ content.inputAddress1?.label }}
      </label>
      <input type="text" class="form-control form-control-lg" id="rest-address-1"
             autocomplete="new-password"
             placeholder="{{ content.inputAddress1?.placeholder }}"
             formControlName="address1" readonly>
      <div class="form-control-feedback">
        <span *ngIf="hasError('address1', 'required')">{{ errorContent.required }}</span>
      </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-danger':hasDanger('address2')}">
      <label for="rest-address-2" class="form-control-label">
        <app-tooltip *ngIf="content.inputAddress2 && content.inputAddress2.tooltip" [tooltipText]="content.inputAddress2.tooltip"></app-tooltip>
        {{ content.inputAddress2?.label }}
      </label>
      <input type="text" class="form-control form-control-lg" id="rest-address-2"
             autocomplete="new-password"
             placeholder="{{ content.inputAddress2?.placeholder }}"
             formControlName="address2">
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('city')}">
          <label for="rest-city" class="form-control-label">
            <app-tooltip *ngIf="content.inputCity && content.inputCity.tooltip" [tooltipText]="content.inputCity.tooltip"></app-tooltip>
            {{ content.inputCity?.label }}
          </label>
          <input type="text" class="form-control form-control-lg" id="rest-city"
                 autocomplete="off"
                 placeholder="{{ content.inputCity?.placeholder }}"
                 formControlName="city" readonly>
          <div class="form-control-feedback">
            <span *ngIf="hasError('city', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('state')}">
          <label for="rest-state" class="form-control-label">
            <app-tooltip *ngIf="content.inputState && content.inputState.tooltip" [tooltipText]="content.inputState.tooltip"></app-tooltip>
            {{ content.inputState?.label }}
          </label>
          <input class="form-control form-control-lg" id="rest-state"
                 formControlName="state" readonly>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('zipCode')}">
          <label for="rest-zipcode" class="form-control-label">
            <app-tooltip *ngIf="content.inputZipcode && content.inputZipcode.tooltip" [tooltipText]="content.inputZipcode.tooltip"></app-tooltip>
            {{ content.inputZipcode?.label }}
          </label>
          <input type="text" class="form-control form-control-lg" id="rest-zipcode"
                 autocomplete="off"
                 placeholder="{{ content.inputZipcode?.placeholder }}"
                 formControlName="zipCode" readonly>
          <div class="form-control-feedback">
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('country')}">
          <label for="rest-country" class="form-control-label">
            <app-tooltip *ngIf="content.inputCountry && content.inputCountry.tooltip" [tooltipText]="content.inputCountry.tooltip"></app-tooltip>
            {{ content.inputCountry?.label }}
          </label>
          <input class="form-control form-control-lg" name="country" id="rest-country"
                 formControlName="country" readonly>
          <div class="form-control-feedback">
            <div *ngIf="hasError('country', 'required')">{{ errorContent.required }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('timeZone')}">
          <label for="rest-timezone" class="form-control-label">
            <app-tooltip *ngIf="content.inputTimeZone && content.inputTimeZone.tooltip" [tooltipText]="content.inputTimeZone.tooltip"></app-tooltip>
            {{ content.inputTimeZone?.label }}
          </label>
          <input class="form-control form-control-lg" id="rest-timezone"
                 formControlName="timeZone" readonly>
          <div class="form-control-feedback">
            <span *ngIf="hasError('timeZone', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group"
             [ngClass]="{'has-danger':(hasDanger('phone') || !phoneValid)}">
          <label for="rest-phone" class="form-control-label">
            <app-tooltip *ngIf="content.inputPhone && content.inputPhone.tooltip" [tooltipText]="content.inputPhone.tooltip"></app-tooltip>
            {{ content.inputPhone?.label }}
          </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">+{{ countryPrefix }}</div>
              <input type="tel" class="form-control form-control-lg" id="rest-phone"
                     autocomplete="off"
                     placeholder="{{ content.inputPhone?.placeholder }}"
                     formControlName="phone">
            </div>
          </div>
          <div class="form-control-feedback">
            <span *ngIf="hasError('phone', 'required')">{{ errorContent.required }}</span>
            <span *ngIf="!phoneValid">Invalid phone number</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group"
             [ngClass]="{'has-danger':hasDanger('currencyCode')}">
          <label for="rest-timezone" class="form-control-label">
            Currency
          </label>
          <select class="form-control form-control-lg" id="currency-code"
                  formControlName="currencyCode">
            <option *ngFor="let currency of currencyData" [value]="currency.code">{{ currency.currency }}</option>
          </select>
          <div class="form-control-feedback">
            <span *ngIf="hasError('currencyCode', 'required')">{{ errorContent.required }}</span>
          </div>
        </div>
      </div>
    </div>
    <hr>

    <ng-container *ngIf="'' | admin">
      <div class="form-group">
        <app-tooltip *ngIf="content.inputReferredCheckbox && content.inputReferredCheckbox.tooltip" [tooltipText]="content.inputReferredCheckbox.tooltip"></app-tooltip>
        &nbsp;
        <div class="form-check d-inline-block">
          <input type="checkbox" class="form-check-input" id="hasReferral"
                 formControlName="hasReferral"
                 (click)="toggleReferral()">
          <label class="form-check-label" for="hasReferral">
            <span class="small">{{ content.inputReferredCheckbox?.label }}?</span>
          </label>
        </div>
      </div>
      <div class="form-group"
           [ngClass]="{'has-danger':hasDanger('referralUserId')}">
        <!-- TODO: Need this added to Contentful to add tooltip and placeholder -->
        <label class="form-control-label">
          <app-tooltip *ngIf="content.inputReferringUser && content.inputReferringUser.tooltip" [tooltipText]="content.inputReferringUser.tooltip"></app-tooltip>
          {{ content.inputReferringUser?.label }}
        </label>
        <input type="text" class="form-control form-control-lg"
               autocomplete="off"
               formControlName="referralUserId">
        <div class="form-control-feedback">
          <span *ngIf="hasError('referralUserId', 'required')">{{ errorContent.required }}</span>
        </div>
      </div>
      <div class="form-group">
        <!-- TODO: Connect Checkbox -->
        <app-tooltip *ngIf="content.inputAttributionCheckbox && content.inputAttributionCheckbox.tooltip" [tooltipText]="content.inputAttributionCheckbox.tooltip"></app-tooltip>
        &nbsp;
        <div class="form-check d-inline-block">
          <input type="checkbox" class="form-check-input" id="hasAttribution"
                 formControlName="hasAttribution"
                 (click)="toggleAttribution()">
          <label class="form-check-label" for="hasAttribution">
            <span class="small">{{ content.inputAttributionCheckbox?.label }}</span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <!-- TODO Connect Attributions -->
          <div class="form-group"
               [ngClass]="{'has-danger':hasDanger('attributionName')}">
            <label class="form-control-label">
              <!-- TODO: inputAttributionName needs to be fixed in Contentful -->
              <app-tooltip *ngIf="content.inputAttributionName && content.inputAttributionName.tooltip" [tooltipText]="content.inputAttributionName.tooltip"></app-tooltip>
              {{ content.inputAttributionName?.label }}
            </label>
            <!-- TODO: Connect Input -->
            <input type="text" class="form-control form-control-lg"
                   autocomplete="off"
                   [placeholder]="content.inputAttributionName.placeholder || ''"
                   formControlName="attributionName">
            <div class="form-control-feedback">
              <span *ngIf="hasError('attributionName', 'required')">{{ errorContent.required }}</span>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group"
               [ngClass]="{'has-danger':hasDanger('attributionDate')}">
            <label class="form-control-label">
              <app-tooltip *ngIf="content.inputAttributionEnds && content.inputAttributionEnds.tooltip" [tooltipText]="content.inputAttributionEnds.tooltip"></app-tooltip>
              {{ content.inputAttributionEnds?.label }}
            </label>
            <input type="date" class="form-control form-control-lg"
                   [placeholder]="content.inputAttributionEnds.placeholder || ''"
                   formControlName="attributionDate">
            <div class="form-control-feedback">
              <span *ngIf="hasError('attributionDate', 'required')">{{ errorContent.required }}</span>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>

    <div class="form-footer">
      <span class="pull-right">
        <span>
          <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="onCancel()">Cancel</button>
        </span>
          <button class="btn btn-outline-primary btn-sm" [title]="restaurantForm.invalid ? getValidationErrorString() : ''"
                  type="submit" [disabled]="!restaurantForm.valid || isSubmitting">
            <!-- The <span> below will change the button text to a
            3-dot loading animation while the form submits -->
            <span [ngClass]="isSubmitting ? 'loader-small span-loader' : ''">
              Done
            </span>
          </button>
      </span>
    </div>
  </form>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
