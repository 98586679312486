import { Pipe, PipeTransform } from '@angular/core';
import { MenuItemModel } from '@generativ/wto-api-client';

@Pipe({
  name: 'menuItemSavedRated'
})
export class MenuItemSavedRatedPipe implements PipeTransform {

  transform(menuItem: MenuItemModel): boolean {
    return menuItem.savedCount > 0 || menuItem.ratingCount > 0;
  }

}
