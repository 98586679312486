import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestaurantModel, RestaurantSearchModel } from '@generativ/wto-api-client';
import { RestaurantService } from '../restaurant/shared/restaurant.service';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { ContentfulService } from '../shared/contentful.service';
import { EntryDictionary, PageHomeType } from '@generativ/wto-admin-types';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../shared/user.service';
import { AdminPipe } from '../shared/pipes/admin.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  restaurant: RestaurantSearchModel | RestaurantModel;
  searching = false;
  searchFailed = false;
  content: PageHomeType;
  ownedRestaurants: RestaurantModel[] = [];

  constructor(
    private restaurantService: RestaurantService,
    private router: Router,
    private contentfulService: ContentfulService,
    private authService: AuthService,
    private userService: UserService,
    private adminPipe: AdminPipe
  ) {
    if (!this.adminPipe.transform()) {
      // Get and sort owned restaurants alphabetically
      this.ownedRestaurants = this.userService.currentUser.restaurants
        .sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
    }
    this.restaurant = new RestaurantSearchModel();
  }

  ngOnInit() {
    this.contentfulService.getAdminEntry(EntryDictionary.PageHome.pageHome).then(
      (entry) => {
        this.content = new PageHomeType(entry);
      }
    );
  }

  onSelectItem(event?) {
    console.log(event);
  }

  restaurantSelected(restaurant: RestaurantModel): void {
    this.restaurant = restaurant;
    this.manageRestaurant();
  }

  manageRestaurant() {
    if ( this.restaurant.id ) {
      this.router.navigate(['restaurant', this.restaurant.id]);
    }
  }

  search = (text: Observable<string>) => {
    return text.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
        switchMap(term =>
          new Observable((observer: Observer<RestaurantSearchModel[]>) => {
            this.restaurantService.search(term)
              .then((hits) => {
                this.searchFailed = false;
                observer.next(hits);
              })
              .catch(() => {
                this.searchFailed = true;
                observer.next([]);
              });
          })
        ),
        tap(() => this.searching = false));
  }

  formatter = (r: RestaurantSearchModel) => r.name ? r.name : '';

  applyClass(restaurantId, removeClass = false): void {
    const el = document.getElementById(`restaurant-${restaurantId}`);
    if (el) {
      if (!removeClass && !el.classList.contains('active')) {
        el.classList.add('active');
      } else if (el.classList.contains('active')) {
        el.classList.remove('active');
      }
    }
  }
}
