<ng-container *ngIf="ready">
  <ng-container *ngIf="photoApproval && photoApproval.length > 0; else noPhotos">
    <ng-container *ngFor="let photo of photoApproval; let i = index">
      <div class="row photo-row">
          <div class="col-3">
            <a [href]="null" (click)="openPopup(photo.url)">
              <img [src]="photo.url | filestackThumbnail: 600 : 600">
            </a>
          </div>
          <div class="col-3">
            <h6>{{ photo.menuItem.name }}</h6>
            <h6>{{ photo.menuItem.menuGroupNames }}</h6>
            <h6>{{ photo.menuItem.restaurantName }}</h6>
            <br>
            <h6>{{ photo.source }}</h6>
            <h6>{{ photo.createdAt | date }}</h6>
            <h6>{{ photo.user?.email }}</h6>
          </div>
          <div class="col-3">
            <img [src]="photo.menuItem.image ? (photo.menuItem.image | filestackThumbnail: 600 : 600 ) : '../../../assets/images/icon-camera-active.svg'">
          </div>
          <div class="col-3">
            <div class="submit-buttons-container">
              <div class="row">
                <button class="btn btn-primary" (click)="approveAsNew(photo)">Approve as New</button>
              </div>
              <div class="row">
                <button class="btn btn-primary" (click)="approveAsCompliant(photo)">Approve as Compliant</button>
              </div>
              <div class="row">
                <button class="btn btn-primary" (click)="openModal(confirmDeletePhoto)">Delete from System</button>
              </div>

              <ng-template #confirmDeletePhoto let-c="close">
                <div class="modal-header">
                  <strong class="modal-title text-uppercase">Warning</strong>
                  <button type="button" class="close" aria-label="Close" (click)="c(confirmDeletePhoto)">
                    <span aria-hidden="true">X</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p class="text-center">This photo is not saved in the Menu Manager and this action cannot be undone.</p>

                  <div class="text-center">
                    <label>Select a reason for deleting photo:</label>
                    <select [(ngModel)]="deleteReason">
                      <option value="-1" disabled>Select Deletion Reason</option>
                      <option *ngFor="let reason of deleteReasons; let i = index" [value]="i">{{ reason }}</option>
                    </select>
                  </div>

                  <div class="text-center">
                    <button class="btn btn-primary btn-sm" (click)="deleteFromSystem(photo)" [disabled]="deleteReason < 0">
                      Delete Photo
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noPhotos>
    <div class="no-photos">
      <h3>
        All photos approved.
      </h3>
      <button class="btn btn-primary" (click)="showMore()">Check for More Photos</button>
    </div>
  </ng-template>
</ng-container>
