import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ModalService } from '../../shared/modal/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RestaurantService } from '../shared/restaurant.service';
import { RestaurantModel, RestaurantRequest } from '@generativ/wto-api-client';
import { NgbDatepickerConfig, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { EntryDictionary, FormTemporarilyClosedType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';

@Component({
  selector: 'app-restaurant-close',
  templateUrl: './restaurant-close.component.html',
  styleUrls: ['./restaurant-close.component.scss'],
  providers: [NgbDatepickerConfig]
})
export class RestaurantCloseComponent implements OnInit, OnDestroy {

  restaurantCloseForm: FormGroup;
  restaurantId: number;
  restaurant: RestaurantModel;
  errorMessage: string;
  @ViewChild('d') datePicker: NgbInputDatepicker;
  minDate;
  content: FormTemporarilyClosedType;

  routerSub: Subscription;
  restaurantSub: Subscription;
  resultSub: Subscription;
  ready: boolean;
  waiting = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private restaurantService: RestaurantService,
    private fb: FormBuilder,
    private config: NgbDatepickerConfig,
    private contentfulService: ContentfulService
  ) {
    this.createFormGroup();

    this.contentfulService.getAdminEntry(EntryDictionary.FormTemporarilyClosed.formTemporarilyClosed)
      .then(entry => this.content = new FormTemporarilyClosedType(entry));
  }

  ngOnInit() {
    this.routerSub = this.modalService.getActivatedRoute().parent.params.subscribe((params: Params) => {
      if (params.restaurantId) {
        this.restaurantId = params.restaurantId;

        this.restaurantSub = this.restaurantService.getRestaurantCache(params.restaurantId).subscribe(
          data => {
            this.restaurant = new RestaurantModel();
            this.restaurant.parse(data);
            const closedStatus = this.restaurant.closed > 0 ? 'tempClosed' : this.restaurant.takeoutDeliveryOnly ?
              'takeoutDelivery' : 'open';
            this.restaurantCloseForm.controls.closedStatus.setValue(closedStatus);
            if (this.restaurant.reopenAt) {
              const reopenAt = new Date(this.restaurant.reopenAt);
              const dateStructure = { year: reopenAt.getUTCFullYear(),
                month: reopenAt.getUTCMonth() + 1,
                day: reopenAt.getUTCDate() };
              this.restaurantCloseForm.controls.reopenAt.setValue(dateStructure);
            }
            this.ready = true;
          },
        );
      }
    });
    const today = new Date();
    this.minDate = { year: today.getUTCFullYear(), month: today.getUTCMonth() + 1, day: today.getUTCDate() };
  }

  /**
   * Calling closeModal from the component allow us to redirect the user to one page depending on
   * the component that we are.
   */
  onCloseModal() {
    this.router.navigate(['../'], { relativeTo: this.modalService.getActivatedRoute() });
  }

  get title() {
    return 'MANAGE TEMPORARY LOCATION CLOSURE';
  }

  submitForm() {
    const updateRequest = new RestaurantRequest.Update();
    this.restaurant.closed = this.restaurantCloseForm.controls.closedStatus.value === 'tempClosed' ? 1 : 0;
    this.restaurant.takeoutDeliveryOnly =
      this.restaurantCloseForm.controls.closedStatus.value === 'takeoutDelivery' ? 1 : 0;
    updateRequest.restaurant = this.restaurant;
    const reopenDateFormat = this.restaurantCloseForm.controls.reopenAt.value;
    let reopenDate;
    if (reopenDateFormat) {
      // Conform month to [0-11] date indexing
      reopenDate = new Date(reopenDateFormat.year, --reopenDateFormat.month, reopenDateFormat.day);
    }
    updateRequest.restaurant.reopenAt = reopenDate;
    this.waiting = true;

    this.resultSub = this.restaurantService.editRestaurantCloseInformation(updateRequest).subscribe(
      updateResponse => {
        this.waiting = false;
        // TODO: Show one message
        this.modalService.closeCurrentModal();
        this.router.navigate(['restaurant', this.restaurant.id]);
      },
      // Todo: Show one error message.
      error => {
        this.errorMessage = <any>error;
        this.waiting = false;
      }
    );
  }


  private createFormGroup() {
    this.restaurantCloseForm = this.fb.group({
      closedStatus: [
        ''
      ],
      reopenAt: [
        null
      ],
    });
  }

  ngOnDestroy() {
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
  }
}
