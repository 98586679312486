import { Pipe, PipeTransform } from '@angular/core';
import { MenuModel } from '@generativ/wto-api-client';

@Pipe({
  name: 'menuSavedRated'
})
export class MenuSavedRatedPipe implements PipeTransform {

  transform(menu: MenuModel): boolean {
    for (const group of menu.groups) {
      for (const item of group.items) {
        if (item.savedCount > 0 || item.ratingCount > 0) {
          return true;
        }
      }
    }
    return false;
  }

}
