<ng-container *ngIf="ready && content && errorContent; else loader">
  <div *ngIf="errorMessage" class="text-danger">
    <i class="fa fa-warning"></i> {{errorMessage}}
  </div>
  <form [formGroup]="menuItemForm" autocomplete="off"
        (keydown.enter)="$event.preventDefault()">
    <div class="form-group row"
         [ngClass]="{'has-danger':hasDanger('name')}">
      <!-- Custom Padding on the <div> below to keep the input vertically aligned with the image on the right -->
      <div class="col-9 input-spacing">
        <label for="item-name" class="form-control-label font-weight-bold">
          <app-tooltip *ngIf="content.inputName && content.inputName.tooltip"
                       [tooltipText]="content.inputName.tooltip"></app-tooltip>
          {{ content.inputName?.label }}
        </label>
        <input type="text" class="form-control form-control-lg" id="item-name"
               autocomplete="off"
               [placeholder]="content.inputName.placeholder || ''"
               formControlName="name">
        <div class="form-control-feedback">
          <span *ngIf="hasError('name', 'required')">{{ errorContent.required }}</span>
          <span *ngIf="hasError('name', 'maxlength')">{{ errorContent.maxlength }}</span>
        </div>
      </div>
      <div class="col-3 delete-container">
        <a (click)="selectPhoto()">
          <img *ngIf="image; else addPhoto" src="{{ image | filestackThumbnail }}" class="camera-icon">
          <ng-template #addPhoto>
          <div class="add-image">
            <!-- Custom padding in the <div> below to make the image larger and keep it square -->
            <div class="text-center image-spacing pb-3">
              <img src="../../../../assets/images/icon-camera-active.svg" class="camera-icon">
              <br>
              <p>Add Photo</p>
            </div>
          </div>
          </ng-template>
        </a>
        <div *ngIf="image" class="overlay-image">
          <span class="remove" (click)="openModal(confirmDeletePhoto)">X</span>
        </div>
        <ng-template #confirmDeletePhoto let-c="close">
          <div class="modal-header">
            <strong class="modal-title text-uppercase">Warning</strong>
            <button type="button" class="close" aria-label="Close" (click)="c(confirmDeletePhoto)">
              <span aria-hidden="true">X</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-center">This photo is not saved in the Menu Manager and this action cannot be undone.</p>
            <div class="text-center">
              <button class="btn btn-primary btn-sm" (click)="removePhoto()">Delete Photo</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="form-group hidden-xl-down" [ngClass]="{'has-danger':hasDanger('description')}">
      <label class="form-control-label font-weight-bold">
        <app-tooltip *ngIf="content.inputDescription && content.inputDescription.tooltip"
                     [tooltipText]="content.inputDescription.tooltip"></app-tooltip>
        {{ content.inputDescription?.label }}
      </label>
      <textarea class="form-control form-control-lg" formControlName="description" rows="4"
                placeholder="{{ content.inputDescription?.placeholder }}"></textarea>
      <div class="form-control-feedback">
        <span *ngIf="hasError('description', 'maxlength')">{{ errorContent.maxlength }}</span>
      </div>
    </div>

    <ng-container>
      <div class="form-group hidden-xl-down">
        <label class="form-control-label font-weight-bold">
          <app-tooltip *ngIf="content.inputAlternateName && content.inputAlternateName.tooltip"
                       [tooltipText]="content.inputAlternateName.tooltip"></app-tooltip>
          {{ content.inputAlternateName?.label }}
        </label>
        <textarea class="form-control form-control-lg" formControlName="altName" rows="4"
                  [placeholder]="content.inputAlternateName.placeholder"></textarea>
        <span *ngIf="hasError('altName', 'maxlength')">{{ errorContent.maxlength }}</span>
      </div>
    </ng-container>

    <div class="row col-9">
      <label for="item-ordering-url" class="form-control-label font-weight-bold">
        <app-tooltip *ngIf="content.inputOrderingUrl && content.inputOrderingUrl.tooltip"
                     [tooltipText]="content.inputOrderingUrl.tooltip"></app-tooltip>
        {{ content.inputOrderingUrl?.label }}
      </label>
      <input type="text" class="form-control form-control-lg" id="item-ordering-url"
             autocomplete="off"
             [placeholder]="content.inputOrderingUrl.placeholder || ''"
             formControlName="orderingUrl">
      <div class="form-control-feedback">
        <span *ngIf="hasError('orderingUrl', 'maxlength')">{{ errorContent.maxlength }}</span>
        <span *ngIf="hasError('orderingUrl', 'invalidUrl')">{{ errorContent.isUrl }}</span>
      </div>
    </div>

    <div class="form-group mt-3">
      <label class="form-control-label font-weight-bold">Type*</label>
      <div class="custom-control custom-radio d-inline-block">
        <input type="radio" id="radioFoodType" name="isFoodItem" [checked]="isFoodMenu"
          class="custom-control-input" disabled>
        <label class="custom-control-label" for="radioFoodType">Food</label>
      </div>
      <div class="custom-control custom-radio d-inline-block">
        <input type="radio" id="radioDrinkType" name="radioDrinkType" class="custom-control-input"
               [checked]="!isFoodMenu" disabled>
        <label class="custom-control-label" for="radioDrinkType">Drink</label>
      </div>
    </div>
    <hr class="dashed">
    <div class="row col-12">
      <label class="form-control-label font-weight-bold">
        <app-tooltip *ngIf="content.inputPrice && content.inputPrice.tooltip"
                     [tooltipText]="content.inputPrice.tooltip"></app-tooltip>
        {{ content.inputPrice?.label }}
      </label>
    </div>
    <div class="row">
      <div class="col-3" [ngClass]="{'has-danger':hasDanger('price')}">
        <input type="text" class="form-control form-control-lg" id="item-price"
               [placeholder]="content.inputPrice.placeholder || '0.00'"
               autocomplete="off"
          step="0.01" formControlName="price">
        <div class="form-control-feedback">
          <span *ngIf="hasError('price', 'required')">{{ errorContent.required }}</span>
          <span *ngIf="hasError('price', 'pattern')">{{ errorContent.pattern }}</span>
        </div>
      </div>
      <div class="col-9 center">
        <div class="custom-control custom-radio d-inline-block">
          <input type="radio" id="normalPrice" name="normalPrice" class="custom-control-input" value="normalPrice"
                 [checked]="menuItemForm.controls.priceType.value === 'normalPrice'"
                 (click)="selectPriceType('normalPrice')">
          <label class="custom-control-label" for="normalPrice">Normal Price</label>
        </div>
        <div class="custom-control custom-radio d-inline-block">
          <input type="radio" id="noPrice" name="noPrice" class="custom-control-input" value="noPrice"
                 [checked]="menuItemForm.controls.priceType.value === 'noPrice'"
                 (click)="selectPriceType('noPrice')">
          <label class="custom-control-label" for="noPrice">No Price</label>
        </div>
        <div class="custom-control custom-radio d-inline-block">
          <input type="radio" id="marketPrice" name="marketPrice" class="custom-control-input" value="marketPrice"
                 [checked]="menuItemForm.controls.priceType.value === 'marketPrice'"
                 (click)="selectPriceType('marketPrice')">
          <label class="custom-control-label" for="marketPrice">Market Price</label>
        </div>
        <div class="custom-control custom-radio d-inline-block">
          <input type="radio" id="fixedPrice" name="fixedPrice" class="custom-control-input" value="fixedPrice"
                 [checked]="menuItemForm.controls.priceType.value === 'fixedPrice'"
                 (click)="selectPriceType('fixedPrice')">
          <label class="custom-control-label" for="fixedPrice">Fixed Price</label>
        </div>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <label class="form-control-label font-weight-bold">Tags</label>
      <div class="row item-tags">
        <div class="col-4">
          <label class="form-control-label">
            <app-tooltip *ngIf="content.inputDietaryTags && content.inputDietaryTags.tooltip"
                         [tooltipText]="content.inputDietaryTags.tooltip"></app-tooltip>
            {{ content.inputDietaryTags?.label }}
          </label>
          <br>
          <ss-multiselect-dropdown class="form-control form-control-sm"
                                   formControlName="dietaryOptionsSelected"
                                   [texts]="{ defaultTitle: content.inputDietaryTags.placeholder || 'Select' }"
                                   [settings]="tagSettings"
                                   [options]="dietaryAllTags">
          </ss-multiselect-dropdown>
        </div>

        <div class="col-4">
          <label class="form-control-label">
            <app-tooltip *ngIf="content.inputEnvironmentalTags && content.inputEnvironmentalTags.tooltip"
                         [tooltipText]="content.inputEnvironmentalTags.tooltip"></app-tooltip>
            {{ content.inputEnvironmentalTags?.label }}
          </label>
          <br>
          <ss-multiselect-dropdown class="form-control form-control-sm"
                                   formControlName="environmentalOptionsSelected"
                                   [texts]="{ defaultTitle: content.inputEnvironmentalTags.placeholder || 'Select' }"
                                   [settings]="tagSettings"
                                   [options]="environmentalAllTags">
          </ss-multiselect-dropdown>
        </div>

        <div class="col-4">
          <label class="form-control-label">
            <app-tooltip [tooltipText]="content?.inputOtherTags?.tooltip"></app-tooltip>
            {{ content.inputOtherTags?.label }}
          </label>
          <br>
          <ss-multiselect-dropdown class="form-control form-control-sm"
                                   formControlName="otherOptionsSelected"
                                   [texts]="{ defaultTitle: content.inputOtherTags.placeholder || 'Select' }"
                                   [settings]="tagSettings"
                                   [options]="otherAllTags">
          </ss-multiselect-dropdown>
        </div>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <label class="form-control-label font-weight-bold">Choices</label>
    </div>
    <div formArrayName="categories">
      <ng-container *ngFor="let category of menuItemForm.get('categories').controls; let categoryIndex=index" [formGroupName]="categoryIndex">
          <div class="form-group">
            <label class="form-control-label">
              <app-tooltip *ngIf="content.inputChoiceCategory && content.inputChoiceCategory.tooltip"
                           [tooltipText]="content.inputChoiceCategory.tooltip"></app-tooltip>
             {{ content.inputChoiceCategory?.label }}
              <div class="form-check d-inline-block ml-3">
                <input type="checkbox" class="form-check-input" id="categoryRequired"
                       [formControlName]="'isRequired'">
                <label class="form-check-label" for="categoryRequired"><span class="small">Required?</span></label>
              </div>
              <div class="form-control-feedback">
                <span *ngIf="hasCategoryRequiredError(categoryIndex)">{{ errorContent?.required }}</span>
              </div>
            </label>
            <div class="row">
              <div class="center" [ngClass]="categoryIndex + 1 > 9 ? 'custom-align-2' : 'custom-align'">
                <strong class="numbering">{{ categoryIndex + 1 }}</strong>
              </div>
              <div class="col-10">
                <input type="text" class="form-control form-control-lg"
                       [placeholder]="content.inputChoiceCategory.placeholder || ''"
                       [formControlName]="'categoryName'"
                       autocomplete="off">
              </div>
              <div class="col-1 center alignment">
                <img class="icon" *ngIf="showAddCategoryButton(categoryIndex)" src="../../../assets/images/icon-add.svg"
                     (click)="addCategory()"/>
              </div>
              <div class="col-1 center alignment">
                <!--Only show if unless one category is created-->
                <strong *ngIf="categoryIndex > 0" (click)="removeCategory(categoryIndex)" class="cursor">x</strong>
              </div>
            </div>
          </div>
          <div formArrayName="choices">
            <div class="row">
              <div class="col-6">
                  <label class="form-control-label">
                    <app-tooltip *ngIf="content.inputChoice && content.inputChoice.tooltip"
                                 [tooltipText]="content.inputChoice.tooltip">
                    </app-tooltip>
                    {{ content.inputChoice?.label }}
                  </label>
              </div>
              <div class="col-4">
                  <label class="form-control-label">
                    <app-tooltip *ngIf="content.inputChoicePrice && content.inputChoicePrice.tooltip"
                                 [tooltipText]="content.inputChoicePrice.tooltip">
                    </app-tooltip>
                    {{ content.inputChoicePrice?.label }}
                  </label>
              </div>
            </div>
              <div class="row" *ngFor="let choice of getChoices(categoryIndex).controls; let choiceIndex=index"
                   [formGroupName]="choiceIndex">
                <div class="center" [ngClass]="choiceIndex + 1 > 9 ? 'custom-align-2' : 'custom-align'">
                  <strong class="numbering">{{ choiceIndex + 1 }}</strong>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <input type="text" class="form-control form-control-lg"
                           [placeholder]="content.inputChoice.placeholder || ''"
                           [formControlName]="'name'"
                           autocomplete="off">
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <input type="text" class="form-control form-control-lg"
                           [placeholder]="content.inputChoicePrice.placeholder || '0.00'"
                           formControlName="price"
                           autocomplete="off">
                    <div class="form-control-feedback">
                      <span *ngIf="hasArrayError(categoryIndex, choiceIndex, 'price', 'pattern')">Must be a valid price.</span>
                      <span *ngIf="hasPriceError(categoryIndex, choiceIndex)">{{ errorContent.required }}</span>
                    </div>
                  </div>
                </div>
                <!-- Custom bottom padding added to properly center these two icons as they are affected by the numbering of choices -->
                <div class="col-1 center alignment pb-3">
                  <img class="icon" *ngIf="showAddChoiceButton(categoryIndex, choiceIndex)"
                       src="../../../assets/images/icon-add.svg" (click)="addChoice(categoryIndex)"/>
                </div>
                <div class="col-1 center alignment pb-3">
                  <!--Only show if unless one category is created-->
                  <strong *ngIf="choiceIndex > 0" (click)="removeChoice(categoryIndex, choiceIndex)" class="cursor">x</strong>
                </div>
              </div>
          </div>
        <hr>
      </ng-container>
    </div>
    <div class="form-footer">
      <ng-container *ngIf="!isEditMenuItem">
        <ng-container *ngIf="menuItemForm.invalid; else nextEnabled">
          <span [ngbTooltip]="tipContent">
            <button class="btn btn-outline-primary btn-sm"
                    [disabled]="!menuItemForm.valid || isSubmitting" (click)="nextGroupClicked = true; submitForm()">
              Next Group
            </button>
          </span>
          <span [ngbTooltip]="tipContent">
            <button class="btn btn-outline-primary btn-sm"
                    [disabled]="!menuItemForm.valid || isSubmitting" (click)="submitForm()">
              Next Item
            </button>
          </span>
          <span [ngbTooltip]="tipContent">
          <button class="btn btn-outline-primary btn-sm" type="submit"
                  [disabled]="!menuItemForm.valid || isSubmitting" (click)="copyAndEditClicked = true; submitForm()">
              Copy & Edit
          </button>
        </span>
        </ng-container>
        <ng-template #nextEnabled>
          <span>
            <button class="btn btn-outline-primary btn-sm"
                    [disabled]="!menuItemForm.valid || isSubmitting" (click)="nextGroupClicked = true; submitForm()">
              Next Group
            </button>
          </span>
          <span>
            <button class="btn btn-outline-primary btn-sm"
                    [disabled]="!menuItemForm.valid || isSubmitting" (click)="submitForm()">
              Next Item
            </button>
          </span>
          <span>
            <button class="btn btn-outline-primary btn-sm" type="submit"
                    [disabled]="!menuItemForm.valid || isSubmitting" (click)="copyAndEditClicked = true; submitForm()">
              Copy & Edit
            </button>
          </span>
        </ng-template>
      </ng-container>

      <span class="pull-right">
        <span>
          <button class="btn btn-outline-primary btn-sm" [disabled]="isSubmitting" (click)="onCancel()">Cancel</button>
        </span>
        <span [ngbTooltip]="tipContent" *ngIf="menuItemForm.invalid; else doneEnabled">
          <button class="btn btn-outline-primary btn-sm" type="submit"
                  [disabled]="!menuItemForm.valid || isSubmitting" (click)="doneClicked = true; submitForm()">
              Done
          </button>
        </span>
        <ng-template #doneEnabled>
          <span>
            <button class="btn btn-outline-primary btn-sm" type="submit"
                    [disabled]="!menuItemForm.valid || isSubmitting" (click)="doneClicked = true; submitForm()">
              <!-- The <span> below will change the button text to a
              3-dot loading animation while the form submits -->
              <span [ngClass]="doneClicked ? 'loader-small span-loader' : ''">
                Done
              </span>
            </button>
          </span>
        </ng-template>
      </span>
    </div>
  </form>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #tipContent>The following fields have errors:
  <br>
  <ul>
    <li *ngFor="let field of getValidationErrors()">
      {{ field }}
    </li>
  </ul>
</ng-template>
