import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../shared/modal/modal.service';
import { MenuGroupModel, MenuModel, MenuRequest } from '@generativ/wto-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { ParamsService } from '../../shared/params.service';
import { RestaurantService } from '../../restaurant/shared/restaurant.service';
import { MenuService } from '../../menu/shared/menu.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-reorder-menu-groups',
  templateUrl: './reorder-menu-groups.component.html',
  styleUrls: ['./reorder-menu-groups.component.scss']
})
export class ReorderMenuGroupsComponent implements OnInit, OnDestroy {

  route: ActivatedRoute;
  menu: MenuModel;
  subs = new Subscription();
  isSubmitting: boolean;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private paramsService: ParamsService,
    private dragulaService: DragulaService,
    private restaurantService: RestaurantService,
    private menuService: MenuService
  ) {
    this.route = this.modalService.getActivatedRoute();
    dragulaService.createGroup('groups-bag', {
      removeOnSpill: false
    });


    this.subs.add(dragulaService.out('groups-bag').subscribe(() => {
      this.onDrop();
    }));
  }

  ngOnInit() {
    const restaurantId = +this.paramsService.getParamById('restaurantId', this.route.snapshot);
    const menuId = +this.paramsService.getParamById('menuId', this.route.snapshot);
    this.subs.add(this.restaurantService.getRestaurantCache(restaurantId).subscribe((restaurant) => {
      this.menu = restaurant.menus.find((menu) => {
        return menu.id === menuId;
      });
    }));
  }


  onDrop() {
    this.isSubmitting = true;
    const menuGroups: MenuGroupModel[] = this.menu.groups;
    const menuGroupOrderRequest: MenuRequest.OrderUpdate = new MenuRequest.OrderUpdate();
    menuGroupOrderRequest.id = this.menu.id;

    menuGroupOrderRequest.menuGroupOrder = menuGroups.map(function (elem) {
      return elem.id;
    }).join();

    this.subs.add(this.menuService.editMenuOrder(menuGroupOrderRequest)
      .subscribe(
        updateResponse => {
          console.log(`Update MenuGroup - Parsed:`, updateResponse);
          this.isSubmitting = false;
        },
        // Todo: Add errors.
        error => this.isSubmitting = false,
      ));
  }

  get title() {
    return 'REORDER GROUPS';
  }

  onCloseModal() {
    this.router.navigate(['../../../../'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.dragulaService.destroy('groups-bag');
    this.subs.unsubscribe();
  }

}
