import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComplexTagModel, RestaurantModel, RestaurantRequest, TagModel } from '@generativ/wto-api-client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { RestaurantService } from '../restaurant.service';
import { TagService } from '../../../shared/tag.service';
import { ContentfulService } from '../../../shared/contentful.service';
import { EntryDictionary, FormRestaurantType, GenericErrorsType } from '@generativ/wto-admin-types';
import { GenericErrorService } from '../../../shared/generic-error-service';
import { FilestackService } from '../../../shared/filestack.service';
import { urlValidator } from '../../../shared/directives/url-validator.directive';
import { ModalService } from '../../../shared/modal/modal.service';
import { ErrorHandlerService } from '../../../shared/error-handler.service';
import { CountryService } from '../../../shared/helpers/country.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as currencyData from 'currency-codes/data';
import * as cc from 'currency-codes';
import { IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-restaurant-form',
  templateUrl: './restaurant-form.component.html',
  styleUrls: ['./restaurant-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default,

})
export class RestaurantFormComponent implements OnInit, OnDestroy {
  @Input() submitButtonText: string;

  cuisineTags: TagModel[];
  complexTags: TagModel[];
  reservationTags: TagModel[];
  deliveryTags: TagModel[];
  reviewTags: TagModel[];
  restaurantForm: FormGroup;
  restaurantId: number;
  isClosed: boolean;
  ready = false;
  content: FormRestaurantType;
  errorContent: GenericErrorsType;
  image: string;
  recipients: FormArray;
  menuFileRecipients: {
    company: string,
    contactEmail: string
  }[] = [];
  complex: FormArray;
  formArrayUpdate: boolean;
  phoneValid: boolean;
  countryPrefix = '';
  countryCode: string;
  timeZoneCode: string;
  currentModal: NgbModalRef;

  restaurant: RestaurantModel;
  errorMessage: string;
  currencyData = currencyData;

  // Array with mapping info between google place address attributes api and restaurant attributes
  private addressMapping = {
    city: ['locality', 'long_name'],
    state: ['administrative_area_level_1', 'short_name'],
    country: ['country', 'long_name'],
    zipCode: ['postal_code', 'short_name'],
  };

  routerSub: Subscription;
  restaurantSub: Subscription;
  resultSub: Subscription;
  tagSub: Subscription;
  complexSubs: Subscription = new Subscription();
  isSubmitting = false;

  // Tags Settings
  tagSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    selectionLimit: 1,
    closeOnSelect: false,
    showUncheckAll: false,
    dynamicTitleMaxItems: 1,
    maxHeight: '300px'
  };

  constructor(
    private fb: FormBuilder,
    private tagService: TagService,
    private restaurantService: RestaurantService,
    private router: Router,
    private route: ActivatedRoute,
    private contentfulService: ContentfulService,
    private genericErrorService: GenericErrorService,
    private filestackService: FilestackService,
    private modalService: ModalService,
    private errorHandlerService: ErrorHandlerService,
    private countryService: CountryService,
    private ngbModalService: NgbModal
  ) {
    this.createFormGroup();

    this.sortCurrenciesAlphabetically();
  }

  ngOnInit() {
    this.complexSubs.add(this.restaurantForm.controls.phone.valueChanges
      .subscribe(value => {
        if (this.countryCode && value && value.length > 2) {
          value = value.replace(/\D/g, '');
          const number: PhoneNumber = phoneNumberUtil.parse(value, this.countryCode);
          this.phoneValid = phoneNumberUtil.isValidNumberForRegion(number, this.countryCode);
          this.restaurantForm.controls.phone.setValue(phoneNumberUtil.format(number, PhoneNumberFormat.NATIONAL),
            { emitEvent: false });
        }
      }));

    this.contentfulService.getAdminEntry(EntryDictionary.FormRestaurant.formRestaurant).then(
      (entry) => {
        this.content = new FormRestaurantType(entry);
      }
    );
    this.genericErrorService.getErrorContent().then(
      (errorContent) => {
        this.errorContent = errorContent;
      }
    );

    this.tagSub = this.tagService.get().subscribe(
      data => {
        this.cuisineTags = data.filter((tag: TagModel) => tag.type === 'restaurant:cuisine');
        this.complexTags = data.filter((tag: TagModel) => {
          return tag.type === 'restaurant:reservation' || tag.type === 'restaurant:delivery' ||
            tag.type === 'restaurant:review';
        });
        this.reservationTags = data.filter((tag: TagModel) => tag.type === 'restaurant:reservation');
        this.deliveryTags = data.filter((tag: TagModel) => tag.type === 'restaurant:delivery');
        this.reviewTags = data.filter((tag: TagModel) => tag.type === 'restaurant:review');
        this.routerSub = this.modalService.getActivatedRoute().parent.params.subscribe((params: Params) => {
          if (params.restaurantId) {
            this.restaurantId = +params.restaurantId;

            this.restaurantSub = this.restaurantService.getRestaurant(this.restaurantId).subscribe(
              restaurantData => {
                this.restaurant = restaurantData;

                if (this.restaurant.primaryCuisine) {
                  this.restaurant.primaryCuisine = this.cuisineTags
                    .find((t: TagModel) => t.id === this.restaurant.primaryCuisine.id);
                }
                this.restaurant.hasOpenTable = !!restaurantData.openTableUrl;

                if (this.restaurant.menuFileRecipients) {
                  this.menuFileRecipients = JSON.parse(this.restaurant.menuFileRecipients);

                  if (this.menuFileRecipients.length > 0) {
                    for (const recipient of this.menuFileRecipients) {
                      this.addRecipient(recipient.company, recipient.contactEmail);
                    }
                  } else {
                    this.addRecipient('', '');
                  }
                } else {
                  this.addRecipient('', '');
                }

                // Set up display of country and timezone
                this.countryCode = this.restaurant.country;
                this.restaurant.country = this.restaurant.countryFullName;
                this.timeZoneCode = this.restaurant.timeZone;
                this.restaurant.timeZone = this.restaurant.timeZoneFullName;

                if (this.restaurant.countryPrefix && this.restaurant.countryPrefix.length > 0) {
                  this.countryPrefix = this.restaurant.countryPrefix;
                }

                this.restaurantForm.patchValue(this.restaurant);
                this.image = this.restaurant.image;
                this.restaurantForm.controls.listPageUrlMonitoringFrequency
                  .setValue(this.restaurant.websiteUrlMonitoringFrequency);
                this.restaurantForm.controls.listPageUrlMonitoringFrequency.disable();

                // If it has undefined or null description we should check url unavailable
                // and disable restaurant url.
                if (this.restaurant.websiteUrl == null) {
                  this.restaurantForm.controls.websiteUrlUnavailable.setValue(true);
                  this.restaurantForm.controls.websiteUrl.disable();
                }

                // If action required enable description and check action required
                if (this.restaurant.actionRequiredDescription) {
                  this.restaurantForm.controls.actionRequiredDescription.enable();
                  this.restaurantForm.controls.actionOnHomePage.setValue(true);
                }

                // Check for referral info and set controls
                if (this.restaurant.referralUserId) {
                  this.restaurantForm.controls.hasReferral.setValue(true);
                  this.restaurantForm.controls.referralUserId.enable();
                  this.restaurantForm.controls.referralUserId.setValidators(Validators.required);
                }

                // Check for attribution info and set controls
                if (this.restaurant.attributionName) {
                  this.restaurantForm.controls.hasAttribution.setValue(true);
                  this.restaurantForm.controls.attributionName.enable();
                  this.restaurantForm.controls.attributionDate.enable();
                  this.restaurantForm.controls.attributionName.setValidators(Validators.required);
                  this.restaurantForm.controls.attributionDate.setValidators(Validators.required);
                }


                /**
                 * If the restaurant doesn't have any menu with time available we shouldn't allow to change
                 * the status of the restaurant
                 */
                if (!this.hasMenuWithTimeAvailable(this.restaurant)) {
                  this.restaurantForm.controls.active.setValue(0);
                  this.restaurantForm.controls.active.disable();
                }

                if (this.restaurant.complexTags.length > 0) {
                  for (const complex of this.restaurant.complexTags) {
                    complex.tag = this.complexTags.find((t) => t.id === complex.tag.id);
                    this.addComplex(complex.tag, complex.url);
                  }
                } else {
                  this.addComplex(null, '');
                }

                this.ready = true;
              },
              error => this.errorMessage = <any>error
            );
          } else {
            this.restaurant = new RestaurantModel();
            this.restaurantForm.controls.active.disable();
            this.addRecipient('', '');
            this.addComplex(null, '');
            this.restaurantForm.controls.listPageUrlMonitoringFrequency.disable();
            this.ready = true;
          }
        });
      },
      error => this.errorMessage = <any>error
    );
  }

  submitForm() {
    if (!this.restaurantForm.valid || !this.phoneValid) {
      this.errorMessage = 'Errors on form. See fields below.';
      return;
    }

    // If no change has occurred to the form on submit, just navigate back to
    // restaurant manage page instead of updating the restaurant.
    // Form arrays must be checked separately.
    let complexIsDirty = false;
    for (const control of this.complex.controls) {
      if (control.dirty) {
        complexIsDirty = true;
        break;
      }
    }
    let recipientsAreDirty = false;
    for (const control of this.recipients.controls) {
      if (control.dirty) {
        recipientsAreDirty = true;
        break;
      }
    }
    if (!this.restaurantForm.dirty && !complexIsDirty && !recipientsAreDirty && !this.formArrayUpdate) {
      this.router.navigate(['restaurant', this.restaurant.id]);
      return;
    }

    this.isSubmitting = true;

    this.menuFileRecipients = [];
    const menuFileRecipientsControls = this.restaurantForm.controls.menuFileRecipient as FormArray;
    for (const recipient of menuFileRecipientsControls.controls as any) {
      const r = {
        company: recipient.controls.company.value,
        contactEmail: recipient.controls.contactEmail.value
      };
      if (r.company && r.contactEmail) {
        this.menuFileRecipients.push(r);
      }
    }

    const complexTags = [];
    for (const complexTag of this.complex.controls as any) {
      if (complexTag.controls.url.value && complexTag.controls.tag.value) {
        const t = new ComplexTagModel();
        t.url = complexTag.controls.url.value;
        t.tag = this.complexTags.find((tag => tag.id === complexTag.controls.tag.value[0]));
        complexTags.push(t);
      }
    }

    this.restaurant.menuFileRecipients = JSON.stringify(this.menuFileRecipients);

    this.restaurantForm.value.phone = this.restaurantForm.value.phone.replace(/\D/g, '');

    const owner = this.restaurant.owner;

    this.restaurant.parse(this.restaurantForm.value);
    this.restaurant.complexTags = complexTags;
    this.restaurant.owner = owner;

    // Switch back from timezone and country display
    this.restaurant.timeZoneFullName = this.restaurant.timeZone;
    this.restaurant.timeZone = this.timeZoneCode;
    this.restaurant.countryFullName = this.restaurant.country;
    this.restaurant.country = this.countryCode;

    this.restaurant.countryPrefix = this.countryPrefix;

    // Set no description based on description present or not.
    this.restaurant.noDescription = !(this.restaurant.briefDescription && this.restaurant.briefDescription.length > 0);

    // If Url unavailable selected we should set the description to null
    this.restaurant.websiteUrl = this.restaurantForm.value.websiteUrlUnavailable ? null :
                                 this.restaurant.websiteUrl;

    // If action required add description, otherwise set to null
    this.restaurant.actionRequiredDescription = this.restaurantForm.value.actionOnHomePage ?
                                                this.restaurant.actionRequiredDescription : null;

    // Just set the boolean flag, the URL should be set through mapping
    this.restaurant.hasOpenTable = !!this.restaurant.openTableUrl;

    // Add image Url
    this.restaurant.image = this.image ? this.image : null;

    // If there is no list page url, don't save a list page url monitoring frequency.
    this.restaurant.listPageUrlMonitoringFrequency = this.restaurant.listPageUrl ?
      this.restaurant.listPageUrlMonitoringFrequency : null;

    this.restaurant.primaryCuisine = new TagModel().parse(this.restaurantForm.controls.primaryCuisine.value);

    // this.restaurant.complexTags = this.complexEntries;

    if (this.restaurantId) {
      // Update
      const updateRequest = new RestaurantRequest.Update();
      updateRequest.restaurant = this.restaurant;
      console.log('Update Restaurant Request:', updateRequest);

      this.resultSub = this.restaurantService.editRestaurant(updateRequest)
        .subscribe(
          updateResponse => {
            console.log(`Update Restaurant - Parsed:`, updateResponse);
            this.modalService.closeCurrentModal();
            this.router.navigate(['restaurant', this.restaurant.id]);
            this.isSubmitting = false;
          },
          error =>  {
            this.isSubmitting = false;
            this.errorMessage = <any>error;
          }
        );
    } else {
      // Create
      const createRequest = new RestaurantRequest.Create().parse(this.restaurant);
      console.log('Create Restaurant Request:', createRequest);

      createRequest.cuisinePrimaryTagId = this.restaurant.primaryCuisine.id;

      this.resultSub = this.restaurantService.createRestaurant(createRequest)
        .subscribe(
          createResponse => {
            this.restaurant.id = createResponse.restaurant.id;
            console.log(`Create Restaurant - Parsed:`, createResponse);
            this.modalService.closeCurrentModal();
            this.isSubmitting = false;
            this.router.navigate(['restaurant', this.restaurant.id]);
          },
          error => {
            this.errorMessage = <any>error;
            this.isSubmitting = false;
          },
          () => console.log('Request Complete')
        );
    }
  }

  toggleHomePageUrl() {
    const control = this.restaurantForm.get('websiteUrl');
    const hasUrl = this.restaurantForm.value.websiteUrlUnavailable;
    control.disabled ? control.enable() : control.disable();
    hasUrl ? this.setWebsiteUrlValidators() : this.setNoWebsiteUrlValidators();
  }

  toggleActionOnHomePage() {
    const control = this.restaurantForm.get('actionRequiredDescription');
    const hasAction = this.restaurantForm.value.actionOnHomePage;
    control.disabled ? control.enable() : control.disable();
    !hasAction ? this.setActionOnHomePageValidators() : this.setNoActionOnHomePageValidators();
  }

  toggleListPageUrl(event: any) {
    event.target.value === '' ? this.setNoListPageUrlValidators() : this.setListPageUrlValidators();
  }

  hasError(field: string, validator: string) {
    const control = this.restaurantForm.controls[field];
    return control.hasError(validator)
      && control.touched;
  }

  hasDanger(field: string) {
    const control = this.restaurantForm.controls[field];
    return !control.disabled && !control.valid && control.touched;
  }

  hasGroupControlError(group: FormGroup, field: string, validator: string) {
    const control = group.controls[field];
    return control.hasError(validator)
      && control.touched;
  }

  hasNgError(field: string, validator: string) {
    const control = this.restaurantForm.controls[field];
    return control.errors
      && control.errors[validator]
      && control.touched;
  }

  open(content) {
    this.ngbModalService.open(content);
  }

  private setWebsiteUrlValidators() {
    this.restaurantForm.controls.websiteUrl.setValidators([ Validators.compose([
        Validators.required,
        urlValidator()
    ])]);
    this.restaurantForm.controls.websiteUrlMonitoringFrequency.setValidators([Validators.compose([
      Validators.required])]);
    this.restaurantForm.controls.websiteUrlMonitoringFrequency.setValue('10 days');
    this.restaurantForm.controls.websiteUrlMonitoringFrequency.updateValueAndValidity();
    this.restaurantForm.controls.websiteUrl.setValue(this.restaurant.websiteUrl);
  }

  private setNoWebsiteUrlValidators() {
    this.restaurantForm.controls.websiteUrl.setValidators([]);
    this.restaurantForm.controls.websiteUrl.updateValueAndValidity();
    this.restaurantForm.controls.websiteUrl.setValue(null);

    this.restaurantForm.controls.websiteUrlMonitoringFrequency.setValidators([]);
    this.restaurantForm.controls.websiteUrlMonitoringFrequency.updateValueAndValidity();
    this.restaurantForm.controls.websiteUrlMonitoringFrequency.setValue(null);
  }

  private setActionOnHomePageValidators() {
    this.restaurantForm.controls.actionRequiredDescription.setValidators([ Validators.compose([
        Validators.required,
        Validators.maxLength(300)
    ])]);
    this.restaurantForm.controls.actionRequiredDescription.setValue(this.restaurant.actionRequiredDescription);
  }

  private setNoActionOnHomePageValidators() {
    this.restaurantForm.controls.actionRequiredDescription.setValidators([]);
    this.restaurantForm.controls.actionRequiredDescription.updateValueAndValidity();
    this.restaurantForm.controls.actionRequiredDescription.setValue(null);
  }

  private setNoListPageUrlValidators() {
    this.restaurantForm.controls.listPageUrl.setValidators([]);
    this.restaurantForm.controls.listPageUrl.updateValueAndValidity();
    this.restaurantForm.controls.listPageUrl.setValue(null);

    this.restaurantForm.controls.listPageUrlMonitoringFrequency.setValidators([]);
    this.restaurantForm.controls.listPageUrlMonitoringFrequency.updateValueAndValidity();
  }

  private setListPageUrlValidators() {
    this.restaurantForm.controls.listPageUrl.setValidators([ Validators.compose([
      Validators.required,
      urlValidator()
    ])]);

    this.restaurantForm.controls.listPageUrlMonitoringFrequency.setValidators([Validators.compose([
      Validators.required
    ])]);
    if (!this.restaurantForm.controls.listPageUrlMonitoringFrequency.value) {
      this.restaurantForm.controls.listPageUrlMonitoringFrequency.setValue('10 days');
    }
    this.restaurantForm.controls.listPageUrlMonitoringFrequency.updateValueAndValidity();
  }

  onFillIn(event: any) {
    const place: google.maps.places.PlaceResult = event.place;
    const timeZone: any = event.timeZone ? event.timeZone : null;

    const infoPlace = {};

    for (const key of Object.keys(this.addressMapping)) {
      /**
       * For each key of addressMapping (city, state, country, etc)
       * We get the mapping attribute for google places (e.g: state matchs with administrative_area_level_1)
       * And the type that we want for that attribute (short_name, long_name, etc).
       */
      infoPlace[key] = this.getPlaceInfoStr(place, this.addressMapping[key][0], this.addressMapping[key][1]);
    }

    let streetNumber = this.getPlaceInfoStr(place, 'street_number', 'short_name');
    streetNumber = streetNumber.length ? streetNumber : null;
    let route = this.getPlaceInfoStr(place, 'route', 'long_name');
    route = route.length ? route : null;
    // Address 1 is compounded by two Place information component (street number and route)
    if (!!streetNumber && streetNumber.length > 0) {
      infoPlace['address1'] = streetNumber + ' ';
    }

    if (!!route && route.length > 0) {
      if (!infoPlace['address1']) {
        infoPlace['address1'] = route;
      } else {
        infoPlace['address1'] += route;
      }
    }

    // If locality information is empty we should fill city with subLocality
    const postalTown = this.getPlaceInfoStr(place, 'postal_town', 'long_name');
    const neighborhood = this.getPlaceInfoStr(place, 'neighborhood', 'long_name');
    const sublocality = this.getPlaceInfoStr(place, 'sublocality_level_1', 'long_name');

    if (infoPlace['city'].length > 0) {
      infoPlace['city'] = infoPlace['city'];
    } else if (sublocality.length > 0) {
      infoPlace['city'] = sublocality;
    } else if (postalTown.length > 0) {
      infoPlace['city'] = postalTown;
    } else if (neighborhood.length > 0) {
      infoPlace['city'] = neighborhood;
    }

    // Clear existing place data
    infoPlace['address2'] = null;

    if (timeZone) {
      infoPlace['timeZone'] = timeZone.timeZoneName;
      this.timeZoneCode = timeZone.timeZoneId;
    }

    // Trick to update phone mask
    infoPlace['phone'] = this.restaurantForm.controls.phone.value;

    const countryCode = this.getPlaceInfoStr(place, 'country', 'short_name');
    this.countryCode = countryCode;

    this.countryPrefix = this.countryService.countryPrefixMap.get(countryCode)
      ? this.countryService.countryPrefixMap.get(countryCode).countryCode.toString()
      : '';

    // Set geometric restaurant's information
    this.restaurant.lat = place.geometry.location.lat();
    this.restaurant.lon = place.geometry.location.lng();
    this.restaurantForm.patchValue(infoPlace);

    // If currency code can be pulled from address, set as default
    const currencyCode = cc.country(infoPlace['country'].toLowerCase());
    if (currencyCode.length > 0) {
      this.restaurantForm.controls.currencyCode.setValue(currencyCode[0].code);
    }

    // Since fill in from address search won't explicitly mark the form
    // as dirty, do it manually so restaurant is saved if only address is changed.
    this.restaurantForm.markAsDirty();
    this.restaurantForm.updateValueAndValidity();
  }


  getPlaceInfoStr(place: google.maps.places.PlaceResult,
                  addressAttribute: string,
                  addressType: string) {
    // Get the component for the addressAttribute (eg postal_code)
    const component = place.address_components.find(cmp => cmp.types[0] === addressAttribute);
    // Get the info selected by the user formatted as (addressType ['short_name',..])
    return component ? component[addressType] : '';
  }

  //
  // updatePrimaryCuisine(tag: TagModel) {
  //   this.restaurant.primaryCuisine = tag;
  // }


  selectPhoto() {
    this.filestackService.pickPhoto()
      .subscribe((image) => {
        this.image = image;
        console.log(`image Url: `, image);
        this.restaurantForm.markAsDirty();
      }, (error) => {
        console.log(`Error uploading file: `, error.toString());
      });
  }

  openModal(content) {
    this.currentModal = this.ngbModalService.open(content);
  }

  removePhoto() {
    this.image = null;
    this.restaurantForm.markAsDirty();
    this.currentModal.close();
  }

  // If canceled from restaurant manage, go back to that page, otherwise go home
  onCancel() {
    if (this.restaurant.id) {
      this.router.navigate(['restaurant/', this.restaurant.id]);
    } else {
      this.router.navigate(['../']);
    }
  }

  private createFormGroup() {
    this.restaurantForm = this.fb.group({
      name: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(50)
        ])
      ],
      briefDescription: [
        null,
        Validators.compose([
          Validators.maxLength(105)
        ])
      ],
      fullDescription: [
        null,
        Validators.compose([
          Validators.maxLength(150)
        ])
      ],
      primaryCuisine: [
        '',
        Validators.required
      ],
      phone: [
        null,
        Validators.compose([
          Validators.required,
        ])
      ],
      websiteUrl: [
        null,
        Validators.compose([
          Validators.required,
          urlValidator()
        ])
      ],
      websiteUrlUnavailable: false,
      websiteUrlMonitoringFrequency: '365 days',
      actionOnHomePage: false,
      actionRequiredDescription: [
        {
          value: null,
          disabled: true
        },
      ],
      listPageUrl: [
        null,
        Validators.compose([])
      ],
      listPageUrlMonitoringFrequency: [
        '365 days',
        Validators.compose([])
      ],
      instagramUrl: [
        null,
        urlValidator()
      ],
      businessType: [
        'independent',
        Validators.required
      ],
      address1: [
        null,
        Validators.compose([
          Validators.required,
        ])
      ],
      address2: [
        null,
      ],
      zipCode: [
        null,
        Validators.compose([
        ])
      ],
      city: [
        null,
        Validators.compose([
        ])
      ],
      state: [
        'NY',
      ],
      country: [
        'US',
        Validators.required
      ],
      currencyCode: [
        null,
        Validators.required
      ],
      locatedIn: [
        null,
        Validators.maxLength(64),
      ],
      timeZone: [
        'America/New_York',
        Validators.required
      ],
      neighborhood: [
        null,
        Validators.compose([
          Validators.maxLength(64),
          Validators.required
        ])
      ],
      active: [
        0,
        Validators.required
      ],
      closed: false,
      hasReferral: false,
      referralUserId: [
        {
          value: null,
          disabled: true
        }
      ],
      hasAttribution: false,
      attributionName: [
        {
          value: null,
          disabled: true
        }
      ],
      attributionDate: [
        {
          value: null,
          disabled: true
        }
      ],
      menuFileRecipient: this.fb.array([]),
      complexTags: this.fb.array([]),
      reviewCode: [
        null,
        Validators.compose([
          Validators.maxLength(32)
        ])
      ],
      feedbackEmail: [
        null,
        Validators.email
      ]
    });
  }

  private hasMenuWithTimeAvailable(restaurant: RestaurantModel) {
    for (const menu of restaurant.menus) {
      if (menu.timeUnavailable === 0) {
        return true;
      }
    }
    return false;
  }

  toggleReferral() {
    const shouldEnable = !this.restaurantForm.controls.hasReferral.value;

    if (shouldEnable) {
      this.restaurantForm.controls.referralUserId.enable();
      this.restaurantForm.controls.referralUserId.setValidators(Validators.required);
    } else {
      this.restaurantForm.controls.referralUserId.clearValidators();
      this.restaurantForm.controls.referralUserId.disable();
    }
    this.restaurantForm.controls.referralUserId.updateValueAndValidity();
  }

  toggleAttribution() {
    const shouldEnable = !this.restaurantForm.controls.hasAttribution.value;

    if (shouldEnable) {
      this.restaurantForm.controls.attributionName.enable();
      this.restaurantForm.controls.attributionDate.enable();
      this.restaurantForm.controls.attributionName.setValidators(Validators.required);
      this.restaurantForm.controls.attributionDate.setValidators(Validators.required);
    } else {
      this.restaurantForm.controls.attributionName.disable();
      this.restaurantForm.controls.attributionDate.disable();
      this.restaurantForm.controls.attributionName.clearValidators();
      this.restaurantForm.controls.attributionDate.clearValidators();
    }
    this.restaurantForm.controls.attributionDate.updateValueAndValidity();
  }

  createRecipient(company: string, contactEmail: string): FormGroup {
    return this.fb.group({
      company: [ company, Validators.minLength(2 ) ],
      contactEmail: [ contactEmail, Validators.email ]
    });
  }

  addRecipient(company: string, contactEmail: string): void {
    this.recipients = this.restaurantForm.get('menuFileRecipient') as FormArray;
    this.recipients.push(this.createRecipient(company, contactEmail));
  }

  removeRecipient(index: number): void {
    this.recipients.removeAt(index);
    this.formArrayUpdate = true;
  }

  createComplex(tag: TagModel, url: string): FormGroup {
    const complexForm =  this.fb.group({
      tag: [ tag ? [tag.id] : null, Validators.compose([]) ],
      url: [ url, Validators.compose([
        Validators.maxLength(2048),
        urlValidator()
      ])]
    });
    // Set tag as required if there is a url present.
    this.complexSubs.add(complexForm.controls.url.valueChanges
      .subscribe((value) => {
        if (value !== '') {
          complexForm.controls.tag.setValidators([ Validators.required ]);
        } else {
          complexForm.controls.tag.setValidators([]);
        }
        complexForm.controls.tag.updateValueAndValidity();
      }));
    return complexForm;
  }

  addComplex(tag: TagModel, url: string): void {
    this.complex = this.restaurantForm.get('complexTags') as FormArray;
    this.complex.push(this.createComplex(tag, url));
  }

  removeComplex(index: number): void {
    if (this.complex.controls.length === 1) {
      this.complex.controls[0]['controls'].tag.setValue(null);
      this.complex.controls[0]['controls'].url.setValue('');
    } else {
      this.complex.removeAt(index);
    }
    this.formArrayUpdate = true;
  }

  /**
   * Show invalid controls in the console for debugging purposes only
   *   Could later be used to show group errors
   */
  public findInvalidControls() {
    const invalid = [];
    const controls = this.restaurantForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
        console.log(`Invalid control ${name}`, controls[name]);
      }
    }
    return invalid;
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
    if (this.tagSub) {
      this.tagSub.unsubscribe();
    }
    this.complexSubs.unsubscribe();
  }

  getValidationErrorString(): string {
    return this.errorHandlerService.getValidationErrors(this.restaurantForm).join();
  }

  updateListPage() {
    this.restaurantForm.controls.listPageUrlMonitoringFrequency.setValue(
      this.restaurantForm.controls.websiteUrlMonitoringFrequency.value
    );
  }

  sortCurrenciesAlphabetically(): void {
    this.currencyData.sort((a, b) => {
      if (a.currency < b.currency) {
        return -1;
      }
      if (a.currency > b.currency) {
        return 1;
      }
      return 0;
    });
  }
}
