/* tslint:disable:member-ordering no-unused-variable */
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HomeComponent } from '../home/home.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbComponent } from './navbar/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from '../shared/breadcrumb.service';
import { EmailService } from '../shared/email.service';
import { SharedModule } from '../shared/shared.module';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';

@NgModule({
  imports:      [ CommonModule, RouterModule, FormsModule, NgbModule, SharedModule ],
  declarations: [ HomeComponent, FeedbackComponent, NavbarComponent, BreadcrumbComponent, PageNotFoundComponent ],
  exports:      [ HomeComponent, FeedbackComponent, NavbarComponent, PageNotFoundComponent ],
})
export class CoreModule {

  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(
    // config: UserServiceConfig
  ): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        BreadcrumbService,
        EmailService
      ]

      // providers: [
      //   {provide: UserServiceConfig, useValue: config }
      // ]
    };
  }
}
