import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ContentfulService } from '../../shared/contentful.service';
import { EntryDictionary, PageCreateType } from '@generativ/wto-admin-types';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalService } from '../../shared/modal/modal.service';
import { ParamsService } from '../../shared/params.service';
import { Subscription } from 'rxjs/Subscription';
import { MenuGroupService } from '../shared/menu-group.service';
import { MenuGroupModel } from '@generativ/wto-api-client';

@Component({
  selector: 'app-menu-group-edit',
  templateUrl: './menu-group-edit.component.html',
  styleUrls: ['./menu-group-edit.component.scss']
})
export class MenuGroupEditComponent implements OnInit, OnDestroy {
  content: PageCreateType;
  route: ActivatedRoute;
  menuGroup: MenuGroupModel;

  subs = new Subscription();
  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();

  constructor(
    private contentfulService: ContentfulService,
    private modalService: ModalService,
    private router: Router,
    private menuGroupService: MenuGroupService,
    private paramsService: ParamsService,
  ) {
    this.route = this.modalService.getActivatedRoute();
  }

  ngOnInit() {
    this.contentfulService.getAdminEntry(EntryDictionary.PageCreate.pageEditMenuGroup).then(
      (entry) => {
        this.content = new PageCreateType(entry);
      }
    );

    this.subs.add(this.route.params.subscribe((params: Params) => {
      const menuGroupId = +this.paramsService.getParamById('menuGroupId', this.route.snapshot);
      this.subs.add(this.menuGroupService.getMenuGroup(menuGroupId).subscribe(menuGroupModel => {
        this.menuGroup = menuGroupModel;
        this.setSubHeader.emit(this.menuGroup.name);
      }));
    }));
  }

  get title() {
    return 'Edit';
  }


  onCloseModal() {
    this.router.navigate(['../../../../../'], { relativeTo: this.route });
  }


  onCancel() {
    this.onCloseModal();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

