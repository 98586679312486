import { NgModule } from '@angular/core';
import { RestaurantComponent } from './restaurant.component';
import { RestaurantService } from './shared/restaurant.service';
import { RestaurantCreateComponent } from './restaurant-create/restaurant-create.component';
import { RestaurantEditComponent } from './restaurant-edit/restaurant-edit.component';
import { SharedModule } from '../shared/shared.module';
import { RestaurantFormComponent } from './shared/restaurant-form/restaurant-form.component';
import { RestaurantManageComponent } from './restaurant-manage/restaurant-manage.component';
import { PlaceAutocompleteComponent } from './place-autocomplete/place-autocomplete.component';
import { PhonePipe } from './shared/phone.pipe';
import { RestaurantRoutingModule } from './restaurant-routing.module';
import { RestaurantCloseComponent } from './restaurant-close/restaurant-close.component';
import { RestaurantExportComponent } from './restaurant-export/restaurant-export.component';
import { RestaurantEmailComponent } from './restaurant-email/restaurant-email.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

@NgModule({
  imports: [
    SharedModule,
    RestaurantRoutingModule,
    UiSwitchModule,
    MultiselectDropdownModule,
  ],
  declarations: [
    RestaurantComponent,
    RestaurantCreateComponent,
    RestaurantEditComponent,
    RestaurantFormComponent,
    RestaurantManageComponent,
    PlaceAutocompleteComponent,
    PhonePipe,
    RestaurantCloseComponent,
    RestaurantExportComponent,
    RestaurantEmailComponent
  ],
  providers: [
    RestaurantService
  ]
})
export class RestaurantModule { }
