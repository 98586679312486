import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { FeedbackModel } from '@generativ/wto-api-client';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable()
export class EmailService {
  private feedbackUrl = `${environment.wtoApiUrl}/email/feedback`;
  private menuUrl = `${environment.wtoApiUrl}/menus/`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {

  }


  public sendFeedbackEmail(feedback: FeedbackModel.Admin): Observable<FeedbackModel.Admin> {
    return this.http
      .post<FeedbackModel.Admin>(this.feedbackUrl, feedback).pipe(
        catchError(this.errorHandlerService.handleError)
      );

  }
}
