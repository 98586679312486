import { Component, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    // You need this small hack in order to catch application root view container ref
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService,
    private idle: Idle,
  ) {
    authService.handleAuthentication();


    // Sets an idle timeout of 25 seconds
    idle.setIdle(25);
    // Sets a timeout period of 1 hour. after 1 hour and 25 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(3600);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.authService.logout();
    });
    this.idle.watch();

    this.localeInitializer(navigator.language).then();
  }

  openFeedbackDialog() {
    // TODO: Fix feedback dialog
  }

  /**
   * Register locale information based on browsers preferred language
   * @param localeId - language code of browser
   */
  localeInitializer(localeId: string): Promise<any> {
    return import(
      `@angular/common/locales/${localeId}.js`
      ).then(module => registerLocaleData(module.default))
      .catch(e => console.log(`${localeId} not found.`));
  }
}
