<form [formGroup]="menuItemCopyForm" autocomplete="off" (ngSubmit)="submitForm()" >
  <div class="form-group relocate" *ngIf="menuItem">
    <div class="row">
      <div class="col-12 form-group">
        <select class="form-control form-control-lg" id="menu-select"
                formControlName="menu"
                (change)="onChange()">
          <option [ngValue]="null" disabled selected> Select Menu</option>
          <option *ngFor="let menu of menus | orderby:'name'" [ngValue]="menu" label="{{ menu.name }}">{{ menu.name }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <select class="form-control form-control-lg" id="menu-group-select"
                formControlName="menuGroupId">
          <option [ngValue]="null" disabled selected> Select Group</option>
          <option *ngFor="let menuGroup of menuGroups | orderby:'name'" [ngValue]="menuGroup.id" label="{{ menuGroup.name }}">
            {{ menuGroup.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="mb-5 text-center button-div">
    <button type="submit" class="btn btn-outline-primary btn-sm" [disabled]="!menuItemCopyForm.valid || waiting">Copy Item</button>
    <button class="btn btn-outline-primary btn-sm" [disabled]="!menuItemCopyForm.valid || waiting" (click)="copyAndEdit = true">Copy & Edit</button>
  </div>
</form>
