import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { EntryDictionary, PageCreateType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';
import { RestaurantModel } from '@generativ/wto-api-client';
import { ModalService } from '../../shared/modal/modal.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { RestaurantService } from '../../restaurant/shared/restaurant.service';
import { ParamsService } from '../../shared/params.service';

@Component({
  selector: 'app-menu-create',
  templateUrl: './menu-create.component.html',
  styleUrls: ['./menu-create.component.scss']
})
export class MenuCreateComponent implements OnInit, OnDestroy {
  content: PageCreateType;
  restaurant: RestaurantModel;
  route: ActivatedRoute;
  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();
  routerSub: Subscription;
  restaurantSub: Subscription;

  constructor(
    private contentfulService: ContentfulService,
    private router: Router,
    private modalService: ModalService,
    private restaurantService: RestaurantService,
    private paramsService: ParamsService
  ) {
    this.route = this.modalService.getActivatedRoute();
  }

  ngOnInit() {
    this.contentfulService.getAdminEntry(EntryDictionary.PageCreate.pageAddMenu).then(
      (entry) => {
        this.content = new PageCreateType(entry);
      }
    );
    this.routerSub = this.route.params.subscribe((params: Params) => {
      const restaurantId = +this.paramsService.getParamById('restaurantId', this.route.snapshot);
      this.restaurantSub = this.restaurantService.getRestaurantCache(restaurantId).subscribe(restaurantModel => {
        this.restaurant = restaurantModel;
        this.setSubHeader.emit(this.restaurant.name);
      });
    });
  }

  /**
   * Getting the title from the component, let us getting it from Contentful.
   * @returns {string}
   */
  get title() {
    const type = this.route.snapshot.queryParams.type;
    return `Add ${type} Menu to`.toUpperCase();
  }

  /**
   * Calling closeModal from the component allow us to redirect the user to one page depending on
   * the component that we are.
   */
  onCloseModal() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  onCancel() {
    this.onCloseModal();
  }

  ngOnDestroy() {
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }

}
