import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeFilter'
})
export class ActiveFilterPipe implements PipeTransform {

  transform(items: any[], minimumStatus: number): any {
    const filterItems = [];
    for (const item of items) {
      if (item && typeof item === 'object' && item.hasOwnProperty('active') && item['active'] >= minimumStatus) {
        filterItems.push(item);
      }
    }

    return filterItems;
  }

}
