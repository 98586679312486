export const environment = {
  production: true,
  wtoApiUrl: 'https://api-prod-dot-whattoorder-165014.appspot.com/',
  contentful: {
    admin: {
      space: 'nbe1lci5o8va',
      accessToken: '91216911ee90aa004bdad8c23a082e7e71e12436c6b9e055fffeca0d74376440'
    }
  },
  auth0: {
    clientID: '5BZZHvsLWF7prWAt4x8aDJXb67u39VAl',
    domain: 'whattoorder.auth0.com',
    redirectUri: '/callback',
    logoutUrl: 'https://whattoorder.auth0.com/v2/logout?returnTo=',
    connection: 'wto-admin-prod'
  },
  googleMapsKey: 'AIzaSyBBcVALYifVwXUJ35l8DYZYvjNa9hAys-c',
  SPARKPOST_API_KEY: '1db0f40ae748e9e2e6927641e8640fede121f41b',
  filestack: {
    apiKey: 'AQ4rMOd7ZQbmcKAcuzFvgz',
    policy: 'eyJleHBpcnkiOjI1MjQ2MjYwMDAsImNhbGwiOlsicmVtb3ZlIl19',
    signature: '13a436170b1013d856722b43939ae096bd05e6c6b1b462943330d3a948316e4b'
  },
  instabugToken: '6dcfd20ba51febb27c429f955b890410',
  algolia: {
    appId: 'QHAJR2AGOU',
    searchKey: 'd30ea7c8cb016df9e1420eda60bd953d',
    restaurantIndex: 'restaurant_prod',
    menuItemIndex: 'menu_item_prod'
  },
  freshchat: {
    token: 'ec2c43a9-e683-42bb-b8d0-929099789466',
    host: 'https://wchat.freshchat.com'
  },
  insightMonitor: {
    link: 'https://insights.whattoorder.com/'
  }
};
