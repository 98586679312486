import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../auth/auth.service';
import { SuperAdminPipe } from '../shared/pipes/super-admin.pipe';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private superAdminPipe: SuperAdminPipe
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['auth', 'login']);
      localStorage.setItem('redirectUrl', state.url);
      return false;
    }

    if (!this.superAdminPipe.transform()) {
      confirm('You are not authorized to view this page');
      this.router.navigate(['']);
      return false;
    }

    return true;
  }

}
