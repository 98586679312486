<form [formGroup]="feedbackForm" (ngSubmit)="sendFeedback()">
  <div class="form-group">
    <label for="feedbackRestaurant">Feedback For:</label>
    <input type="email" class="form-control" id="feedbackRestaurant" formControlName="feedbackRestaurant"
           aria-describedby="feedbackRestaurant" placeholder="Restaurant Name (if applicable)">
  </div>
  <hr>
  <div class="form-group">
    <strong>This issue relates to:</strong>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox"
               value="admin" formControlName="admin"
        > The Admin Portal
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox" formControlName="userManual"> The User's Manual
      </label>
    </div>
  </div>
  <div class="form-group">
    <strong>What subject area does the issue cover?  [Select any that apply]</strong>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox"
               formControlName="navigation"
               value="navigation"> Navigation through the Admin Portal
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox"
               formControlName="home"
               value="navigation"> Home Screen
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox"
               formControlName="summary"
               value="navigation"> Restaurant Summary Screen
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input"
               formControlName="restaurant"
               type="checkbox" value="restaurant"> Adding or Editing a Restaurant
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input"
               formControlName="menu"
               type="checkbox" value="menu"> Adding or Editing a Menu
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input"
               formControlName="group"
               type="checkbox" value="group"> Adding or Editing a Menu Group
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox"
               formControlName="item"
               value="item"> Adding or Editing a Menu Item
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input"
               formControlName="other"
               type="checkbox" value="other"> Other
      </label>
    </div>
  </div>
  <hr>
  <div class="form-group" [ngClass]="{'has-danger':hasDanger('feedbackEmail')}">
    <label for="feedbackEmail" class="form-control-label">What is your email address?</label>
    <input type="email" class="form-control" id="feedbackEmail"
           formControlName="feedbackEmail"
           aria-describedby="emailHelp" placeholder="Enter email">
    <div class="form-control-feedback">
      <span *ngIf="hasError('feedbackEmail', 'required')">Email is required</span>
    </div>
  </div>
  <div class="form-group" [ngClass]="{'has-danger':hasDanger('feedbackMessage')}">
    <label for="feedbackMessage" class="form-control-label">What is your specific feedback?</label>
    <textarea class="form-control" id="feedbackMessage"
              formControlName="feedbackMessage"
              rows="5"></textarea>
    <div class="form-control-feedback">
      <span *ngIf="hasError('feedbackMessage', 'required')">Message is required.</span>
    </div>
  </div>
  <button type="submit"
          [disabled]="!feedbackForm.valid">Submit Feedback</button>
  <div class="clearfix"></div>
</form>
