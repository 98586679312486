import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ContentfulEntryType } from '@generativ/wto-admin-types';
import { ContentfulClientApi, createClient, Entry } from 'contentful/dist/contentful.browser.js';
import { SyncCollection } from 'contentful';


@Injectable()
export class ContentfulService {
  adminClient: ContentfulClientApi;
  message: any;

  entryCache: Map<string, any>;
  includeDepth = 7;
  nextSyncToken: string;
  setUpCompleted = false;

  constructor() {
    this.adminClient = createClient({
      space: environment.contentful.admin.space,
      accessToken: environment.contentful.admin.accessToken,
      resolveLinks: true
    });
    this.entryCache = new Map<string, any>();
  }

  /**
   * This endpoint will be called from the components to get the entries content.
   * Only the first time is called after loading the app, it will call to contenful
   * service set up
   * @param id
   */
  async getAdminEntry(id: string): Promise<ContentfulEntryType> {
    if (!this.setUpCompleted) {
      await this.setUp();
    }
    return this.entryCache.get(id);
  }

  /**
   * Extract one entry form Contentful response and save it in the local entry cache
   * @param entries
   */
  private extractOneEntry(rawEntry: Entry): Entry {
    if (rawEntry) {
      const entry: ContentfulEntryType = rawEntry;
      // In case the entry was already in the cache we are replacing it to the new value
      this.entryCache.set(entry.sys.id, entry);
      return entry;
    }
    return null;
  }


  /**
   * It's called only once the app module is generated and the Contentful service is created.
   * There're two cases:
   *  1 - The first time that we are syncing all the data from Contenful.
   *      In this case we should get all the entries and save them in service memory and localStorage for future access.
   *  2 - Entries are already saved in localStorage. In this case we should read all the entries from localStorage
   *      adding them to the entryCache of the Contentful Service. After we have all the entities loaded, we check
   *      if there're changes in Contentful.
   *      The sync function will return the delta changes. Only those entries that changes.
   *      We should save them in the entryCache and later in the localStorage.
   */
  private async setUp() {

    this.nextSyncToken = localStorage.getItem('nextSyncToken');
    let options = this.nextSyncToken ? { nextSyncToken: this.nextSyncToken } : { initial: true };
    if (!this.nextSyncToken) {
      options = { initial: true };
    } else {
      this.readEntriesFromLocalStorage();
      options = { nextSyncToken: this.nextSyncToken };
    }
    const response: SyncCollection = await this.adminClient.sync(options);
    await this.processEntries(response);
    this.setUpCompleted = true;
  }

  /**
   * Todo: Check if Contentful is fixed and stop asking for all the entries again, since sync isn't returning the
   * nested objects in include attribute.
   * @param syncCollection
   */
  private async processEntries(syncCollection: SyncCollection) {
    this.nextSyncToken = syncCollection.nextSyncToken;
    localStorage.setItem('nextSyncToken', this.nextSyncToken);
    // If there are new entries, it means that new changes occurred. Get all the entries again and update cache.
    if (syncCollection.entries) {
      let hasMoreEntries = true;
      let response;
      let skip = 0;
      while (hasMoreEntries) {
        response = await this.adminClient.getEntries({
          include: this.includeDepth,
          skip: skip
        });
        const total = response.total;
        const limit = response.limit;
        // While we have entries that aren't being load hit the api again and get the entities.
        hasMoreEntries = total - (limit + skip) > 0;
        skip += limit;
        for (const entry of response.items) {
          this.extractOneEntry(entry);
        }
      }
    }
    // After processing the entries we save it to localStorage.
    this.saveEntriesInLocalStorage();
  }

  /**
   * Save the array of the entries in the localStorage.
   */
  private saveEntriesInLocalStorage() {
    localStorage.setItem('contentfulEntries', JSON.stringify(Array.from(this.entryCache.entries())));
  }

  /**
   * Read entries form localStorage.
   * Since the saved object is get form the Array produce of doing Array.from(this.entryCache.entries()))
   * In the first index it's located the KEY
   * In the second index it's located the ENTRY
   */
  private readEntriesFromLocalStorage() {
    const entries = JSON.parse(localStorage.getItem('contentfulEntries'));
    if (entries) {
      for (const entry of entries) {
        this.entryCache.set(entry[0], entry[1]);
      }
    }
  }
}
