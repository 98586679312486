import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MenuGroupModel, MenuGroupRequest, MenuItemModel, MenuModel } from '@generativ/wto-api-client';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { of } from 'rxjs/observable/of';
import { MenuItemService } from '../menu-item.service';
// TODO: Change menu-group service location to shared folder
import { MenuGroupService } from '../../menu-group/shared/menu-group.service';
import { MenuService } from '../../menu/shared/menu.service';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { MenuItemFormComponent } from '../shared/menu-item-form/menu-item-form.component';
import { EntryDictionary, PageCreateMenuItemType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ModalService } from '../../shared/modal/modal.service';


@Component({
  selector: 'app-menu-item-create',
  templateUrl: './menu-item-create.component.html',
  styleUrls: ['./menu-item-create.component.scss']
})
export class MenuItemCreateComponent implements OnInit, OnDestroy {

  @ViewChild(MenuItemFormComponent)
  private formComponent: MenuItemFormComponent;
  @ViewChild('searchInputId') searchInput;
  @Output() setSubHeader: EventEmitter<any> = new EventEmitter();
  subs = new Subscription();

  menuItem: MenuItemModel;
  menuGroup: MenuGroupModel;
  menu: MenuModel;

  searching = false;
  searchFailed = false;
  enableSearch = false;

  menuItemsBagForm: FormGroup;
  ready = false;

  content: PageCreateMenuItemType;

  private menuGroupId: number;
  private errorMessage: string;
  route: ActivatedRoute;

  constructor(
    private menuItemService: MenuItemService,
    private menuGroupService: MenuGroupService,
    private menuService: MenuService,
    private fb: FormBuilder,
    private dragulaService: DragulaService,
    private contentfulService: ContentfulService,
    private modalService: ModalService,
    private router: Router
  ) {

    this.route = this.modalService.getActivatedRoute();
    this.createMenuItemsBagFormGroup();

  }


  ngOnInit() {
    this.contentfulService.getAdminEntry(EntryDictionary.PageCreateMenuItem.pageAddMenuItem).then(
      (entry) => {
        this.content = new PageCreateMenuItemType(entry);
      }
    );

    this.subs.add(this.route.params.subscribe((params: Params) => {
      const menuGroupIdParam = +this.route.snapshot.parent.params['menuGroupId'];
      if (menuGroupIdParam) {
        this.menuGroupId = +menuGroupIdParam;

        this.subs.add(this.menuGroupService.getMenuGroup(this.menuGroupId).subscribe((menuGroup) => {
          this.menuGroup = menuGroup;
          this.menuItemsBagForm.patchValue(this.menuGroup);
          this.ready = true;
          console.log(`Get Menu Group - Parsed`, this.menuGroup);

          this.subs.add(this.menuService.getMenu(menuGroup.menuId).subscribe((menu) => {
            this.menu = menu;
            this.enableSearch = true;
            // console.log(`Get Menu - Parsed`, this.menu);
            this.setSubHeader.emit(menuGroup.name);
          }));
        }));
      }
    }));
  }

  changeMenuItemStatus(newStatus: number, menuItemModel: MenuItemModel) {
    console.log(`Changing Menu Item ${menuItemModel.id} Status`);
    this.subs.add(this.menuItemService.changeMenuItemStatus(newStatus, menuItemModel.id)
      .subscribe(
      updateResponse => {
        console.log(`Update MenuItem Status - Parsed:`, updateResponse);
        menuItemModel.active = menuItemModel.active ? 0 : 1;
        this.menuItemsBagForm.patchValue(this.menuGroup);
      },
      error => this.errorMessage = <any>error,
    ));
  }

  onSelectItem(event?) {
    console.log(event);
  }

  assignItemToMenuGroup() {
    if (this.menuItem && this.menuGroupId) {
      this.subs.add(this.menuGroupService.assignMenuItem(this.menuGroupId, this.menuItem.id).subscribe((response) => {
        this.menuGroup = response.menuGroup;
        this.menuItemsBagForm.patchValue(this.menuGroup);
        console.log(`Get Menu - Parsed`, this.menu);
        this.cleanSearchInputField();
      }));
    }
  }

  onNewMenuItem(newMenuItem: MenuItemModel): void {
    this.menuGroup.items.push(newMenuItem);
    // Update the order list
    const concatItem = (this.menuGroup.menuItemOrder && this.menuGroup.menuItemOrder.length > 0) ? ',' : '';
    this.menuGroup.menuItemOrder = this.menuGroup.menuItemOrder +  concatItem + newMenuItem.id;

  }


  search = (text: Observable<string>) => {
    return text
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
        switchMap((term) => {
          if (term.length > 0) {
            return this.menuItemService.searchRestaurant(this.menu.restaurantId, term)
              .pipe(
                tap(() => this.searchFailed = false),
                catchError(() => {
                  this.searchFailed = true;
                  return of([]);
                }));
          } else {
            this.searchFailed = true;
            return of([]);
          }
        }),
        tap(() => this.searching = false));
  }

  formatter = (mi: MenuItemModel) => mi.name ? mi.name : '';

  onDrop(args) {
    const [e, el] = args;
    const orderArray = this.menuGroup.items.map(function(elem) { return elem.id; });
    const order = orderArray.join();

    const updateRequest = new MenuGroupRequest.OrderUpdate();
    updateRequest.menuItemOrder = order;
    updateRequest.id = this.menuGroup.id;

    console.log('Update MenuGroup Order Request:', updateRequest);

    this.subs.add(this.menuGroupService.editMenuGroupOrder(updateRequest)
      .subscribe(
        updateResponse => {
          console.log(`Update MenuGroup - Parsed:`, updateResponse);
        },
        error => this.errorMessage = <any>error,
      ));

  }

  private cleanSearchInputField() {
    this.searchInput.nativeElement.value = '';
  }

  private createMenuItemsBagFormGroup() {
    this.menuItemsBagForm = this.fb.group({
      'name': false,
      'menuItemNormalPrice': true,
      'marketPrice': false,
      'noPrice': false

    });
  }

  /**
   * Getting the title from the component, let us getting it from Contentful.
   * @returns {string}
   */
  get title() {
    return 'Add Item To';
  }

  /**
   * Calling closeModal from the component allow us to redirect the user to one page depending on
   * the component that we are.
   */
  onCloseModal() {
    this.router.navigate(['../../../../../../'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
