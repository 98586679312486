import { Routes } from '@angular/router';
import { AuthGuardService } from '../guards/auth-guard.service';
import { PageNotFoundComponent } from './page-not-found.component';

export const pageNotFoundRoutes: Routes = [
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthGuardService],
  }
];
