import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'photoApprovalSizing'
})

export class PhotoApprovalImageSizingPipe implements PipeTransform {

  transform(url: string) {
    const splitUrl = url.split('/');

    return 'https://cdn.filestackcontent.com/resize=width:600,height:600/' + splitUrl[splitUrl.length - 1];
  }

}
