import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Observer } from 'rxjs/Observer';
import { RestaurantSearchModel, UserModel } from '@generativ/wto-api-client';
import { RestaurantService } from '../../restaurant/shared/restaurant.service';
import { EntryDictionary, PageHomeType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';
import { UserService } from '../../shared/user.service';
import { Subscription } from 'rxjs/Subscription';
import { IMultiSelectOption, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';

@Component({
  selector: 'app-restaurant-assign',
  templateUrl: './restaurant-assign.component.html',
  styleUrls: ['./restaurant-assign.component.scss']
})
export class RestaurantAssignComponent implements OnInit, OnDestroy {
  restaurant: RestaurantSearchModel;
  ownedRestaurant: RestaurantSearchModel;
  searching = false;
  searchFailed = false;
  content: PageHomeType;
  owners: UserModel[] = [];
  selectOwners: IMultiSelectOption[];
  owner: number[] = [];
  subscription: Subscription = new Subscription();

  userSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    selectionLimit: 1,
    closeOnClickOutside: true,
    closeOnSelect: false,
    showUncheckAll: false,
    dynamicTitleMaxItems: 1,
    maxHeight: '300px'
  };

  constructor(
    private restaurantService: RestaurantService,
    private userService: UserService,
    private contentfulService: ContentfulService
  ) { }

  ngOnInit(): void {
    this.contentfulService.getAdminEntry(EntryDictionary.PageHome.pageHome).then(
      (entry) => {
        this.content = new PageHomeType(entry);
      }
    );

    this.subscription.add(this.userService.getAllOwners()
      .subscribe(owners => {
        this.owners = owners;
        this.selectOwners = [];
        for (const owner of this.owners) {
          this.selectOwners = [...this.selectOwners, { id: owner.id, name: owner.email }];
        }
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  search = (text: Observable<string>) => {
    return text.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        new Observable((observer: Observer<RestaurantSearchModel[]>) => {
          this.restaurantService.search(term)
            .then((hits) => {
              this.searchFailed = false;
              observer.next(hits);
            })
            .catch(() => {
              this.searchFailed = true;
              observer.next([]);
            });
        })
      ),
      tap(() => this.searching = false));
  }

  ownedSearch = (text: Observable<string>) => {
    return text.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        new Observable((observer: Observer<RestaurantSearchModel[]>) => {
          this.restaurantService.search(term, true)
            .then((hits) => {
              this.searchFailed = false;
              observer.next(hits);
            })
            .catch(() => {
              this.searchFailed = true;
              observer.next([]);
            });
        })
      ),
      tap(() => this.searching = false));
  }

  formatter = (r: RestaurantSearchModel) => r.name ? r.name : '';

  onSelectItem(event?: any) {
    console.log(event);
  }

  assignOwner(): void {
    this.restaurantService.assignOwnership(this.restaurant.id, this.owner[0])
      .subscribe(res => {
          alert('Ownership successfully assigned');
          this.owner = null;
          this.restaurant = null;
        },
        err => alert('Problem assigning ownership' + err));
  }

  unAssignOwner(): void {
    this.restaurantService.assignOwnership(this.ownedRestaurant.id, null)
      .subscribe(res => {
          alert('Ownership successfully removed');
          this.ownedRestaurant = null;
        },
        err => alert('Problem removing ownership' + err));
  }
}
