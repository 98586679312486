import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'scheduleHours'
})
export class ScheduleHoursPipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe
  ) {}

  transform(value: string): string {
    // Since date requires a date, use a dummy date
    const date = new Date('1970-01-01T' + value);
    let returnString = '';

    // If date valid, build date string.
    // Otherwise, pad with <br> for spacing.
    if (date && !isNaN(date.getTime())) {
      returnString = this.datePipe.transform(date, 'shortTime');
      returnString += ` | `;
      returnString += this.datePipe.transform(date, 'HH:mm');
    } else {
      returnString += `<br>`;
    }
    return returnString;
  }

}
