import { Routes } from '@angular/router';
import { SearchComponent } from './search.component';
import { PartialIndexComponent } from './partial-index/partial-index.component';
import { SuperAdminGuard } from '../guards/super-admin.guard';
import { RestaurantAssignComponent } from './restaurant-assign/restaurant-assign.component';
import { TagsComponent } from './tags/tags.component';

export const searchRoutes: Routes = [
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [SuperAdminGuard],
    data: {
      breadcrumb: {}
    }
  },
  {
    path: 'search/partial',
    component: PartialIndexComponent,
    canActivate: [SuperAdminGuard],
    data: {
      breadcrumb: {}
    }
  },
  {
    path: 'search/assign',
    component: RestaurantAssignComponent,
    canActivate: [SuperAdminGuard],
    data: {
      breadcrumb: {}
    }
  },
  {
    path: 'search/tags',
    component: TagsComponent,
    canActivate: [SuperAdminGuard],
    data: {
      breadcrumb: {}
    }
  }
];
