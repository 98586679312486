<div class="form-group">
    <div class="row">
      <div class="col-1 center">
        <strong>1</strong>
      </div>
      <div class="col-7">
        <div class="weekdays-selector">
          <input type="checkbox" id="weekday-mon" [checked]="isChecked('mon')" class="weekday" />
          <label for="weekday-mon">Mo</label>
          <input type="checkbox" id="weekday-tue" [checked]="isChecked('tue')" class="weekday" />
          <label for="weekday-tue">Tu</label>
          <input type="checkbox" id="weekday-wed" [checked]="isChecked('wed')" class="weekday" />
          <label for="weekday-wed">We</label>
          <input type="checkbox" id="weekday-thu" [checked]="isChecked('thu')" class="weekday" />
          <label for="weekday-thu">Th</label>
          <input type="checkbox" id="weekday-fri" [checked]="isChecked('fri')" class="weekday" />
          <label for="weekday-fri">Fr</label>
          <input type="checkbox" id="weekday-sat" [checked]="isChecked('sat')" class="weekday" />
          <label for="weekday-sat">Sa</label>
          <input type="checkbox" id="weekday-sun" [checked]="isChecked('sun')" class="weekday" />
          <label for="weekday-sun">Su</label>
        </div>
      </div>
      <div class="time-selector">
        <input type="time" id="open" class="weekday" />
        <input type="time" id="close" class="weekday" />
      </div>
    </div>
  </div>