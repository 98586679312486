import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { FadeInEvent } from './fade-in-event';

@Injectable({
  providedIn: 'root'
})
export class AnimationEventsService {

  constructor() { }

  public moveEvent = new Subject<FadeInEvent>();
  public createOrCopyEvent = new Subject<FadeInEvent>();
  public createMenuEvent = new Subject<FadeInEvent>();

  setMoveEvent(value: FadeInEvent) {
    this.moveEvent.next(value);
  }

  setCreateOrCopyEvent(value: FadeInEvent) {
    this.createOrCopyEvent.next(value);
  }

  setCreateMenuEvent(value: FadeInEvent) {
    this.createMenuEvent.next(value);
  }
}
