<main class="container">
  <div class="row">
    <div class="col-6" style="text-align:center">
      <button (click)="verifyRestaurantIndex()"
              class="btn btn-primary btn-lg">Reset Restaurant Index
      </button>
    </div>
    <div class="col-6" style="text-align: center">
      <button (click)="verifyMenuItemIndex()"
              class="btn btn-primary btn-lg">Reset Menu Item Index
      </button>
    </div>
  </div>
</main>
