import { Routes } from '@angular/router';
import { MenuItemComponent } from './menu-item.component';
import { MenuItemEditComponent } from './menu-item-edit/menu-item-edit.component';
import { ModalWrapperComponent } from '../shared/modal/modal-wrapper/modal-wrapper.component';
import { MenuItemCreateComponent } from './menu-item-create/menu-item-create.component';
import { MenuItemRelocateComponent } from './menu-item-relocate/menu-item-relocate.component';
import { MenuItemCopyComponent } from './menu-item-copy/menu-item-copy.component';
import { MenuItemPhotoApprovalComponent } from './menu-item-photo-approval/menu-item-photo-approval.component';

export const menuItemRoutes: Routes = [
  {
    path: 'menu-item',
    component: MenuItemComponent,
    data: {
      breadcrumb: {}
    },
    children: [
      {
        path: 'create',
        component: ModalWrapperComponent,
        data: {
          component: MenuItemCreateComponent,
        }
      },
      {
        path: 'approval',
        component: MenuItemPhotoApprovalComponent,
        data: {
          component: MenuItemPhotoApprovalComponent
        }
      },
      {
        path: ':menuItemId',
        data: {
          breadcrumb: {
            bcType: 'LookUp',
            value: 'menuItemId',
            type: 'MenuItem'
          }
        },
        children: [
          {
            path: 'edit',
            component: ModalWrapperComponent,
            data: {
              component: MenuItemEditComponent
            }
          },
          {
            path: 'move',
            component: ModalWrapperComponent,
            data: {
              component: MenuItemRelocateComponent
            }
          },
          {
            path: 'copy',
            component: ModalWrapperComponent,
            data: {
              component: MenuItemCopyComponent
            }
          }
        ]
      }
    ]
  }
];
