import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu-schedule',
  templateUrl: './menu-schedule.component.html',
  styleUrls: ['./menu-schedule.component.scss']
})
export class MenuScheduleComponent implements OnInit {

  @Input() selectedDays: string[];
  @Input() disabledDays: string[];

  constructor() { }

  ngOnInit() {
  }

  isChecked(day: string): boolean {
    return !!this.selectedDays.find(item => item === day);
  }

}
