import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MenuComponent } from './menu.component';
import { MenuCreateComponent } from './menu-create/menu-create.component';
import { MenuEditComponent } from './menu-edit/menu-edit.component';
import { MenuFormComponent } from './shared/menu-form/menu-form.component';
import { MenuService } from './shared/menu.service';
import { MenuScheduleComponent } from './shared/menu-schedule/menu-schedule.component';
import { ReorderMenusComponent } from './reorder-menus/reorder-menus.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    MenuComponent,
    MenuCreateComponent,
    MenuEditComponent,
    MenuFormComponent,
    MenuScheduleComponent,
    ReorderMenusComponent,
  ],
  providers: [
    MenuService
  ]
})
export class MenuModule { }
