import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Pipe({
  name: 'phone'
})

export class PhonePipe implements PipeTransform {

  transform(number, countryCode) {
    const n = phoneNumberUtil.parse(number, countryCode);
    return phoneNumberUtil.format(n, PhoneNumberFormat.INTERNATIONAL);
  }
}
