import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../shared/modal/modal.service';
import { RestaurantService } from '../../restaurant/shared/restaurant.service';
import { MenuModel, RestaurantModel, RestaurantRequest } from '@generativ/wto-api-client';
import { ParamsService } from '../../shared/params.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-reorder-menus',
  templateUrl: './reorder-menus.component.html',
  styleUrls: ['./reorder-menus.component.scss']
})
export class ReorderMenusComponent implements OnInit, OnDestroy {

  route: ActivatedRoute;
  restaurant: RestaurantModel;
  subs = new Subscription();
  isSubmitting: boolean;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private paramsService: ParamsService,
    private dragulaService: DragulaService,
    private restaurantService: RestaurantService
  ) {
    this.route = this.modalService.getActivatedRoute();
    dragulaService.createGroup('menus-bag', {
      removeOnSpill: false
    });


    dragulaService.out('menus-bag').subscribe((value) => {
      this.onDrop(value);
    });
  }

  ngOnInit() {
    const restaurantId = +this.paramsService.getParamById('restaurantId', this.route.snapshot);
    this.subs.add(this.restaurantService.getRestaurantCache(restaurantId).subscribe((restaurant) => {
      this.restaurant = restaurant;
    }));
  }


  onDrop(args) {
    this.isSubmitting = true;
    const menus: MenuModel[] = this.restaurant.menus;
    const menuOrderRequest: RestaurantRequest.OrderUpdate = new RestaurantRequest.OrderUpdate();
    menuOrderRequest.id = this.restaurant.id;

    menuOrderRequest.menuOrder = menus.map(function (elem) {
      return elem.id;
    }).join();

    this.subs.add(this.restaurantService.editMenuOrder(menuOrderRequest)
      .subscribe(
        updateResponse => {
          console.log(`Update MenuGroup - Parsed:`, updateResponse);
          this.isSubmitting = false;
        },
        // Todo: Add errors.
        error => this.isSubmitting = false,
      ));
  }

  get title() {
    return 'REORDER MENUS';
  }

  onCloseModal() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.dragulaService.destroy('menus-bag');
    this.subs.unsubscribe();
  }

}
