import { Routes } from '@angular/router';
import { RestaurantComponent } from './restaurant.component';
import { RestaurantCreateComponent } from './restaurant-create/restaurant-create.component';
import { RestaurantEditComponent } from './restaurant-edit/restaurant-edit.component';
import { AuthGuardService } from '../guards/auth-guard.service';
import { RestaurantManageComponent } from './restaurant-manage/restaurant-manage.component';
import { menuRoutes } from '../menu/menu.routes';
import { ModalWrapperComponent } from '../shared/modal/modal-wrapper/modal-wrapper.component';
import { RestaurantCloseComponent } from './restaurant-close/restaurant-close.component';
import { RestaurantExportComponent } from './restaurant-export/restaurant-export.component';
import { RestaurantEmailComponent } from './restaurant-email/restaurant-email.component';

export const restaurantRoutes: Routes = [
  {
    path: 'restaurant',
    component: RestaurantComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {
      breadcrumb: {}
    },
    children: [
      {
        path: 'create',
        component: ModalWrapperComponent,
        data: {
          title: 'Add Location',
          component: RestaurantCreateComponent,
          breadcrumb: {
            bcType: 'Display',
            action: 'Create',
            value: 'Restaurants',
            type: 'Restaurant'
          }
        }
      },
      {
        path: ':restaurantId',
        component: RestaurantManageComponent,
        data: {
          breadcrumb: {
            bcType: 'LookUp',
            value: 'restaurantId',
            type: 'Restaurant'
          }
        },
        children: [
          {
            path: 'edit',
            // Call the wrapper component & add the info about the component that is going to be instantiated inside
            // the modal.
            component: ModalWrapperComponent,
            data: {
              title: 'Edit Location Details',
              component: RestaurantEditComponent,
              breadcrumb: {
                bcType: 'Display',
                action: 'Edit'
              }
            }
          },
          {
            path: 'close',
            // Call the wrapper component & add the info about the component that is going to be instantiated inside
            // the modal.
            component: ModalWrapperComponent,
            data: {
              component: RestaurantCloseComponent,
            }
          },
          {
            path: 'export',
            // Call the wrapper component & add the info about the component that is going to be instantiated inside
            // the modal.
            component: ModalWrapperComponent,
            data: {
              component: RestaurantExportComponent,
            }
          },
          {
            path: 'send-email',
            // Call the wrapper component & add the info about the component that is going to be instantiated inside
            // the modal.
            component: ModalWrapperComponent,
            data: {
              component: RestaurantEmailComponent,
            }
          },
          ...menuRoutes
        ]
      },

    ]
  }
];
