import { AfterContentInit, Component } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterContentInit {

  model: { email: string, password: string };

  constructor(
      private authService: AuthService
  ) {

    this.model = { email: '', password: '' };

  }

  ngAfterContentInit() {
    if (localStorage.getItem('reset-password')) {
      confirm(`A password reset is required for new users. ` +
        `An email has been sent for resetting your password.`);
      localStorage.removeItem('reset-password');
    }
  }

  showLock() {
    this.authService.showLock();
    // this.authService.showChangePassword();
  }

}
