<form [formGroup]="menuGroupMoveForm" autocomplete="off" (ngSubmit)="submitForm()" >
  <div class="form-group relocate" *ngIf="menuGroup; else loader">
    <div class="row">
      <div class="col-12">
        <select class="form-control form-control-lg" id="rest-cuisine"
                formControlName="menuId">
          <option [ngValue]="null" disabled selected> Select Menu</option>
          <option *ngFor="let menu of menus | orderby:'name'" [ngValue]="menu.id" label="{{ menu.name }}">{{ menu.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="mb-5 text-center form-footer">
    <span>
      <button type="submit" class="btn btn-outline-primary btn-sm" [disabled]="!menuGroupMoveForm.valid || isSubmitting">
        <span [ngClass]="isSubmitting ? 'loader-small span-loader-large' : ''">
          Move Group
        </span>
      </button>
    </span>
  </div>
</form>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
