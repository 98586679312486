import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalContentComponent, ModalContentTemplateComponent } from './modal-content/modal-content.component';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';
import { ModalService } from './modal.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ModalContentComponent,
    ModalWrapperComponent,
    ModalContentTemplateComponent],
  providers: [
    ModalService
  ]
})
export class ModalModule { }
