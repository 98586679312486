import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements AfterViewInit, OnDestroy {

  modalRef: NgbModalRef;

  constructor(
    private ngbModalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // Get from route
      this.modalRef = this.ngbModalService.open(ModalContentComponent, {
        // 'size' and 'windowClass' added below to handle specific modals that need to be smaller
        size: 'lg',
        backdrop: 'static',
        windowClass: this.activatedRoute.snapshot.url.toString() + '-custom-modal-dialog',
        beforeDismiss: () => {
          return this.beforeDismiss();
        }
      });
      const instance = <ModalContentComponent> this.modalRef.componentInstance;
      instance.component = this.activatedRoute.snapshot.data.component;
      this.modalService.setActivatedRoute(this.activatedRoute);
      this.modalService.setCurrentModalRef(this.modalRef);
    });
  }

  /**
   * Before escaping we should call the close method.
   * @returns {boolean}
   */
  beforeDismiss(): boolean {
    this.modalRef.componentInstance.close();
    return true;
  }

  ngOnDestroy() {
    this.modalRef.close();
    this.modalService.resetModalService();
  }
}
