import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ModalService {

  activatedRoute: ActivatedRoute;
  modalRef: NgbModalRef;

  constructor() {
  }

  setCurrentModalRef(modalRef: NgbModalRef) {
    this.modalRef = modalRef;
  }

  closeCurrentModal() {
    this.resetModalService();
    this.modalRef.close();
  }

  setActivatedRoute(activatedRoute: ActivatedRoute) {
    this.activatedRoute = activatedRoute;
  }

  getActivatedRoute(): ActivatedRoute {
    return this.activatedRoute;
  }

  resetModalService(): void {
    this.activatedRoute = null;
  }

}
