import { Injectable } from '@angular/core';
import * as filestack from 'filestack-js';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';

@Injectable()
export class FilestackService {
  private client: any;

  constructor() {
    this.client = filestack.init(environment.filestack.apiKey, {
      security: {
        policy: environment.filestack.policy,
        signature: environment.filestack.signature
      }
    });

  }

  pickPhoto(): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const options = {
        accept: 'image/*',
        maxFiles: 1,
        uploadInBackground: false,
        onUploadDone: (res) => {
          console.log(`Response: `, res);
          if (res.filesUploaded[0]) {
            observer.next(res.filesUploaded[0].url);
            observer.complete();
          }
        }
      };
      this.client.picker(options).open();
    });
  }

  removePhoto(url: string) {
    const urlParts = url.split('/');
    const handle = urlParts[urlParts.length - 1];

    return this.client.remove(handle)
      .then((res) => {
        return res;
      });
  }

  cropImage(url: string) {
    return new Observable((observer: Observer<string>) => {
      const picker = this.client.picker({
        transformations: {
          crop: {
            aspectRatio: 4 / 4,
          },
          circle: false,
          rotate: true
        },
        onUploadDone: (res) => {
          console.log(`Response: `, res);
          if (res.filesUploaded[0]) {
            observer.next(res.filesUploaded[0].url);
            observer.complete();
          }
        }
      });
    picker.crop([url]);
    });
  }
}
