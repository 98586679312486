import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { EmailService } from '../../shared/email.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackModel } from '@generativ/wto-api-client';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {

  @Output() submitted = new EventEmitter<boolean>();
  feedbackForm: FormGroup;
  feedback: FeedbackModel.Admin;
  emailSub: Subscription;

  constructor(
    private emailService: EmailService,
    private fb: FormBuilder
  ) {
    this.createFormGroup();
  }

  ngOnInit() {
    this.feedback = new FeedbackModel.Admin();
  }

  public sendFeedback() {
    this.feedback.parse(this.feedbackForm.value);
    this.emailSub = this.emailService.sendFeedbackEmail(this.feedback).subscribe(
      feedbackModel => {
        this.submitted.emit(false);
      },
      error => { console.log(error); }
    );
  }

  public hasError(field: string, validator: string) {
    const control = this.feedbackForm.controls[field];
    return control.hasError(validator)
      && control.touched;
  }

  public hasDanger(field: string) {
    const control = this.feedbackForm.controls[field];
    return !control.disabled && !control.valid && control.touched;
  }

  private createFormGroup() {
    this.feedbackForm = this.fb.group({
      feedbackRestaurant: [
        null
      ],
      admin: [
        null
      ],
      userManual: [
        null
      ],
      navigation: [
        null
      ],
      home: [
        null
      ],
      summary: [
        null
      ],
      restaurant: [
        null
      ],
      menu: [
        null
      ],
      group: [
        null
      ],
      item: [
        null
      ],
      other: [
        null
      ],
      feedbackEmail: [
        null,
        Validators.required
      ],
      feedbackMessage: [
        null,
        Validators.required
      ],
    });
  }

  ngOnDestroy() {
    if (this.emailSub) {
      this.emailSub.unsubscribe();
    }
  }
}
