import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import Auth0Lock from 'auth0-lock';
import * as auth0 from 'auth0-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../shared/user.service';
import { UserModel } from '@generativ/wto-api-client';

@Injectable()
export class AuthService {
  public token: string;
  roles: AdminUserRole[] = [];
  freshChatInit: boolean;

  // Configure Auth0
  lock = new Auth0Lock(environment.auth0.clientID, environment.auth0.domain,  {
      allowSignUp: false
  });

  auth0 = new auth0.WebAuth({
    clientID: environment.auth0.clientID,
    domain: environment.auth0.domain
  });

  constructor(
    private router: Router,
    private jwtHelperService: JwtHelperService,
    private userService: UserService
  ) {
    this.token = localStorage.getItem('token_id');
    this.roles = JSON.parse(localStorage.getItem('roles'));
    this.userService.currentUser = new UserModel().parse(JSON.parse(localStorage.getItem('currentUser')));
  }

  isAuthenticated() {
    const authorized =  !this.jwtHelperService.isTokenExpired(this.token);

    if (authorized && !this.freshChatInit) {
      this.initFreshChat();
    }
    return authorized;
  }

  getAuthorizationHeader(): string {
    return this.token ? this.token : '';
  }

  // Call this method in app.component
  // if using path-based routing
  handleAuthentication(): void {
    // Add callback for lock `authenticated` event
    this.lock.on('authenticated', (authResult) => {

      if (authResult && authResult.accessToken && authResult.idToken) {
        localStorage.setItem('token_id', authResult.idToken);
        localStorage.setItem('roles',
          JSON.stringify(authResult.idTokenPayload['https://whattoorder.com/appMetadata'].authorization.roles || []));
        this.token = authResult.idToken;
        this.roles = authResult.idTokenPayload['https://whattoorder.com/appMetadata'].authorization.roles;
        const updateRoles = [];
        for (const role of this.roles) {
          updateRoles.push(role.name);
        }
        this.userService.updateUserRoles(authResult.idTokenPayload.sub, updateRoles)
          .subscribe(user => {
            localStorage.setItem('currentUser', JSON.stringify(user));
            const ownerRole = user.roles.find(role => role === 'restaurant-owner');
            if (!!ownerRole && !user.hasChangedPassword) {
              this.auth0.changePassword({
                email: user.email,
                connection: environment.auth0.connection
              }, (res) =>  {
                localStorage.setItem('reset-password', 'true');
                this.logout();
              });
            } else {
              this.initFreshChat();
              const redirect = '/';
              // Redirect the user
              this.router.navigate([redirect]);
            }
          }, (error => alert(`Error updating user: ${error}`)));
      } else if (authResult && authResult['error']) {
        alert(`Error: ${authResult['error']}`);
      }
    });
  }

  showLock() {
    const url = window.location.protocol + '//' + window.location.hostname;
    const port = window.location.port.length > 0 ? ':' + window.location.port : '';

    const redirectUrl = url + port + environment.auth0.redirectUri;
    this.auth0.authorize({
      responseType: 'token id_token',
      scope: 'openid app_metadata profile',
      redirectUri: redirectUrl
    });
  }

  logout(): void {
    const url = window.location.protocol + '//' + window.location.hostname;
    const port = window.location.port.length > 0 ? ':' + window.location.port : '';

    const redirectUrl = encodeURIComponent(url) + port;

    window.location.href = environment.auth0.logoutUrl +
      redirectUrl + '&client_id=' +
      environment.auth0.clientID;

    // clear token remove user from local storage to log user out
    this.token = null;
    this.roles = [];
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token_id');
    localStorage.removeItem('roles');
    this.freshChatInit = false;
    this.userService.currentUser = undefined;
  }

  getHighestRole(): number {
    const superAdmin = this.roles.find(role => role.name === 'super-admin');
    if (!!superAdmin) {
      return ADMIN_ROLES['super-admin'];
    }

    const admin = this.roles.find(role => role.name === 'admin');
    if (!!admin) {
      return ADMIN_ROLES['admin'];
    }

    return ADMIN_ROLES['restaurant-owner'];
  }

  // Initialize freshchat and set user info
  private initFreshChat(): void {
    if (this.userService.currentUser && this.userService.currentUser.email && (<any>window).fcWidget) {
      this.freshChatInit = true;
      (<any>window).fcWidget.init({
        token: environment.freshchat.token,
        host: environment.freshchat.host,
        externalId: this.userService.currentUser.email,
        restoreId: this.userService.currentUser.restoreId ? this.userService.currentUser.restoreId : null
      });
      (<any>window).fcWidget.on('user:created', (data) => {
        if (data.data && data.data.restoreId &&
          this.userService.currentUser.restoreId !== data.data.restoreId) {
          this.userService.addRestoreId(data.data.restoreId)
            .subscribe();
        }
      });
    }
  }
}

export interface AdminUserRole {
  description: string;
  id: string;
  name: string;
  sources: {
    source_id: string;
    source_type: string;
    source_name: string;
  };
}

export const ADMIN_ROLES = {
  'super-admin': 0,
  'admin': 1,
  'restaurant-owner': 2
};
