import { NgModule } from '@angular/core';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './auth.service';
import { SharedModule } from '../shared/shared.module';
import { JwtHelperService } from '@auth0/angular-jwt';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AuthComponent,
    LoginComponent
  ],
  providers: [
    AuthService,
    JwtHelperService
  ]
})
export class AuthModule { }
