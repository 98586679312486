<div class="flex-container">
    <button type="submit" (click)="showLock()" class="btn btn-outline-primary btn-sm btn-block login-button">
      Sign In
    </button>

  <div class="policy-block">
    By signing in, you agree to our Privacy Policy,
    <br>
    Terms of Use and Data Access Agreement.
  </div>

  <a href="https://www.whattoorder.com/privacy-policy/" target="_blank">Privacy Policy</a>

  <a href="https://www.whattoorder.com/terms-of-use/" target="_blank">Terms of Use</a>

  <a href="https://www.whattoorder.com/data-access-agreement/" target="_blank">Data Access Agreement</a>
</div>
