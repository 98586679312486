import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { RestaurantModel, RestaurantRequest } from '@generativ/wto-api-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../shared/modal/modal.service';
import { RestaurantService } from '../shared/restaurant.service';
import { Subscription } from 'rxjs/Subscription';
import { ToastService } from '../../shared/toast/toast.service';
import { EntryDictionary, FormExportType } from '@generativ/wto-admin-types';
import { ContentfulService } from '../../shared/contentful.service';

export function atLeastOneSelected(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const filtered = control.value.filter(item => item.selected);
    return !filtered.length ? {'invalidSelection': {value: control.value}} : null;
  };
}


@Component({
  selector: 'app-restaurant-email',
  templateUrl: './restaurant-email.component.html',
  styleUrls: ['./restaurant-email.component.scss']
})
export class RestaurantEmailComponent implements OnInit, OnDestroy {
  content: FormExportType;
  restaurant: RestaurantModel;
  restaurantMenusForm: FormGroup;
  restaurantEmailForm: FormGroup;

  stepTwo = false;

  routerSub: Subscription;
  restaurantSub: Subscription;
  resultSub: Subscription;
  waiting = false;

  constructor(
    private restaurantService: RestaurantService,
    private modalService: ModalService,
    private router: Router,
    private fb: FormBuilder,
    private toastService: ToastService,
    private contentfulService: ContentfulService
  ) {
    this.createFormGroups();

    this.contentfulService.getAdminEntry(EntryDictionary.FormExport.formExport)
      .then(entry => this.content = new FormExportType(entry));
  }

  ngOnInit() {
    this.routerSub = this.modalService.getActivatedRoute().parent.params.subscribe((params) => {
      if (params.restaurantId) {
        this.restaurantSub = this.restaurantService.getRestaurant(params.restaurantId).subscribe((restaurant) => {
          this.restaurant = restaurant;
          for (const menu of this.restaurant.menus) {
            this.menus.push(this.fb.group({
              id: menu.id,
              selected: false,
              name: menu.name,
              active: menu.active
            }));
          }
          const recipients = JSON.parse(this.restaurant.menuFileRecipients);

          if (recipients && recipients.length) {
            for (const recipient of recipients) {
              this.recipients.push(this.fb.group({
                company: recipient.company,
                email: recipient.contactEmail,
                selected: false,
              }));
            }
          }
        });
      }
    });
  }

  onNext() {
    this.waiting = true;
    this.stepTwo = true;
    this.waiting = false;
  }

  onSendEmail() {

    const updatedSinceFormat = this.restaurantMenusForm.controls.updatedSince.value;
    let updatedSince;
    if (updatedSinceFormat) {
      // Conform month to [0-11] date indexing
      updatedSince = new Date(updatedSinceFormat.year, --updatedSinceFormat.month, updatedSinceFormat.day);
    }
    const body = new RestaurantRequest.SendEmail();
    body.content = this.restaurantEmailForm.controls.content.value;
    body.menuIds = this.menus.value.filter(menu => menu.selected).map(menu => menu.id).join();
    body.unpublishedChecked = this.restaurantMenusForm.get('includeUnpublished').value;
    body.updatedSince = updatedSince;
    body.address = this.recipients.value.map(item => item.email);
    this.waiting = true;
    this.resultSub = this.restaurantService.sendEmail(this.restaurant.id, body).subscribe(data => {
        this.waiting = false;
        this.toastService.showSuccess(`Menu(s) sent to recipients.`);
        this.router.navigate(['restaurant', this.restaurant.id]);
        this.modalService.closeCurrentModal();
      },
      error => {
        this.waiting = false;
        console.log(`error sending email: `, error);
      }
    );
  }

  get menus(): FormArray {
    return this.restaurantMenusForm.get('menus') as FormArray;
  }

  get recipients(): FormArray {
    return this.restaurantEmailForm.get('recipients') as FormArray;
  }

  /**
   * Getting the title from the component, let us getting it from Contentful.
   * @returns {string}
   */
  get title() {
    return 'EMAIL MENU INFORMATION';
  }

  /**
   * Calling closeModal from the component allow us to redirect the user to one page depending on
   * the component that we are.
   */
  onCloseModal() {
    this.router.navigate(['../'], { relativeTo: this.modalService.getActivatedRoute() });
  }



  private createFormGroups() {
    this.restaurantMenusForm = this.fb.group({
      menus: this.fb.array([], atLeastOneSelected()),
      includeUnpublished: [false],
      updatedSince: [null],
      active: [false]
    });
    this.restaurantEmailForm = this.fb.group({
      recipients: this.fb.array([], atLeastOneSelected()),
      content: [
        null
      ],
    });
  }

  ngOnDestroy() {
    if (this.restaurantSub) {
      this.restaurantSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.resultSub) {
      this.resultSub.unsubscribe();
    }
  }
}
