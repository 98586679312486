import { Pipe, PipeTransform } from '@angular/core';
import { MenuGroupModel } from '@generativ/wto-api-client';

@Pipe({
  name: 'menuGroupSavedRated'
})
export class MenuGroupSavedRatedPipe implements PipeTransform {

  transform(menuGroup: MenuGroupModel): boolean {
    for (const item of menuGroup.items) {
      if (item.savedCount > 0 || item.ratingCount > 0) {
        return true;
      }
    }
    return false;
  }

}
